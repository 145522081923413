import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'twaice-fe-actions-buttons-cell',
  templateUrl: './actions-buttons-cell.component.html',
  standalone: false,
})
export class ActionsButtonsCellComponent {
  @Input() data;
  @Output() actionClicked = new EventEmitter<number>();

  onOptionClick(optionId: number): void {
    this.actionClicked.emit(optionId);
  }
  trackByIndex(i: number) {
    return i;
  }
}
