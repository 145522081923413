import { CdkOverlayOrigin, Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { Directive, ElementRef, HostListener, Input, OnDestroy, OnInit, ViewContainerRef } from '@angular/core';
import { spacerS, spacerXs } from '@twaice-fe/frontend/shared/utilities';
import { ReplaySubject, takeUntil } from 'rxjs';
import { MenuComponent } from './menu.component';

@Directive({
  selector: '[twMenuTriggerFor]',
  standalone: false,
})
export class MenuTriggerDirective extends CdkOverlayOrigin implements OnInit, OnDestroy {
  @Input('twMenuTriggerFor')
  menuComponent: MenuComponent;

  private overlayRef: OverlayRef;
  private destroy$ = new ReplaySubject<boolean>();

  private menuIsOpen = false;

  @HostListener('click') onClick() {
    this.toggleMenu();
  }

  constructor(
    private overlay: Overlay,
    private viewContainerRef: ViewContainerRef,
    private element: ElementRef
  ) {
    super(element);
  }

  ngOnInit(): void {
    this.menuComponent.click.pipe(takeUntil(this.destroy$)).subscribe(() => {
      if (this.menuComponent.closeOnSelect) {
        this.toggleMenu();
      }
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  private toggleMenu(): void {
    if (this.menuIsOpen) {
      this.menuComponent.close.emit();
      this.overlayRef.dispose();
    } else {
      this.attachMenu();
    }

    this.menuIsOpen = !this.menuIsOpen;
  }

  private attachMenu(): void {
    // add backdrop to enable backdrop click-to-close but overwrite class for transparent styling
    this.overlayRef = this.overlay.create({ hasBackdrop: true, backdropClass: '' });

    const positionStrategy = this.overlay
      .position()
      // attach overlay position to menu trigger for positioning relative to trigger position
      .flexibleConnectedTo(this.element)
      .setOrigin(this.element)
      .withPositions([{ originX: 'start', originY: 'bottom', overlayX: 'start', overlayY: 'top', offsetY: spacerXs }])
      .withViewportMargin(spacerS)
      // allow content to push menu into the viewport to avoid menu exiting viewport
      .withPush(true);

    this.overlayRef.updatePositionStrategy(positionStrategy);

    // block body scrolling to avoid menu overlay detaching from trigger
    this.overlayRef.updateScrollStrategy(this.overlay.scrollStrategies.block());

    this.overlayRef.attach(new TemplatePortal(this.menuComponent.getTemplateRef(), this.viewContainerRef));

    this.menuComponent.playAnimation();

    this.overlayRef
      .backdropClick()
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.toggleMenu();
      });

    this.overlayRef
      .keydownEvents()
      .pipe(takeUntil(this.destroy$))
      .subscribe(({ key }) => {
        if (key === 'Escape') {
          this.toggleMenu();
        }
      });
  }
}
