<div class="chart-item-content">
  <label class="item-title">{{ item.titleTop }}</label>
  <twaice-fe-data-display
    class="item-value"
    [value]="item.valueZip?.value"
    [numberFormat]="item.numberFormat"
    [dateFormat]="item.dateFormat"
    [measurand]="item.measurand"
    notAvailableText="-"
  ></twaice-fe-data-display>
  <label *ngIf="item.titleBottom && item.valueZip?.id" class="active" (click)="onClick(item)">{{
    item.titleBottom | componentNameTranslation
  }}</label>
</div>
