// Colors
$color-light: #ffffff;

$blue-100: #eef6ff;
$blue-200: #c0daf9;
$blue-300: #89bcf7;
$blue-400: #489bff;
$blue-500: #1562fc;
$blue-600: #0051c6;
$blue-700: #044799;
$blue-800: #003972;
$blue-900: #0c2f4c;

$blue-400-a-15: rgba(72, 154, 255, 0.15);
$blue-400-a-05: rgba(72, 154, 255, 0.05);
$blue-500-a-3: rgba(21, 98, 252, 0.3);
$blue-500-a-1: rgba(21, 98, 252, 0.1);

$blue-gray-50: #f7fafc;
$blue-gray-100: #f7fafc;
$blue-gray-200: #edf2f7;
$blue-gray-300: #e2e8f0;
$blue-gray-400: #cbd5e0;
$blue-gray-500: #a0aec0;
$blue-gray-600: #718096;
$blue-gray-700: #4a5568;
$blue-gray-800: #2d3748;
$blue-gray-900: #1a202c;

$blue-gray-400-a-15: rgba(203, 213, 224, 0.15);

$blue-gray-500-a-15: rgba(160, 174, 192, 0.15);

$blue-gray-500-a-40: rgba(160, 174, 192, 0.4);

$gray-50: #ececec;
$gray-100: #e6e6e6;
$gray-200: #cccccc;
$gray-300: #b3b3b3;
$gray-400: #999999;
$gray-500: #808080;
$gray-600: #666666;
$gray-700: #4d4d4d;
$gray-800: #333333;
$gray-900: #1a1a1a;

$green-100: #f0fff4;
$green-200: #c6f6d5;
$green-300: #9ae6b4;
$green-400: #68d391;
$green-500: #48bb78;
$green-600: #38a169;
$green-700: #2f855a;
$green-800: #276749;
$green-900: #22543d;

$red-100: #fff5f5;
$red-200: #fed7d7;
$red-300: #feb2b2;
$red-400: #fc8181;
$red-500: #f56565;
$red-600: #e53e3e;
$red-700: #c53030;
$red-800: #9b2c2c;
$red-900: #742a2a;

$red-500-a-15: rgba(245, 101, 101, 0.15);

$orange-100: #fffaf0;
$orange-200: #feebc8;
$orange-300: #fbd38d;
$orange-400: #f6ad55;
$orange-500: #ed8936;
$orange-600: #dd6b20;
$orange-700: #c05621;
$orange-800: #9c4221;
$orange-900: #7b341e;

$navy-blue: #040f26;
$navy-3: #cdcfd4;
$navy-4: #b4b7be;

$colorWarning: $orange-500;
$colorDanger: $red-500;
$colorSuccess: $green-500;
$colorLink: $blue-500;

$bgColorDanger: $red-300;
$bgColorSuccess: $green-300;

$color-dark-semitransparent: rgba(34, 49, 74, 0.8);

$colorTransparent: transparent;

$severity-critical: #ff0000;
$severity-medium: #f0e442;
$severity-normal: #009e73;
$severity-low: #cdcfd4;

// Font sizes
$font-size-xs: 10px;
$font-size-sm: 13px;
$font-size-base: 16px;
$font-size-lg: 18px;
$font-size-xl: 22px;
$font-size-2xl: 24px;
$font-size-3xl: 40px;

$font-family-proxima-nova: proxima-nova, sans-serif;

// This font family is here to be used for plotly, as in plotly you can not define font-weight, so we declare font as bold instead
$font-family-proxima-nova-bold: proxima-nova-bold, sans-serif;

// Font weights
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-semibold: 600;
$font-weight-bold: 700;

// Base spacing
$spacer-xxs: 2px;
$spacer-xs: 4px;
$spacer-s: 8px;
$spacer: 16px;
$spacer-m: 24px;
$spacer-md: 32px;
$spacer-l: 64px;
$spacer-xl: 72px;

$box-xs: $spacer-md * 3; // 96px
$box-s: $spacer-l * 2; // 128px
$box-m: $spacer-l * 4; // 256px
$box-l: $spacer-l * 5; // 320px

// Content widths spacing
$content-narrow: 400px;
$content-medium: 600px;
$content-regular: 900px;
$content-large: 1100px;

// Other
$border-radius: 1px;

$border-thin: 1px;
