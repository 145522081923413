import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'twaice-fe-accordion-item',
  templateUrl: './accordion-item.component.html',
  styleUrls: ['./accordion-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class AccordionItemComponent {
  @Input() expanded: boolean = false;
  @Input() title: string;

  @Output() expandedChange = new EventEmitter<boolean>();

  constructor(private cdRef: ChangeDetectorRef) {}

  onTitleClick(): void {
    this.toggleExpandedState(!this.expanded);
    this.expandedChange.emit(this.expanded);
  }

  toggleExpandedState(state: boolean): void {
    this.expanded = state;
    this.cdRef.detectChanges();
  }
}
