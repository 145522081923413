<nz-spin class="h-full flex items-center justify-center" *ngIf="isLoading" />
<div *ngIf="!isLoading" class="h-full flex flex-col">
  <div class="overflow-auto p-16 h-full">
    <div *ngIf="chatMessages?.length" class="flex items-center w-full justify-end gap-8">
      <nz-select
        class="w-[150px]"
        nzAllowClear
        nzPlaceHolder="Filter by Tag"
        [(ngModel)]="selectedTag"
        g
        (ngModelChange)="filterByTag($event)"
      >
        @for (tag of tags; track tag) {
        <nz-option [nzLabel]="tag" [nzValue]="tag"></nz-option>
        }
      </nz-select>
    </div>
    @for (message of filteredChatMessages; track message.timestamp; let last = $last) {
    <nz-comment [nzAuthor]="message.userMail" [nzDatetime]="getDatetimeDisplay(message.timestamp)">
      <nz-avatar nz-comment-avatar nzIcon="user"> </nz-avatar>

      <nz-comment-content>
        <div #commentContent class="flex flex-row items-center justify-between gap-24">
          <p class="mb-0">
            {{ message.message }}
          </p>

          <button
            *ngIf="authService.getUsername() === message.userMail"
            class="cursor-pointer"
            (click)="openDeleteModal(message)"
          >
            <twaice-fe-svg-icon class="flex justify-start" name="trash" source="tabler" size="16px" />
          </button>
        </div>
      </nz-comment-content>

      <nz-comment-action>
        <div class="flex flex-row items-center">
          <twaice-fe-message-tags
            [customerBk]="nzData.customerBk"
            [systemBk]="nzData.systemBk"
            [chatMessage]="message"
          ></twaice-fe-message-tags>
        </div>
      </nz-comment-action>

      <nz-comment-action *ngIf="message.sourceUrl">
        <div class="flex flex-row gap-8 min-w-[160px] mr-8">
          <button
            nz-button
            nzSize="small"
            nzType="info"
            (click)="goToSourceUrl(message.sourceUrl)"
            class="flex items-center gap-4 justify-center"
          >
            <twaice-fe-svg-icon class="mt-[-2px]" name="external-link" source="tabler" size="16px" />
            <div>Go to Dashboard</div>
          </button>
        </div>
      </nz-comment-action>
    </nz-comment>
    <nz-divider *ngIf="!last" class="my-8"></nz-divider>
    } @empty {
    <nz-empty nzNotFoundContent="No notes yet"></nz-empty>
    }
  </div>
  <div class="border-t-1 border-solid">
    <nz-comment class="mx-24">
      <nz-avatar nz-comment-avatar nzIcon="user"> </nz-avatar>
      <nz-comment-content>
        <nz-form-item class="mb-16">
          <textarea placeholder="Add a new note here..." [(ngModel)]="newMessage" nz-input rows="5"></textarea>
        </nz-form-item>
        <div class="flex gap-16 flex-row items-center">
          <nz-form-item class="mb-0">
            <button nz-button nzType="primary" [nzLoading]="submittingMessage" [disabled]="!newMessage" (click)="postMessage()">
              Add Comment
            </button>
          </nz-form-item>

          <div class="flex flex-row gap-8 items-center">
            <nz-switch nzSize="small" [(ngModel)]="includeLink"></nz-switch> Include link to current dashboard
          </div>
        </div>
      </nz-comment-content>
    </nz-comment>
  </div>
</div>
