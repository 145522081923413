/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAction, props } from '@ngrx/store';
import {
  BasePerformanceMangerParams,
  StringKPIInterface,
  StringKPIParams,
  SystemInsightsParams,
  SystemKPIInterface,
  SystemKPIParams,
  SystemStatisticsInterface,
  SystemStatisticsParams,
} from '@twaice-fe/shared/models';

export const fetchBalancingSystemStatistics = createAction(
  '[Performance Manager] Fetch balancing system statistics',
  props<SystemStatisticsParams>()
);

export const loadBalancingSystemStatisticsSuccess = createAction(
  '[Performance Manager/API] Load balancing system statistics Success',
  props<{ balancingSystemStatistics: SystemStatisticsInterface }>()
);

export const loadBalancingSystemStatisticsFailure = createAction(
  '[Performance Manager/API] Load balancing system statistics Failure',
  props<{ error: any }>()
);

export const selectBalancingSystemStatisticsDay = createAction(
  '[Performance Manager/API] Select balancing System Statistics by id',
  props<{ selectedDay: number }>()
);
export const cancelBalancingSystemStatisticsRequest = createAction(
  '[Performance Manager/API] Cancel balancing system statistics http request'
);

export const fetchTemperatureSystemStatistics = createAction(
  '[Performance Manager] Fetch temperature system statistics',
  props<SystemStatisticsParams>()
);

export const loadTemperatureSystemStatisticsSuccess = createAction(
  '[Performance Manager/API] Load temperature system statistics Success',
  props<{ temperatureSystemStatistics: SystemStatisticsInterface }>()
);

export const loadTemperatureSystemStatisticsFailure = createAction(
  '[Performance Manager/API] Load temperature system statistics Failure',
  props<{ error: any }>()
);

export const selectTemperatureSystemStatisticsDay = createAction(
  '[Performance Manager/API] Select temperature System Statistics by id',
  props<{ selectedDay: number }>()
);

export const cancelTemperatureSystemStatisticsRequest = createAction(
  '[Performance Manager/API] Cancel temperature system statistics http request'
);

/**
 * System KPI data for inverter level actions
 */
export const fetchSystemKPIDataInverter = createAction(
  '[Performance Manager] Fetch system KPI data inverter',
  props<SystemKPIParams>()
);

export const loadSystemKPIDataInverterSuccess = createAction(
  '[Performance Manager/API] Load system KPI data inverter Success',
  props<{ systemKPIData: SystemKPIInterface }>()
);

export const loadSystemKPIDataInverterFailure = createAction(
  '[Performance Manager/API] Load system KPI data inverter Failure',
  props<{ error: any }>()
);

export const cancelSystemKPIDataInverterRequest = createAction(
  '[Performance Manager/API] Cancel system KPI data inverter http request'
);

/**
 * System KPI data for string level actions
 */
export const fetchSystemKPIDataString = createAction(
  '[Performance Manager] Fetch system KPI data string',
  props<SystemKPIParams>()
);

export const loadSystemKPIDataStringSuccess = createAction(
  '[Performance Manager/API] Load system KPI data string Success',
  props<{ systemKPIData: SystemKPIInterface }>()
);

export const loadSystemKPIDataStringFailure = createAction(
  '[Performance Manager/API] Load system KPI data string Failure',
  props<{ error: any }>()
);

export const cancelSystemKPIDataStringRequest = createAction(
  '[Performance Manager/API] Cancel system KPI data string http request'
);

/**
 * System insights actions
 */
export const fetchSystemInsights = createAction('[Performance Manager] Fetch system insights', props<SystemInsightsParams>());

export const flushSystemInsights = createAction('[Performance Manager/API] Flush system insights');

export const loadSystemInsightsSuccess = createAction(
  '[Performance Manager/API] Load system insights Success',
  props<{ systemInsights: any }>()
);

export const loadSystemInsightsFailure = createAction(
  '[Performance Manager/API] Load system insights Failure',
  props<{ error: any }>()
);

export const cancelSystemInsightsRequest = createAction('[Performance Manager/API] Cancel system insights data http request');

/**
 * System severity level actions
 */
export const fetchSystemSeverityLevel = createAction(
  '[Performance Manager] Fetch system severity level',
  props<BasePerformanceMangerParams>()
);

export const loadSystemSeverityLevelSuccess = createAction(
  '[Performance Manager/API] Load system severity level Success',
  props<{ systemSeverityLevel: any }>()
);

export const loadSystemSeverityLevelFailure = createAction(
  '[Performance Manager/API] Load system severity level Failure',
  props<{ error: any }>()
);

export const cancelSystemSeverityLevelRequest = createAction(
  '[Performance Manager/API] Cancel system severity level data http request'
);

export const fetchStringKPIData = createAction('[Performance Manager] Fetch string KPI data', props<StringKPIParams>());

export const loadStringKPIDataSuccess = createAction(
  '[Performance Manager/API] Load string KPI data Success',
  props<{ stringKPIData: StringKPIInterface }>()
);

export const loadStringKPIDataFailure = createAction(
  '[Performance Manager/API] Load string KPI data Failure',
  props<{ error: any }>()
);

export const cancelStringKPIDataRequest = createAction('[Performance Manager/API] Cancel string KPI data http request');

export const syncSelectedDayIntoUrlSuccess = createAction('[Performance Manager] Selected Day URL sync success');

export const selectComponent = createAction(
  '[Performance Manager] Select component',
  props<{ componentBk: string | undefined }>()
);
