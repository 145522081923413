import { Component, Input } from '@angular/core';
import { CellConfigInterface } from '../../models/cell-config.interface';

@Component({
  selector: 'twaice-fe-icon-cell',
  templateUrl: 'icon-cell.component.html',
  standalone: false,
})
export class IconCellComponent {
  @Input() dense: boolean;
  @Input() data: CellConfigInterface;

  constructor() {}
}
