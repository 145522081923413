/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { selectors } from '@twaice-fe/frontend/shared/store';
import jwtDecode from 'jwt-decode';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { userTrackingActions } from 'libs/frontend/shared/store/src/actions';
import mixpanel from 'mixpanel-browser';
import { distinctUntilChanged, filter, from, map, switchMap, tap } from 'rxjs';
import { AuthService } from './auth.service';
import { CookieConsentService } from './cookie-consent.service';

const { configsSelectors, authSelectors } = selectors;

@Injectable({
  providedIn: 'root',
})
export class UserTrackingService {
  constructor(
    private authService: AuthService,
    private cookieConsentService: CookieConsentService,
    private store: Store,
    private router: Router
  ) {}

  setupMixpanelTracking(token: string): void {
    if (!token) return;

    mixpanel.init(token, {
      debug: false,
      track_pageview: 'url-with-path',
      persistence: 'localStorage',
      // this is an output of the OpsStage/MixpanelProxyStack stack.
      // As this value should not change it's hardcoded here but could be injected dynamically as well
      api_host: 'https://track.ops.twaice.ai',
      ignore_dnt: true,
    });

    this.cookieConsentService
      .getChoices()
      .pipe(distinctUntilChanged())
      .subscribe((optIn) => {
        if (optIn === false) {
          mixpanel.opt_out_tracking();
          return;
        }

        if (!mixpanel.has_opted_in_tracking()) {
          mixpanel.opt_in_tracking();
        }
        this.store.dispatch(userTrackingActions.mixpanelSetup());
      });
  }

  setupIntercomIntegration(intercomAppId: string): void {
    if (!intercomAppId) return;

    this.store
      .select(authSelectors.getUser)
      .pipe(
        filter((user) => !!user),
        switchMap(() => this.store.select(configsSelectors.getCustomerType)),
        filter((customerType) => !!customerType),
        switchMap((customerType) =>
          from(this.authService.getIdToken()).pipe(
            map((token) => {
              const intercomUserHash = jwtDecode(token)?.['intercom_user_hash'];
              // @ts-expect-error: Intercom provided integration script --> ignore formatting & disable typechecking
              // taken from: https://developers.intercom.com/installing-intercom/web/installation/
              // prettier-ignore
              // eslint-disable-next-line
              (function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src = 'https://widget.intercom.io/widget/' + intercomAppId;var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s, x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();

              const userName = this.authService.getUsername();
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              (window as any).Intercom('boot', {
                app_id: intercomAppId,
                api_base: 'https://api-iam.eu.intercom.io',
                user_hash: intercomUserHash,
                ...(userName && { email: userName }),
                ...(customerType && { customer_type: customerType }),
              });
            })
          )
        ),
        switchMap(() => this.router.events),
        tap((event) => {
          if (!(event instanceof NavigationEnd)) return;

          const userName = this.authService.getUsername();
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (window as any).Intercom('update', {
            ...(userName && { email: userName }),
          });
        }),
        switchMap(() => this.authService.getUserObservable()),
        tap((user) => {
          if (user) return;

          // end session if user logs out
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (window as any).Intercom('shutdown');
        })
      )
      .subscribe();
  }
}
