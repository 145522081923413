import { Injectable } from '@angular/core';
import { onBoardingInProgress, StatsValuesConfigInterface, STATUS_CONFIGS } from './system-portfolio-overview-table-config';
import {
  HealthSummary,
  ImbalanceState,
  ImbalanceSummary,
  PerformanceManagerKPI,
  RoundTripEfficiencyRating,
  RTESummary,
  SafetySummary,
  SpreadState,
} from '@twaice-fe/shared/models';

@Injectable()
export class SystemPortfolioOverviewTableService {
  getStatus<T extends keyof typeof STATUS_CONFIGS>({
    category,
    stateKey,
    value,
    link,
  }: {
    category: T;
    stateKey: keyof (typeof STATUS_CONFIGS)[T] | null | undefined;
    value?: number | null;
    link: string;
  }): StatsValuesConfigInterface {
    if (!stateKey) {
      return { ...onBoardingInProgress, link };
    }

    return {
      ...STATUS_CONFIGS[category][stateKey],
      value: value !== undefined && stateKey !== SpreadState.STABLE ? Math.round(value * 10) / 10 : null,
      link,
    };
  }

  getSafetyScoreStatus(safetySummary: SafetySummary | null): StatsValuesConfigInterface {
    return this.getStatus({
      category: 'safety',
      stateKey: safetySummary?.safetyState,
      link: 'safety',
    });
  }

  getPerformanceStatus(imbalanceSummary: ImbalanceSummary | null): StatsValuesConfigInterface {
    return this.getStatus({
      category: 'performance',
      stateKey: imbalanceSummary?.imbalanceState,
      value:
        imbalanceSummary?.imbalanceState !== ImbalanceState.BALANCED ? imbalanceSummary?.imbalancedComponentsPercentage : null,
      link: `/performance-manager?kpi=${PerformanceManagerKPI.SOC_SPREAD}`,
    });
  }

  getHealthStatus(healthSummary: HealthSummary | null): StatsValuesConfigInterface {
    return this.getStatus({
      category: 'health',
      stateKey: healthSummary?.soh?.spreadState,
      value: healthSummary?.soh?.spreadState !== SpreadState.STABLE ? healthSummary?.soh?.spread : null,
      link: '/energy/health',
    });
  }

  getRteStatus(rteSummary: RTESummary | null): StatsValuesConfigInterface {
    return this.getStatus({
      category: 'rte',
      stateKey: rteSummary?.rteState,
      value: rteSummary?.rteState !== RoundTripEfficiencyRating.HIGH ? rteSummary?.rtePercentage : null,
      link: '/warranty-tracker',
    });
  }
}
