import { CustomerNameTranslationService, StorageLevelIdentifierUtils } from '@twaice-fe/frontend/shared/services';
import { colorLight, EventsInfo, fontSizeSm, HoverEvent, spacerS, spacerXs } from '@twaice-fe/frontend/shared/utilities';
import { StorageLevel } from '@twaice-fe/shared/models';
import { select } from 'd3';

export class HealthBarChartTooltip {
  private readonly tooltip: HTMLDivElement;

  private customerNameTranslationService: CustomerNameTranslationService;

  constructor(customerNameTranslationService: CustomerNameTranslationService) {
    this.tooltip = document.createElement('div');

    this.tooltip.style.position = 'absolute';

    this.tooltip.style.top = '0';

    this.tooltip.style.color = colorLight;

    this.tooltip.style.backgroundColor = 'rgba(4, 15, 38, 0.95)';

    this.tooltip.style.fontSize = `${fontSizeSm}px`;

    this.tooltip.style.lineHeight = `${fontSizeSm}px`;

    this.tooltip.style.zIndex = '10';

    this.tooltip.style.padding = `${spacerS}px`;

    this.tooltip.style.borderRadius = `${spacerXs}px`;

    this.tooltip.style.display = 'none';

    this.tooltip.style.minWidth = '14px';

    this.tooltip.style.textAlign = 'center';

    this.customerNameTranslationService = customerNameTranslationService;
  }

  show({ x, y, content, chartContainer }: { x: number; y: number; content: string; chartContainer: HTMLDivElement }) {
    this.tooltip.innerHTML = content;

    this.tooltip.style.display = 'inline-block';

    select(chartContainer).node().appendChild(this.tooltip);

    const xTranslate = `${x - this.tooltip.offsetWidth / 2}px`;

    const yTranslate = `${y - this.tooltip.offsetHeight}px`;

    this.tooltip.style.display = 'block';

    this.tooltip.style.transform = `translate(${xTranslate}, ${yTranslate})`;

    this.tooltip.innerHTML = content;

    this.tooltip.style.display = 'block';
  }

  remove() {
    this.tooltip.remove();
  }

  onHover(event: HoverEvent<EventsInfo>, chartContainer: HTMLDivElement) {
    const { type, data, cell } = event;

    if (type === 'mouseover') {
      if (Array.isArray(data.yData)) {
        throw new Error('data.yData should not be an Array.');
      }

      const rect = <SVGRectElement>cell.node();

      rect.style.cursor = 'url("assets/icons/applications/icon-arrow-tooltip.svg"), auto';

      const marginToRect = -8;

      const x = rect.x.baseVal.value + rect.width.baseVal.value / 2;

      const y = rect.y.baseVal.value + marginToRect;

      const content = this.customerNameTranslationService.replaceLevelIdentifiers(
        StorageLevelIdentifierUtils.toLevelString(StorageLevel.String, false, data.yData, true)
      );

      this.show({ x, y, content, chartContainer });
    } else {
      this.remove();
    }
  }
}
