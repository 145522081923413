import { Pipe, PipeTransform } from '@angular/core';
import { environment } from '@env/fe';

/**
 * @class SafeAssetPathPipe
 * @description Pipe for transforming relative asset URLs to absolute static URLS to be used in widget integration
 * */
@Pipe({
  name: 'safeAssetPath',
  standalone: false,
})
export class SafeAssetPathPipe implements PipeTransform {
  transform(path: string): string {
    const isLocal = environment.version.includes('local');
    const isNotEmbedded = window.location.hostname.endsWith('twaice.cloud') || window.location.hostname.endsWith('twaice.ai');
    if (isLocal || isNotEmbedded) {
      return path;
    }

    // get semver version from version string e.g. 'local-prod-v1.2.3' => 'v1.2.3'
    const stripDownVersion = environment.version.includes('-')
      ? environment.version.substring(environment.version.indexOf('-v') + 1)
      : environment.version;

    return `${environment.staticFilesHost}widgets/fleet-analytics/${stripDownVersion}/${path}`;
  }
}
