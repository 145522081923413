/* eslint-disable @typescript-eslint/naming-convention */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { missingCalculationAlert } from '@twaice-fe/frontend/shared/utilities';
import { KPI_STANDARD_SENSOR_BKS, KPI_UNITS, SKIP_BE4FE_HEADER } from '@twaice-fe/shared/constants';
import {
  AggregationScope,
  HealthAlert,
  HealthContainerV2,
  HealthRequestMeasurementParameters,
  HealthRequestParameters,
  HealthV2,
  HttpParametersObject,
  KPI,
  SensorListResponseV2,
  SingleRackKpisEnum,
} from '@twaice-fe/shared/models';
import { forkJoin, mergeMap, Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
// eslint-disable-next-line @nx/enforce-module-boundaries
import {
  KpiMeasurement,
  KpiMeasurementsResponse,
  KpiSensor,
  MeasurementResult,
  MeasurementsV2Response,
  MeasurementV2,
  SensorInfo,
} from '../../../../features/storage-monitoring/data-explorer/src/lib/data-explorer.models';

interface ComponentWithSensors {
  component_bk: string;
  sensors: SensorListResponseV2['data']['items'];
}

@Injectable()
export class HealthService {
  constructor(private httpClient: HttpClient) {}

  /**
   * Request health data for a specific systemID at depth level
   *
   * @param kpiName
   * @param requestParams
   * @param aggregationScope
   */
  getHealthData({
    requestParams,
    customerBk,
    systemBk,
    aggregationScope,
    levelBk,
  }: {
    requestParams: HealthRequestParameters;
    customerBk: string;
    systemBk: string;
    aggregationScope: AggregationScope;
    levelBk: string;
  }): Observable<HealthV2 | HealthAlert> {
    let healthParameters: HttpParametersObject = {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      customer_bk: customerBk,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      system_bk: systemBk,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      aggregation_scope: aggregationScope,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      aggregation_type: requestParams.aggregationType,
      level_bk: levelBk,
    };

    const url = requestParams.useLatest
      ? `analytics/health-kpis/${requestParams.kpi}/latest`
      : `analytics/health-kpis/${requestParams.kpi}`;

    if (!requestParams.useLatest) {
      healthParameters = {
        ...healthParameters,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        start_date: requestParams.startDate,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        end_date: requestParams.endDate,
        sampling_seconds: requestParams.sampling_seconds,
      };
    }
    return this.httpClient
      .get<HealthV2 | HealthAlert>(url, { params: healthParameters, headers: { [SKIP_BE4FE_HEADER]: '1' } })
      .pipe(
        map((health) => ({ ...health['data'], kpi: requestParams.kpiName })),
        catchError(() => {
          const healthAlert = new HealthAlert();
          healthAlert.kpi = requestParams.kpiName;
          healthAlert.alert = missingCalculationAlert;
          return of(healthAlert);
        })
      );
  }

  sortSensorDataDescending(sensorValues: number[], sensorTimestamps: string[]): [number[], string[]] {
    const paired = sensorValues.map((value, index) => ({
      value,
      timestamp: new Date(sensorTimestamps[index]).getTime(),
    }));

    paired.sort((a, b) => b.timestamp - a.timestamp);
    return [paired.map((p) => p.value), paired.map((p) => new Date(p.timestamp).toISOString())];
  }

  avgValues = (maxValues: number[], minValues: number[]): number[] =>
    maxValues.map((maxValue, index) => (maxValue + minValues[index]) / 2);

  createSensorConfigObservables(systemID: string, componentBks: string[]): Observable<ComponentWithSensors>[] {
    return componentBks.map((component_bk) => {
      const sensors_url = `configuration/systems/${systemID}/containers/${component_bk}/sensors`;
      return this.httpClient
        .get<SensorListResponseV2>(sensors_url, {
          headers: { [SKIP_BE4FE_HEADER]: '1' },
        })
        .pipe(
          map((data) => ({
            component_bk,
            sensors: data.data.items,
          }))
        );
    });
  }

  processSensorsForKpi(componentsWithSensors: ComponentWithSensors[], kpi: string): { [aggregationType: string]: SensorInfo[] } {
    const sensorsByAggregationType: { [aggregationType: string]: SensorInfo[] } = {};

    componentsWithSensors.forEach(({ component_bk, sensors }) => {
      KPI_STANDARD_SENSOR_BKS[kpi].forEach((kpiSensor) => {
        const sensor = sensors.find((s) => s.standard_sensor_bk === kpiSensor.standard_sensor_bk);
        if (sensor) {
          const aggregationType = kpiSensor.aggregation_type;
          if (!sensorsByAggregationType[aggregationType]) {
            sensorsByAggregationType[aggregationType] = [];
          }
          sensorsByAggregationType[aggregationType].push({
            component_bk,
            type: kpiSensor.type,
            sensor_name: kpiSensor.sensor_name,
            standard_sensor_bk: kpiSensor.standard_sensor_bk,
            sensor_bk: sensor.sensor_bk,
          });
        }
      });
    });
    return sensorsByAggregationType;
  }

  createMeasurementRequests(
    url: string,
    healthParameters: HttpParametersObject,
    sensorsByAggregationType: { [key: string]: SensorInfo[] }
  ): Observable<MeasurementResult>[] {
    return Object.entries(sensorsByAggregationType).map(([aggregationType, sensors]) => {
      const params = {
        ...healthParameters,
        sensor_bks: sensors.map((s) => s.sensor_bk),
        aggregation_type: aggregationType,
      };

      return this.httpClient
        .get<MeasurementsV2Response>(url, {
          params: JSON.parse(JSON.stringify(params)),
          headers: { [SKIP_BE4FE_HEADER]: '1' },
        })
        .pipe(
          map((response) => ({
            aggregationType,
            sensors,
            response,
          }))
        );
    });
  }

  processSensorMeasurements(
    componentBk: string,
    results: { sensors: SensorInfo[]; response: MeasurementsV2Response }[],
    healthV2: HealthV2
  ): HealthV2 {
    results.forEach(({ sensors, response }) => {
      const componentSensors = sensors.filter((s) => s.component_bk === componentBk);

      componentSensors.forEach((sensor) => {
        const measurements = this.findSensorMeasurements(sensor, response);
        if (measurements.length) {
          const healthContainer = this.createHealthContainer(componentBk, sensor, measurements);
          this.assignHealthContainer(healthV2, sensor.type, healthContainer);
        }
      });
    });

    return healthV2;
  }

  findSensorMeasurements(sensor: SensorInfo, response: MeasurementsV2Response): MeasurementV2[] {
    return response.data.items.filter((item) => item.sensor_bk === sensor.sensor_bk);
  }

  createHealthContainer(componentBk: string, sensor: SensorInfo, measurements: MeasurementV2[]): HealthContainerV2 {
    const [sortedValues, sortedTimestamps] = this.sortSensorDataDescending(
      measurements.map((m) => m.value),
      measurements.map((m) => m.event_timestamp)
    );

    return {
      component_bk: componentBk,
      sensor_name: sensor.sensor_name,
      sensor_values: sortedValues,
      sensor_timestamps: sortedTimestamps,
    };
  }

  assignHealthContainer(healthV2: HealthV2, sensorType: string, healthContainer: HealthContainerV2 | HealthContainerV2): void {
    switch (sensorType) {
      case 'data':
        healthV2.data = [healthContainer];
        break;
      case 'upperAreaConfidenceInterval':
        healthV2.upperAreaConfidenceInterval = healthContainer;
        break;
      case 'lowerAreaConfidenceInterval':
        healthV2.lowerAreaConfidenceInterval = healthContainer;
        break;
    }
  }

  getHealthMeasurementsDataForTemperature({
    customerBk,
    systemBk,
    systemID,
    componentBks,
    startDate,
    endDate,
    url,
    healthParameters,
  }: {
    customerBk: string;
    systemBk: string;
    systemID: string;
    componentBks: string[];
    startDate: string;
    endDate: string;
    url: string;
    healthParameters: HttpParametersObject;
  }): Observable<HealthV2[] | HealthAlert> {
    const sensorConfigObservables = this.createSensorConfigObservables(systemID, componentBks);
    const kpi = SingleRackKpisEnum.TEMPERATURE;

    return forkJoin(sensorConfigObservables).pipe(
      switchMap((componentsWithSensors) => {
        // Group sensors by aggregation type and map sensors
        const sensorsByAggregationType = this.processSensorsForKpi(componentsWithSensors, kpi);
        // Create API calls for each aggregation type
        const apiCalls = this.createMeasurementRequests(url, healthParameters, sensorsByAggregationType);
        // Execute all API calls and combine results
        return forkJoin(apiCalls).pipe(
          map((results) =>
            componentBks.map((componentBk) => {
              const baseHealthV2: HealthV2 = {
                customer_bk: customerBk,
                system_bk: systemBk,
                component_bk: componentBk,
                level_bk: null,
                start_date: new Date(startDate).toISOString(),
                end_date: new Date(endDate).toISOString(),
                aggregation_scope: null,
                aggregation_type: null,
                kpi: kpi,
                unit: KPI_UNITS[kpi],
                data: [],
              };
              return this.processSensorMeasurements(componentBk, results, baseHealthV2);
            })
          )
        );
      })
    );
  }

  groupSensorsByAggregationType<T extends KpiSensor>(kpiSensors: T[]): Record<string, T[]> {
    return kpiSensors.reduce(
      (groups, kpiSensor) => {
        const aggregationType = kpiSensor.aggregation_type;
        if (!groups[aggregationType]) {
          groups[aggregationType] = [];
        }
        groups[aggregationType].push(kpiSensor);
        return groups;
      },
      {} as Record<string, T[]>
    );
  }

  createApiCallsForAggregationTypes(
    url: string,
    healthParameters: HttpParametersObject,
    aggregationTypeGroups: Record<string, KpiSensor[]>,
    kpi_bks: { component_bk: string; standard_sensor_bk: string }[]
  ): Observable<KpiMeasurementsResponse>[] {
    return Object.entries(aggregationTypeGroups).map(([aggregationType]) => {
      const params = {
        ...healthParameters,
        kpi_bks: JSON.stringify(kpi_bks),
        aggregation_type: aggregationType,
      };

      return this.httpClient.get<KpiMeasurementsResponse>(url, {
        params: JSON.parse(JSON.stringify(params)),
        headers: { [SKIP_BE4FE_HEADER]: '1' },
      });
    });
  }

  findMatchingResponse(
    kpiSensor: KpiSensor,
    aggregationTypeGroups: Record<string, KpiSensor[]>,
    responses: KpiMeasurementsResponse[]
  ): KpiMeasurementsResponse {
    const responseIndex = Object.keys(aggregationTypeGroups).findIndex((type) => type === kpiSensor.aggregation_type);

    return responses[responseIndex];
  }

  filterComponentItems(
    response: KpiMeasurementsResponse,
    componentBk: string,
    standardSensorBks: string | string[]
  ): KpiMeasurement[] {
    return response.data.items.filter((item) => {
      const isComponentMatch = item.kpi_bk.component_bk === componentBk;

      if (Array.isArray(standardSensorBks)) {
        return isComponentMatch && standardSensorBks.includes(item.kpi_bk.standard_sensor_bk);
      } else {
        return isComponentMatch && item.kpi_bk.standard_sensor_bk === standardSensorBks;
      }
    });
  }

  processHealthData(
    sensorResults: Array<
      Array<{
        type: string;
        sensorData: HealthContainerV2;
      }>
    >,
    componentBk: string,
    kpi: string,
    baseHealthData: Omit<HealthV2, 'data' | 'upperAreaConfidenceInterval' | 'lowerAreaConfidenceInterval'>
  ): HealthV2 {
    const healthV2: HealthV2 = {
      ...baseHealthData,
      component_bk: componentBk,
      data: [],
    };

    sensorResults.forEach((componentResults, index) => {
      const result = componentResults.find((r) => r.sensorData.component_bk === componentBk);

      if (result) {
        const kpiSensor = KPI_STANDARD_SENSOR_BKS[kpi][index];
        this.assignHealthContainer(healthV2, kpiSensor.type, result.sensorData as HealthContainerV2);
      }
    });

    return healthV2;
  }

  getHealthMeasurementsDataForKPI({
    customerBk,
    systemBk,
    componentBks,
    startDate,
    endDate,
    url,
    healthParameters,
    kpi,
  }: {
    customerBk: string;
    systemBk: string;
    componentBks: string[];
    startDate: string;
    endDate: string;
    url: string;
    healthParameters: HttpParametersObject;
    kpi: SingleRackKpisEnum | KPI;
  }): Observable<HealthV2[] | HealthAlert> {
    const kpiString = kpi.valueOf();
    // Group KPI sensors by aggregation_type
    const aggregationTypeGroups = this.groupSensorsByAggregationType(KPI_STANDARD_SENSOR_BKS[kpiString]);

    const kpi_bks = KPI_STANDARD_SENSOR_BKS[kpiString].flatMap((kpiSensor) =>
      (Array.isArray(kpiSensor.standard_sensor_bk) ? kpiSensor.standard_sensor_bk : [kpiSensor.standard_sensor_bk]).flatMap(
        (sensor) =>
          componentBks.map((component_bk) => ({
            component_bk,
            standard_sensor_bk: sensor,
          }))
      )
    );
    // Create one API call per aggregation_type
    const apiCalls = this.createApiCallsForAggregationTypes(url, healthParameters, aggregationTypeGroups, kpi_bks);
    return forkJoin(apiCalls).pipe(
      map((responses) => {
        const sensorResults = KPI_STANDARD_SENSOR_BKS[kpi].map((kpiSensor) =>
          componentBks.map((component_bk) => {
            // Find the response that matches this kpiSensor's aggregation_type
            const response = this.findMatchingResponse(kpiSensor, aggregationTypeGroups, responses);
            const componentItems = this.filterComponentItems(response, component_bk, kpiSensor.standard_sensor_bk);

            if (kpiString === 'soc' && kpiSensor.type === 'data') {
              const processedData = kpiSensor.standard_sensor_bk.map((sensor) => {
                const sensorData = componentItems.filter((item) => item.kpi_bk.standard_sensor_bk === sensor);
                return {
                  sensor_values: sensorData.map((item) => item.value),
                  sensor_timestamps: sensorData.map((item) => item.event_timestamp),
                };
              });

              const [sortedValues, sortedTimestamps] = this.sortSensorDataDescending(
                this.avgValues(processedData[0].sensor_values, processedData[1].sensor_values),
                processedData[0].sensor_timestamps
              );

              return {
                type: kpiSensor.type,
                sensorData: {
                  component_bk,
                  sensor_name: kpiSensor.sensor_name,
                  sensor_values: sortedValues,
                  sensor_timestamps: sortedTimestamps,
                },
              };
            } else {
              const [sortedValues, sortedTimestamps] = this.sortSensorDataDescending(
                componentItems.map((item) => item.value),
                componentItems.map((item) => item.event_timestamp)
              );
              return {
                type: kpiSensor.type,
                sensorData: {
                  component_bk,
                  sensor_name: kpiSensor.sensor_name,
                  sensor_values: sortedValues,
                  sensor_timestamps: sortedTimestamps,
                },
              };
            }
          })
        );

        return componentBks
          .map((component_bk) => {
            const baseHealthV2: Omit<HealthV2, 'data'> = {
              customer_bk: customerBk,
              system_bk: systemBk,
              component_bk: component_bk,
              level_bk: null,
              start_date: new Date(startDate).toISOString(),
              end_date: new Date(endDate).toISOString(),
              aggregation_scope: null,
              aggregation_type: null,
              kpi: kpi,
              unit: KPI_UNITS[kpi],
            };
            return this.processHealthData(sensorResults, component_bk, kpi, baseHealthV2);
          })
          .flat();
      })
    );
  }

  getHealthMeasurementsData({
    systemID,
    customerBk,
    systemBk,
    componentBks,
    kpi,
    healthRequestMeasurementParameters,
  }: {
    systemID: string;
    customerBk: string;
    systemBk: string;
    componentBks: string[];
    kpi: SingleRackKpisEnum | KPI;
    healthRequestMeasurementParameters: HealthRequestMeasurementParameters;
  }): Observable<HealthV2 | HealthAlert> {
    const healthParameters: HttpParametersObject = {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      start_timestamp: healthRequestMeasurementParameters.startDate,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      end_timestamp: healthRequestMeasurementParameters.endDate,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      sampling_seconds: healthRequestMeasurementParameters.sampling_seconds,
    };

    const url =
      kpi !== SingleRackKpisEnum.TEMPERATURE
        ? `data-explorer/${customerBk}/${systemBk}/kpis`
        : `data-explorer/${customerBk}/${systemBk}/measurements`;

    if (kpi === SingleRackKpisEnum.TEMPERATURE) {
      return this.getHealthMeasurementsDataForTemperature({
        customerBk,
        systemBk,
        systemID,
        componentBks,
        startDate: healthRequestMeasurementParameters.startDate,
        endDate: healthRequestMeasurementParameters.endDate,
        url,
        healthParameters,
      }).pipe(
        mergeMap((response: HealthV2[] | HealthAlert) => {
          if (response instanceof HealthAlert) {
            return of(response);
          }
          // If it's an array of HealthV2, return each item individually
          return response;
        })
      );
    } else {
      return this.getHealthMeasurementsDataForKPI({
        customerBk,
        systemBk,
        componentBks: componentBks,
        startDate: healthRequestMeasurementParameters.startDate,
        endDate: healthRequestMeasurementParameters.endDate,
        url,
        healthParameters,
        kpi,
      }).pipe(
        mergeMap((response: HealthV2[] | HealthAlert) => {
          if (response instanceof HealthAlert) {
            return of(response);
          }
          return response;
        })
      );
    }
  }
}
