<div class="chart-info-wrapper">
  <div class="chart-info-content">
    <span class="chart-info-title" data-test="chart-info-title">{{ chartTitle }}</span>
    <ng-container *ngIf="percent != null && percent !== 100">
      <twaice-fe-svg-icon
        *ngIf="percent === 0; else missingDataTemplate"
        [twaiceFeTooltip]="'This card is based on 100.0% of available data'"
        name="circle-check"
        source="tabler"
        color="green"
        size="20px"
      />
    </ng-container>
    <ng-template #missingDataTemplate>
      <div
        [ngClass]="['missing-data', percent <= 50 ? 'missing-data__yellow' : 'missing-data__red']"
        (click)="filterMissingData.emit()"
      >
        <span>
          Missing Data for
          {{ storageLevel.String | storageLevelToCustomerLevelName: true : chartMissingDataContent.missingDataCount : true }}
        </span>
        <div style="width: 20px">
          <nz-progress
            [nzStrokeColor]="percent <= 50 ? '#c0981e' : '#ad2102'"
            [nzPercent]="percent"
            [nzShowInfo]="false"
            [nzStrokeWidth]="2"
          ></nz-progress>
        </div>
      </div>
    </ng-template>
  </div>
  <div class="chart-info-content">
    <div class="chart-info-content-items" [class.loaded]="chartItemList && chartItemList.length">
      <twaice-fe-chart-info-item
        *ngFor="let item of chartItemList; let i = index"
        [item]="item"
        (itemClick)="itemClick.emit($event)"
      ></twaice-fe-chart-info-item>
    </div>
  </div>
</div>
