import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subject, debounceTime, distinctUntilChanged, filter } from 'rxjs';
import { OptionsFilterInterface } from '../../models/filter-config.interface';

@Component({
  selector: 'twaice-fe-datatable-options-filter',
  templateUrl: 'datatable-options-filter.component.html',
  styleUrls: ['./datatable-options-filter.component.scss'],
  standalone: false,
})
export class DatatableOptionsFilterComponent implements OnInit {
  @Input() filterConfig: OptionsFilterInterface;
  @Input() resetForms$: Subject<boolean>;
  @Output() emitFilterChange: EventEmitter<Record<string, unknown>> = new EventEmitter();

  selectedOption = new FormControl('');

  ngOnInit(): void {
    this.selectedOption.valueChanges
      .pipe(
        filter((val) => !!val),
        debounceTime(500)
      )
      .subscribe((text: string) => {
        this.emitFilterChange.emit({ [this.filterConfig.filterBy]: text });
      });

    this.resetForms$.pipe(distinctUntilChanged()).subscribe((val) => val && this.selectedOption.reset());
  }

  keepOrder() {
    return 0;
  }
}
