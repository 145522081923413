export enum FilterTypeEnum {
  OPTIONS = 'options',
  TEXT = 'text',
  DATE = 'date',
  NUMBER = 'number',
  SELECT = 'select',
}

interface BaseFilterInterface {
  type: FilterTypeEnum;
  filterBy: string;
  label?: string;
}

export interface DateFilterInterface {
  type: FilterTypeEnum.DATE;
  option: 'range' | 'selection';
  label?: string;
  from: string;
  to: string;
}

export interface OptionsFilterInterface extends BaseFilterInterface {
  type: FilterTypeEnum.OPTIONS;
  options: Record<string, string>;
}

export interface TextFilterInterface extends BaseFilterInterface {
  type: FilterTypeEnum.TEXT;
}

export interface SelectFilterInterface extends BaseFilterInterface {
  type: FilterTypeEnum.SELECT;
  options: string[];
}

export interface NumberFilterInterface {
  type: FilterTypeEnum.NUMBER;
  option: 'range' | 'selection';
  label?: string;
  min?: number;
  max?: number;
  from: string;
  to: string;
  value?: number;
  format?: (val: number) => string;
  offset?: (val: number) => string;
}

export type BaseFilterType = OptionsFilterInterface | TextFilterInterface | SelectFilterInterface;
export type FilterConfigType = DateFilterInterface | NumberFilterInterface | BaseFilterType;
