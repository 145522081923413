import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DateFilterInterface } from '../../models/filter-config.interface';
import { FormControl, FormGroup } from '@angular/forms';
import { Subject, debounceTime, distinctUntilChanged, filter, map } from 'rxjs';

@Component({
  selector: 'twaice-fe-datatable-date-filter',
  templateUrl: 'datatable-date-filter.component.html',
  styleUrls: ['datatable-date-filter.component.scss'],
  standalone: false,
})
export class DatatableDateFilterComponent implements OnInit {
  rangeForm = new FormGroup({
    start: new FormControl<Date | null>(null),
    end: new FormControl<Date | null>(null),
  });

  @Input() filterConfig: DateFilterInterface;
  @Input() resetForms$: Subject<boolean>;
  @Output() emitFilterChange: EventEmitter<Record<string, unknown>> = new EventEmitter();

  ngOnInit() {
    this.rangeForm.valueChanges
      .pipe(
        debounceTime(500),
        filter(({ start, end }) => !!start || !!end),
        map((range) => {
          if (range.end) {
            //* Add 1 day to get all values in the day selected.
            //* All end date will have midnight as time
            const endDate = new Date(range.end);
            endDate.setDate(endDate.getDate() + 1);
            range.end = endDate;
          }

          return range;
        })
      )
      .subscribe(({ start, end }) => {
        this.emitFilterChange.emit({
          ...(start && { [this.filterConfig.from]: new Date(start).toISOString().replace('Z', '') }),
          ...(end && { [this.filterConfig.to]: new Date(end).toISOString().replace('Z', '') }),
        });
      });

    this.resetForms$.pipe(distinctUntilChanged()).subscribe((val) => {
      if (val) {
        this.rangeForm.reset();
        this.rangeForm.get('end').setValue(null);
      }
    });
  }
}
