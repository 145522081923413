import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { naturalCompare } from '@twaice-fe/shared/utilities';
import { DataTableColumn, DataTableConfig, DataTablePagination } from './models/data-table-config';
import { SelectionModel } from '@angular/cdk/collections';

@Component({
  selector: 'twaice-fe-data-table',
  templateUrl: './data-table.component.html',
  styleUrls: ['./data-table.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: false,
})
export class DataTableComponent implements OnInit, OnChanges, AfterViewInit {
  // Input parameters of the components
  @Input() data: unknown[];
  @Input() config: DataTableConfig;
  @Input() selectedRow?: unknown;
  @Input() checkedRows?: unknown[];

  // Current configurations
  paginationConfig: DataTablePagination;
  columnsConfig: DataTableColumn[];

  // Default configuration for pagination if not specified or set partially
  private readonly defaultPaginationConfig: DataTablePagination = {
    isVisible: true,
    pageSize: [10, 50, 100, 500],
    defaultPageSize: 100,
  };

  // Default configuration for Date column if not specified or set partially
  private readonly defaultColumnConfig: DataTableColumn = {
    sortable: true,
    name: '',
  };

  @Output() rowClicked: EventEmitter<unknown> = new EventEmitter<unknown>();

  @Output() rowHover: EventEmitter<{ rowValue: unknown; isHover: boolean }> = new EventEmitter<{
    rowValue: unknown;
    isHover: boolean;
  }>();

  dataSource: MatTableDataSource<unknown>;

  displayedColumns: string[];

  rangeLabel: Element;

  isRowClickable: boolean;

  selection: SelectionModel<unknown>;

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatPaginator, { static: false, read: ElementRef }) paginatorEl: ElementRef<Element>;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  ngOnInit() {
    if (this.config?.enableBulkSelection) {
      this.selection = new SelectionModel<unknown>(true, []);

      if (this.checkedRows) {
        this.selection.clear();
        this.selection.select(...this.checkedRows);
      }
    }
  }

  ngOnChanges() {
    if (this.paginator && this.selectedRow) {
      this.paginator.pageIndex = Math.trunc(this.data.indexOf(this.selectedRow) / this.paginator.pageSize);
    }

    // If configuration is missed, we will create it from the first data row
    if (!this.config && this.data) {
      this.config = {
        columns: Object.keys(this.data[0]).map((name) => ({ name })),
      };
    }

    // Merge default and specified configs together for pagination
    this.paginationConfig = Object.assign({}, this.defaultPaginationConfig, this.config.pagination);

    // For columns we do the same and create a flat list of columns to use it in mat-row
    this.displayedColumns = [];

    if (this.config?.enableBulkSelection) {
      this.displayedColumns.push('select');
    }

    this.columnsConfig = this.config.columns.map((column) => {
      this.displayedColumns.push(column.name);
      return Object.assign({ isDate: column.name === 'timestamp' }, this.defaultColumnConfig, column);
    });

    if (this.data) {
      this.dataSource = new MatTableDataSource(this.data);
      this.dataSource.sortData = this.sortTable;
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    }

    if (this.checkedRows && this.selection) {
      this.selection.clear();
      this.selection.select(...this.checkedRows);
    }

    this.isRowClickable = this.rowClicked.observed;

    this.pageChanged(this.paginator && this.selectedRow != null);
  }

  ngAfterViewInit() {
    // If neither configuration nor data are provided then the exception will be thrown
    if (!this.config && !this.data) {
      throw new Error('Neither configuration nor data are specified');
    }

    // Set paginator and sorting
    if (this.dataSource) {
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    }

    // Show page number
    if (this.paginationConfig.isVisible) {
      this.rangeLabel = this.paginatorEl.nativeElement.getElementsByClassName('mat-paginator-range-label').item(0);
      this.pageChanged(false);
    }
  }

  /**
   * Show page number with total number of pages
   */
  pageChanged(isPageIndexChanged = true) {
    if (this.rangeLabel) {
      this.rangeLabel.innerHTML = isPageIndexChanged
        ? `Page: ${this.paginator.pageIndex + 1} of ${this.paginator.getNumberOfPages()}`
        : `Page: 1 of ${Math.ceil(this.data?.length / this.paginationConfig.defaultPageSize)}`;
    }
  }

  onRowClicked(rowValue: unknown) {
    this.selectedRow = rowValue;

    if (rowValue) {
      this.rowClicked.emit(rowValue);
    }
  }

  onMouseEvent(rowValue: unknown, hover: boolean) {
    this.rowHover.emit({ rowValue: rowValue, isHover: hover });
  }

  sortTable(data: any[], sort: MatSort) {
    if (sort.active) {
      return data.sort((a: string, b: string) =>
        sort.direction === 'asc' ? naturalCompare(a, b, sort.active) : naturalCompare(b, a, sort.active)
      );
    } else {
      return data;
    }
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Whether the number of selected reaches the maximum allowed. */
  isLimitReached() {
    return this.selection.selected.length >= this.config.selectionLimit;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.dataSource.data);
  }

  onSelectionChange(row: unknown) {
    this.rowClicked.emit(row);
  }
}
