import { Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Store } from '@ngrx/store';
import { System } from '@twaice-fe/shared/models';
import { systemSelectors } from 'libs/frontend/shared/store/src/selectors';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { SystemChatComponent } from './chat-component/chat.component';

@Injectable({
  providedIn: 'root',
})
export class ChatDrawerService {
  selectedSystem: System;

  constructor(
    private drawerService: NzDrawerService,
    private store: Store
  ) {
    this.store
      .select(systemSelectors.getSelected)
      .pipe(takeUntilDestroyed())
      .subscribe((selectedSystem) => {
        this.selectedSystem = selectedSystem;
      });
  }

  openChatDrawer({
    customerBk,
    systemBk,
    defaultTags,
    tagFilter,
  }: {
    customerBk?: string;
    systemBk?: string;
    defaultTags?: string[];
    tagFilter?: string;
  }) {
    const drawerRef = this.drawerService.create({
      nzContent: SystemChatComponent,
      nzClosable: true,
      nzPlacement: 'right',
      nzSize: 'large',
      nzTitle: 'Notes',
      nzData: {
        customerBk: customerBk || this.selectedSystem.customerBk,
        systemBk: systemBk || this.selectedSystem.systemBk,
        defaultTags,
        tagFilter,
      },
      nzBodyStyle: { padding: 0 },
    });
  }
}
