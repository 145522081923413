import {
  SYSTEM_ID_ENERGY_DEMO,
  SYSTEM_ID_ENERGY_DEMO_UK,
  SYSTEM_ID_ENERGY_US_DEMO_1,
  SYSTEM_ID_ENERGY_US_DEMO_2,
  SYSTEM_ID_ENERGY_US_DEMO_3,
  SYSTEM_ID_PERCIVAL,
} from '@twaice-fe/shared/constants';
import { Store } from '@ngrx/store';
import { actions, selectors } from '@twaice-fe/frontend/shared/store';
import {DestroyRef, Inject, inject, Injectable, Optional} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  filter,
  combineLatest,
  tap,
  Observable,
  distinctUntilChanged,
  withLatestFrom,
  map
} from 'rxjs';
import { getQueryParamAsDate, TimeLength, TimeRangeEnum, TimeRangeEnumDE } from '@twaice-fe/shared/utilities';
import { TimeRangeInterface } from '@twaice-fe/shared/models';
import {
  subHours,
  startOfDay,
  endOfDay,
  subDays,
  startOfMonth,
  endOfMonth,
  subMonths
} from 'date-fns';
import { ActivatedRoute } from '@angular/router';

const { systemActions } = actions;
const { systemSelectors } = selectors;

type TimeRangeConfig = Record<string, Date>;

const defaultStartDates: TimeRangeConfig = {
  [SYSTEM_ID_PERCIVAL]: new Date('2022-10-20T00:00:00'),
  [SYSTEM_ID_ENERGY_DEMO]: new Date('2022-09-01T00:00:00'),
  [SYSTEM_ID_ENERGY_DEMO_UK]: new Date('2023-12-01T00:00:00+00:00'),
  [SYSTEM_ID_ENERGY_US_DEMO_1]: new Date('2024-04-01T00:00:00+00:00'),
  [SYSTEM_ID_ENERGY_US_DEMO_2]: new Date('2024-10-01T00:00:00+00:00'),
  [SYSTEM_ID_ENERGY_US_DEMO_3]: new Date('2024-04-09T00:00:00+00:00'),
};

/* eslint-disable @typescript-eslint/naming-convention */
const timeRangeIdLookup: Record<number, string> = {
  7: 'sevenDays',
  30: 'thirtyDays',
  60: 'sixtyDays',
  90: 'ninetyDays',
  365: 'threeHundredSixtyFiveDays',
};
/* eslint-enable @typescript-eslint/naming-convention */

export interface DemoTimeRangeConfig {
  isDataExplorer?: boolean;
}

/**
 * This service overrides the default time range behaviour for demo systems.
 * By including this service we can set specific selected dates by default,
 * with a per-feature system/date mapping
 */
@Injectable()
export class DemoTimeRangeService {
  destroyRef = inject(DestroyRef);
  private ranges: ({ disabled: boolean; id: string; label: string; value: [Date, Date] })[];
  private isDataExplorer: boolean = false;

  constructor(
    private store: Store,
    private route: ActivatedRoute,
    @Optional() @Inject('DEMO_TIME_RANGE_CONFIG') config?: DemoTimeRangeConfig
  ) {
    this.isDataExplorer = config?.isDataExplorer ?? false;
  }

  getDemoTimeRange(
    timeRangeConfig?: TimeRangeConfig
  ): Observable<{ timeRange: TimeRangeInterface; availableTimeRanges: TimeRangeInterface[] }> {
    return combineLatest([
      this.store.select(systemSelectors.getSelected).pipe(
        filter((system) => !!system),
        distinctUntilChanged((prev, curr) => prev.systemBk === curr.systemBk)
      ),
      this.store.select(systemSelectors.getDefaultTimeRange).pipe(distinctUntilChanged((prev, curr) => prev === curr)),
    ]).pipe(
      takeUntilDestroyed(this.destroyRef),
      withLatestFrom(this.route.queryParams),
      map(([[{ id: systemId }, defaultTimeRange], queryParams]) =>
        this.getDefaultTimeRange(timeRangeConfig, systemId, queryParams, defaultTimeRange)
      ),
      tap(({ timeRange, availableTimeRanges }) =>
        this.store.dispatch(
          systemActions.setInitialTimeRange({
            timeRange,
            availableTimeRanges,
          })
        )
      )
    );
  }

  /**
   * For features that use a time range outside of the global store.
   * If the time range is fetched from the global store then use `setDemoTimeRange`.
   *
   * @param systemId
   * @param timeRangeConfig
   */
  getTimeRangeFromRoute(systemId: string, timeRangeConfig?: TimeRangeConfig) {
    // If it is not pre-set with input variable, we set the time-range to the query value or to the last hour
    const defaultStartDate =
      timeRangeConfig?.[systemId] ?? defaultStartDates[systemId] ?? new Date(new Date().setHours(0, 0, 0, 0));
    const from = this.route.snapshot.queryParams['from']
      ? getQueryParamAsDate(this.route.snapshot.queryParams, 'from')
      : new Date(defaultStartDate.getTime() - TimeLength.DAY * 6);

    const to = this.route.snapshot.queryParams['to']
      ? getQueryParamAsDate(this.route.snapshot.queryParams, 'to')
      : defaultStartDate;

    return { from, to };
  }

  private getDefaultTimeRange(
    timeRangeConfig: TimeRangeConfig | undefined,
    systemId: string,
    queryParams: Record<string, string>,
    defaultTimeRange: TimeRangeEnum | TimeRangeEnumDE |undefined
  ) {
    const selectedRange = {
      startDate: queryParams['startDate'],
      endDate: queryParams['endDate'],
      id: queryParams['timeRange']
    };
    const systemStartDate =
      timeRangeConfig?.[systemId] ?? defaultStartDates[systemId] ?? new Date(new Date().setHours(0, 0, 0, 0));

    const availableTimeRanges = this.getTimeRanges(systemStartDate);
    const timeRange = this.getTimeRange(defaultTimeRange, availableTimeRanges, selectedRange);

    return { timeRange, availableTimeRanges };
  }

  private getTimeRanges(systemStartDate: Date): TimeRangeInterface[] {
    const dateDeltas = [7, 30, 60, 90, 365];
    const now = new Date();
    const currentTime = {
      hours: now.getHours(),
      minutes: now.getMinutes(),
    };

    this.ranges = this.isDataExplorer ? [
        {
          id: 'lastHour',
          value: [subHours(now, 1), now] as [Date, Date],
          label: 'Last Hour',
          disabled: false
        },
        {
          id: 'today',
          value: [startOfDay(now), now] as [Date, Date],
          label: 'Today',
          disabled: false
        },
        {
          id: 'yesterday',
          value: [startOfDay(subDays(now, 1)), endOfDay(subDays(now, 1))] as [Date, Date],
          label: 'Yesterday',
          disabled: false
        },
        {
          id: 'last24Hours',
          value: [subHours(now, 24), now] as [Date, Date],
          label: 'Last 24 Hours',
          disabled: false
        },
        {
          id: 'last7Days',
          value: [subDays(now, 7), now] as [Date, Date],
          label: 'Last 7 Days',
          disabled: false
        },
        {
          id: 'last30Days',
          value: [subDays(now, 30), now] as [Date, Date],
          label: 'Last 30 Days',
          disabled: false
        },
        {
          id: 'thisMonth',
          value: [startOfMonth(now), now] as [Date, Date],
          label: 'This Month',
          disabled: false
        },
        {
          id: 'previousMonth',
          value: [startOfMonth(subMonths(now, 1)), endOfMonth(subMonths(now, 1))] as [Date, Date],
          label: 'Previous Month',
          disabled: false
        }
      ]
      : dateDeltas.map((delta) => {
        const endDate = new Date(systemStartDate);
        endDate.setHours(currentTime.hours);
        endDate.setMinutes(currentTime.minutes);

        const startDate = new Date(subDays(systemStartDate, delta));
        return {
          id: timeRangeIdLookup[delta] ?? null,
          value: [startDate, endDate],
          label: `Last ${delta} days`,
          disabled: false,
        };
      })

    return [
      ...this.ranges,
    ];
  }

  private convertToDate = (timestamp: number): Date => {
    // Check if timestamp is in seconds (typically 10 digits) or milliseconds (typically 13 digits)
    const isMilliseconds = timestamp > 999999999999;
    return new Date(isMilliseconds ? timestamp : timestamp * 1000);
  };

  private getTimeRange(
    defaultTimeRange: TimeRangeEnum | TimeRangeEnumDE | undefined,
    availableTimeRanges: TimeRangeInterface[],
    selectedRange: { startDate: string; endDate: string; id: string }
  ) {
    let selectedTimeRange: TimeRangeInterface;
    if (selectedRange.id === 'custom') {
      if (selectedRange.startDate && selectedRange.endDate) {
        selectedTimeRange = { id: selectedRange.id,
          value: [
            this.convertToDate(Number(selectedRange.startDate)),
          this.convertToDate(Number(selectedRange.endDate)),
          ],
          label: 'Custom',
          disabled: true,
      };
      }
    }
    else {
      selectedTimeRange =
        availableTimeRanges.find((range) => range.id === selectedRange.id) ||
        availableTimeRanges[defaultTimeRange ?? (this.isDataExplorer ? TimeRangeEnumDE.LAST_7_DAYS : TimeRangeEnum.LAST_THIRTY_DAYS)];
    }

    // @ts-ignore
    return selectedTimeRange;
  }
}
