import { Directive } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Store } from '@ngrx/store';
import { differenceInCalendarDays, isValid } from 'date-fns';
import { systemSelectors } from 'libs/frontend/shared/store/src/selectors';
import { NzDatePickerComponent } from 'ng-zorro-antd/date-picker';

@Directive({
  selector: 'nz-date-picker[twClampDateRange],nz-range-picker[twClampDateRange]',
})
export class ClampDateRangeDirective {
  constructor(
    private datePicker: NzDatePickerComponent,
    private store: Store
  ) {
    this.store
      .select(systemSelectors.getSelected)
      .pipe(takeUntilDestroyed())
      .subscribe((system) => {
        if (!system?.metadata) return;

        const installationDate = system.metadata.installationDate;

        // clamp enabled dates
        this.datePicker.nzDisabledDate = (current: Date) => {
          // if the installation date is valid we clamp the date to after the installation date
          if (isValid(new Date(installationDate))) {
            return (
              differenceInCalendarDays(current, new Date()) > 0 ||
              differenceInCalendarDays(current, new Date(installationDate)) < 0
            );
          }

          // else we just clamp it to the current date
          return differenceInCalendarDays(current, new Date()) > 0;
        };
      });
  }
}
