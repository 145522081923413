/* eslint-disable @typescript-eslint/naming-convention */
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { TablerIconsModule } from 'angular-tabler-icons';
import {
  IconAdjustmentsHorizontal,
  IconAlertCircle,
  IconAlertTriangle,
  IconAlertTriangleFilled,
  IconArrowNarrowDown,
  IconArrowRight,
  IconBattery4,
  IconBook,
  IconBulb,
  IconBus,
  IconCategory,
  IconChartArrowsVertical,
  IconChartBar,
  IconChartCandle,
  IconChartDots,
  IconChartLine,
  IconCheck,
  IconChevronDown,
  IconChevronsLeft,
  IconChevronsRight,
  IconCircleCheck,
  IconCircleFilled,
  IconClockHour4,
  IconCube,
  IconDashboard,
  IconDiamond,
  IconDirections,
  IconDots,
  IconDotsVertical,
  IconDownload,
  IconEdit,
  IconExternalLink,
  IconFileAnalytics,
  IconFilter,
  IconFilterPlus,
  IconFlag,
  IconGripVertical,
  IconHeartRateMonitor,
  IconHome,
  IconInfoCircle,
  IconKey,
  IconLayoutSidebarLeftCollapse,
  IconLayoutSidebarRightCollapse,
  IconLock,
  IconLogout,
  IconMail,
  IconMailX,
  IconMessages,
  IconMessagesOff,
  IconPassword,
  IconPencil,
  IconPlayerPlayFilled,
  IconPlus,
  IconPointFilled,
  IconProgress,
  IconRectangleFilled,
  IconServer,
  IconSettings,
  IconSwitchHorizontal,
  IconTag,
  IconTrash,
  IconTriangleFilled,
  IconUrgent,
  IconUserCircle,
  IconUserCog,
  IconWifi,
  IconX,
} from 'angular-tabler-icons/icons';
import { CustomIconModule } from '../icon/custom-icon.module';
import { SvgComponent } from './svg-icon.component';

const tablerIcons = {
  IconAdjustmentsHorizontal,
  IconAlertCircle,
  IconAlertTriangle,
  IconAlertTriangleFilled,
  IconArrowRight,
  IconBattery4,
  IconBook,
  IconBulb,
  IconBus,
  IconCategory,
  IconChartArrowsVertical,
  IconChartBar,
  IconChartCandle,
  IconChartDots,
  IconChartLine,
  IconCheck,
  IconChevronDown,
  IconChevronsLeft,
  IconChevronsRight,
  IconCircleCheck,
  IconCircleFilled,
  IconClockHour4,
  IconCube,
  IconDashboard,
  IconDiamond,
  IconDirections,
  IconDots,
  IconDownload,
  IconExternalLink,
  IconFileAnalytics,
  IconFilter,
  IconFilterPlus,
  IconFlag,
  IconHeartRateMonitor,
  IconHome,
  IconInfoCircle,
  IconKey,
  IconLayoutSidebarLeftCollapse,
  IconLayoutSidebarRightCollapse,
  IconLock,
  IconLogout,
  IconMail,
  IconMailX,
  IconMessages,
  IconMessagesOff,
  IconPassword,
  IconPencil,
  IconPlayerPlayFilled,
  IconPointFilled,
  IconProgress,
  IconServer,
  IconSettings,
  IconSwitchHorizontal,
  IconTag,
  IconTrash,
  IconTriangleFilled,
  IconUrgent,
  IconUserCircle,
  IconUserCog,
  IconWifi,
  IconX,
  IconEdit,
  IconRectangleFilled,
  IconArrowNarrowDown,
  IconDotsVertical,
  IconPlus,
  IconGripVertical,
};

@NgModule({
  declarations: [SvgComponent],
  imports: [CommonModule, CustomIconModule, MatIconModule, TablerIconsModule.pick(tablerIcons)],
  exports: [SvgComponent],
})
export class SvgIconModule {}
