import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'twaice-fe-tab',
  templateUrl: './tab.component.html',
  styleUrls: ['./tab.component.scss'],
  standalone: false,
})
export class TabComponent {
  @Output()
  tabClicked = new EventEmitter<boolean>();

  active = false;
}
