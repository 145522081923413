import { CurrencyPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { actions, selectors } from '@twaice-fe/frontend/shared/store';
import { FeatureFlagsEnum, StorageLevel, SystemDetails } from '@twaice-fe/shared/models';
import { distinctUntilChanged, map, Observable, Subject } from 'rxjs';
import { ColumnItem, SystemOverviewAntTableInterface } from './system-portfolio-overview-table-config';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { StorageLevelToCustomerLevelNamePipe } from '@twaice-fe/frontend/shared/services';
import { isEqual } from '@twaice-fe/shared/utilities';
import { ChatDrawerService } from '../chat/chat-drawer.service';
import { SystemPortfolioOverviewTableService } from './system-portfolio-overview-table.service';
// eslint-disable-next-line @nx/enforce-module-boundaries

const { systemSelectors, configsSelectors } = selectors;
const { systemActions } = actions;

@Component({
  selector: 'twaice-fe-system-portfolio-overview-table',
  templateUrl: 'system-portfolio-overview-table.component.html',
  styleUrls: ['./system-portfolio-overview-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [CurrencyPipe, StorageLevelToCustomerLevelNamePipe],
  // eslint-disable-next-line @angular-eslint/prefer-standalone
  standalone: false,
})
export class SystemPortfolioOverviewTableComponent implements OnInit, OnDestroy {
  systemDetails$: Observable<SystemOverviewAntTableInterface[]>;
  systemOverviewColumns: ColumnItem<SystemDetails>[];
  isSenecDemo = false;
  isFinancialImpactDisabled = false;
  isRTESummaryEnabled = false;

  private destroy$: Subject<void> = new Subject();

  constructor(
    protected store: Store,
    private systemPortfolioOverviewTableService: SystemPortfolioOverviewTableService,
    private router: Router,
    private storageLevelToLevelName: StorageLevelToCustomerLevelNamePipe,
    private chatDrawerService: ChatDrawerService
  ) {}

  ngOnInit(): void {
    this.store
      .select(configsSelectors.getConfigFeatureFlagList)
      .pipe(distinctUntilChanged(isEqual))
      .subscribe((featureFlags: FeatureFlagsEnum[]) => {
        this.isRTESummaryEnabled = featureFlags.includes(FeatureFlagsEnum.SYSTEM_LEVEL_RTE);

        this.isFinancialImpactDisabled = [
          FeatureFlagsEnum.ENERGY_PERFORMANCE_MANAGER_DISABLE_FINANCIAL_IMPACT,
          FeatureFlagsEnum.MODO_ENERGY_INTEGRATION,
        ].some((featureFlag) => featureFlags.includes(featureFlag));

        this.systemOverviewColumns = this.createSystemOverviewColumnItems({
          includeDailyLoss: true,
          includePerformanceStatus: true,
          isRTESummaryEnabled: this.isRTESummaryEnabled,
        });
      });

    this.systemDetails$ = this.store
      .select(systemSelectors.getSystemDetailsList)
      .pipe(map((systemDetailsList) => this.formatSystemDetailsData(systemDetailsList)));
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  navigate(systemId: string, link: string) {
    this.store.dispatch(systemActions.selectSystem({ systemId: systemId }));
    this.router.navigateByUrl(link);
  }

  openChat(customerBk: string, systemBk: string) {
    this.chatDrawerService.openChatDrawer({ customerBk, systemBk });
  }

  private createSystemOverviewColumnItems({
    includeDailyLoss,
    includePerformanceStatus,
    isRTESummaryEnabled,
  }: {
    includeDailyLoss: boolean;
    includePerformanceStatus: boolean;
    isRTESummaryEnabled: boolean;
  }): ColumnItem<SystemDetails>[] {
    const dailyLoss = {
      name: 'Daily Loss',
      class: 'justify-center',
      width: '150px',
      tooltip: {
        link: 'https://help.twaice.cloud/en/articles/206437-energy-loss-calculation-how-and-what',
        linkText: 'Learn more about the Daily Loss',
        content:
          'This metric tracks the loss in energy and revenue due to the difference between current available energy and initial energy of the storage.' +
          ' Calculated based on one cycle per day, with no consideration of aging.' +
          ' If strings within one node can be operated independently, actual losses might be lower.' +
          ' Revenue loss is based on an market specific benchmark. Use this insight to optimize performance.',
      },
    };
    const performanceStatus = {
      name: 'Performance Status',
      tooltip: {
        content:
          'This metric indicates the percentage of nodes in a storage with balancing issues. The severity is based on the node with the highest imbalance. Use this insight to identify imbalances nodes and restore optimal performance',
      },
      class: 'justify-center',
    };

    const rteSummary = {
      name: 'Round Trip Efficiency',
      tooltip: {
        link: 'https://help.twaice.cloud/en/articles/277881-track-round-trip-efficiency-rte-on-system-level',
        linkText: 'Learn more about the Round Trip Efficiency',
        content:
          'The system AC Roundtrip Efficiency (RTE) is calculated based on an aggregation of the battery, inverters and transformers efficiencies.',
      },
      class: 'justify-center',
    };

    return [
      {
        name: this.storageLevelToLevelName.transform(StorageLevel.System, true, 1),
        width: '223px',
      },
      includeDailyLoss ? dailyLoss : null,
      includePerformanceStatus ? performanceStatus : null,
      {
        name: 'Safety Index',
        tooltip: {
          link: 'https://help.twaice.cloud/en/articles/211666-twaice-safety-index',
          linkText: 'Learn more about the Safety Index',
          content:
            'The index reflects the current safety status of the storage, ranging from ‘Stable’, to ‘Action Recommended’ to ‘Action Required’. It is calculated based on the number of anomalous measurements for temperature and resistance. Use this index to prioritize maintenance and reduce risks.',
        },
        class: 'justify-center',
      },
      {
        name: 'State of Health Spread',
        tooltip: {
          content:
            'This metrics reflects the range between the healthiest and least healthy strings in the storage. Use this insight to address strings with lower health to ensure overall system stability.',
        },
        class: 'justify-center',
      },
      isRTESummaryEnabled ? rteSummary : null,
    ].filter(Boolean);
  }

  private formatSystemDetailsData(systemDetailsList: SystemDetails[]): SystemOverviewAntTableInterface[] {
    return systemDetailsList.map((systemDetails) => ({
      customerBk: systemDetails.customerBk,
      systemBk: systemDetails.systemBk,
      id: systemDetails.id,
      systemName: systemDetails.systemName,
      metadata: systemDetails.metadata,
      safetyScore: this.systemPortfolioOverviewTableService.getSafetyScoreStatus(systemDetails.safetySummary),
      performance: !this.isSenecDemo
        ? this.systemPortfolioOverviewTableService.getPerformanceStatus(systemDetails.performanceSummary?.imbalanceSummary)
        : null,
      health: this.systemPortfolioOverviewTableService.getHealthStatus(systemDetails.healthSummary),
      rte: this.isRTESummaryEnabled
        ? this.systemPortfolioOverviewTableService.getRteStatus(systemDetails.performanceSummary?.rteSummary)
        : null,
      dailyEnergyLossMWh: systemDetails.performanceSummary?.dailyEnergyLossWh / 1000000,
      dailyFinancialLoss: systemDetails.performanceSummary?.dailyFinancialLoss,
      newChatMessagesAvailable: systemDetails.newChatMessagesAvailable,
    }));
  }
}
