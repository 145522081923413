import {
  StorageLevel,
  ImbalanceState,
  SafetyState,
  SpreadState,
  FinancialValue,
  SystemMetadata,
  RoundTripEfficiencyRating,
} from '@twaice-fe/shared/models';
import { severityCritical, severityMedium, severityNormal } from '@twaice-fe/frontend/shared/utilities';
import { NzTableSortFn, NzTableSortOrder } from 'ng-zorro-antd/table';

export interface StatsValuesConfigInterface {
  label: string | null;
  title?: string;
  color: string;
  value?: number;
  icon?: string;
  link?: string;
}

export interface ColumnItem<T> {
  name: string;
  tooltip?: {
    content: string;
    link?: string;
    linkText?: string;
  };
  class?: string;
  width?: string;
  sortOrder?: NzTableSortOrder | null;
  sortFn?: NzTableSortFn<T> | null;
  sortDirections?: NzTableSortOrder[];
}

export interface SystemOverviewAntTableInterface {
  customerBk: string;
  systemBk: string;
  id: string;
  systemName?: string;
  metadata?: SystemMetadata;
  performance?: StatsValuesConfigInterface;
  health?: StatsValuesConfigInterface;
  safetyScore?: StatsValuesConfigInterface;
  rte?: StatsValuesConfigInterface;
  dailyEnergyLossMWh?: number | null;
  dailyFinancialLoss?: FinancialValue | null;
  newChatMessagesAvailable: boolean;
}

export const STATUS_CONFIGS: Record<string, Record<string, StatsValuesConfigInterface>> = {
  safety: {
    [SafetyState.ACTION_REQUIRED]: { label: 'Action Required', color: severityCritical },
    [SafetyState.ACTION_RECOMMENDED]: { label: 'Action Recommended', color: severityMedium },
    [SafetyState.STABLE]: { label: 'Stable', color: severityNormal },
  },
  performance: {
    [ImbalanceState.HIGH]: {
      title: 'Critical',
      label: `of ${StorageLevel.Inverter}s with imbalance issues`,
      color: severityCritical,
    },
    [ImbalanceState.MEDIUM]: {
      title: 'Medium',
      label: `of ${StorageLevel.Inverter}s with imbalance issues`,
      color: severityMedium,
    },
    [ImbalanceState.BALANCED]: { title: 'Balanced', label: 'Normal', color: severityNormal },
  },
  health: {
    [SpreadState.HIGH]: { label: null, color: severityCritical },
    [SpreadState.MEDIUM]: { label: null, color: severityMedium },
    [SpreadState.STABLE]: { label: 'Normal', color: severityNormal },
  },
  rte: {
    [RoundTripEfficiencyRating.LOW]: { label: null, color: severityCritical },
    [RoundTripEfficiencyRating.MEDIUM]: { label: null, color: severityMedium },
    [RoundTripEfficiencyRating.HIGH]: { label: 'Normal', color: severityNormal },
  },
};

export const onBoardingInProgress: StatsValuesConfigInterface = {
  label: 'Onboarding in Progress',
  color: '#B4B7BE',
  icon: 'settings',
};
