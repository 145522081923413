<div nz-form [formGroup]="newPasswordForm">
  <nz-form-item>
    <nz-form-control [nzErrorTip]="tempPasswordErrorTpl">
      <nz-input-group [nzAddOnBefore]="tempPasswordIconTpl">
        <input
          nz-input
          nzSize="large"
          aria-label="Password"
          data-test="new-password"
          formControlName="password"
          placeholder="New password"
          type="password"
        />
        <ng-template #tempPasswordErrorTpl let-control>
          <div class="text-left" *ngIf="control.hasError('required')">Temporary password is required!</div>
          <div class="text-left" *ngIf="control.hasError('minlength')">Minimal length is 10 characters!</div>
          <div class="text-left" *ngIf="!control.hasError('minlength') && control.hasError('pattern')">
            Numbers, special characters, upper and lower case required!
          </div>
        </ng-template>
        <ng-template #tempPasswordIconTpl>
          <twaice-fe-svg-icon name="key" size="13px" source="tabler" />
        </ng-template>
      </nz-input-group>
    </nz-form-control>
  </nz-form-item>

  <nz-form-item>
    <nz-form-control [nzErrorTip]="tempPasswordErrorTpl">
      <nz-input-group [nzAddOnBefore]="tempPasswordIconTpl">
        <input
          nz-input
          nzSize="large"
          aria-label="Repeat password"
          data-test="repeat-new-password"
          formControlName="confirmPassword"
          matInput
          placeholder="Repeat new password"
          type="password"
        />
        <ng-template #tempPasswordErrorTpl let-control>
          <div class="text-left" *ngIf="control.hasError('required')">It is required to repeat the new password!</div>
          <div class="text-left" *ngIf="control.hasError('passwordsNotTheSame')" data-test="passwords-not-same-error">
            Passwords are not the same!
          </div>
        </ng-template>
        <ng-template #tempPasswordIconTpl>
          <twaice-fe-svg-icon name="key" size="13px" source="tabler" />
        </ng-template>
      </nz-input-group>
    </nz-form-control>
  </nz-form-item>
</div>
