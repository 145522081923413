/* eslint-disable @angular-eslint/component-selector */
import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChange,
  SimpleChanges,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable, Subject, debounceTime, distinctUntilChanged, takeUntil } from 'rxjs';
import { CellConfigInterface } from '../models/cell-config.interface';
import { CellTypeEnum } from '../models/cell-type.enum';

@Component({
  selector: '[cell]',
  templateUrl: './cell.component.html',
  standalone: false,
})
export class CellComponent implements OnChanges, OnInit, OnDestroy {
  @Input() content: unknown;
  @Input() columns: Observable<string[]>;
  @Input() enableBulkSelection = false;
  @Input() bulkSelected = false;
  @Input() selectedCheckBox = false;
  @Input() dense: boolean;
  @Input() reachedMaxSelectLimit = false;

  @Output() selected = new EventEmitter<CellConfigInterface>();
  @Output() selectedItems = new EventEmitter<{ state: boolean }>();
  @Output() actionClicked = new EventEmitter<number>();

  selectionCheckbox: FormControl = new FormControl();
  // To avoid dispatching any events in case of first cell draw
  isFirstRender: boolean;

  cellTypeEnum = CellTypeEnum;

  protected destroy$: Subject<boolean> = new Subject<boolean>();

  constructor() {
    this.isFirstRender = true;
  }

  @HostBinding('class.dense') get isDense() {
    return this.dense;
  }

  ngOnInit(): void {
    if (this.reachedMaxSelectLimit) {
      if (!this.selectedCheckBox && !this.selectionCheckbox.value && this.bulkSelected) {
        this.selectionCheckbox.setValue(this.bulkSelected);
      }

      if (!this.selectedCheckBox && !this.selectionCheckbox.value && !this.bulkSelected) {
        this.selectionCheckbox.disable();
      }
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    // Extract changes
    const enableBulkSelectionChange = changes?.enableBulkSelection;
    const bulkSelectedChange = changes?.bulkSelected;

    // Determine initial bulk selection state
    const isBulkSelectedInitially = this.isInitialBulkSelected(bulkSelectedChange);

    // Handle checkbox state based on selection limits
    this.handleSelectionLimit();

    // Initialize checkbox change listener if enableBulkSelection changed for the first time
    if (enableBulkSelectionChange?.isFirstChange()) {
      this.cellCheckboxValueChange();
    }

    // Handle bulk selection changes
    this.handleBulkSelectionChange(bulkSelectedChange, isBulkSelectedInitially);
  }

  /**
   * Check if bulkSelected was initially true but now false
   */
  isInitialBulkSelected(bulkSelectedChange: SimpleChange | undefined): boolean {
    return (
      bulkSelectedChange &&
      !bulkSelectedChange.isFirstChange() &&
      !bulkSelectedChange.currentValue &&
      bulkSelectedChange.previousValue
    );
  }

  /**
   * Handle enabling or disabling of the selection checkbox based on max select limit
   */
  handleSelectionLimit(): void {
    if (this.reachedMaxSelectLimit) {
      if (this.selectionCheckbox.enabled && !this.selectionCheckbox.value && !this.bulkSelected) {
        this.selectionCheckbox.disable();
      }
    } else if (this.selectionCheckbox.disabled) {
      this.selectionCheckbox.enable();
    }
  }

  /**
   * Handle changes in bulkSelected and update checkbox state accordingly
   */
  handleBulkSelectionChange(bulkSelectedChange: SimpleChange | undefined, isBulkSelectedInitially: boolean): void {
    if (bulkSelectedChange && !this.reachedMaxSelectLimit) {
      this.selectionCheckbox.setValue(bulkSelectedChange.currentValue);
    }

    if (this.reachedMaxSelectLimit && isBulkSelectedInitially) {
      this.selectionCheckbox.enable();
      this.selectionCheckbox.setValue(false);
    }
  }

  handleRowClick(row: CellConfigInterface) {
    if (this.enableBulkSelection && (!this.reachedMaxSelectLimit || this.selectionCheckbox.value)) {
      this.selectionCheckbox.setValue(!this.selectionCheckbox.getRawValue());
      return;
    }

    this.selected.emit(row);
  }

  onEvent(event) {
    event.stopPropagation();
  }

  action(id) {
    this.actionClicked.emit(id);
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  private cellCheckboxValueChange() {
    this.selectionCheckbox.valueChanges
      .pipe(debounceTime(150), distinctUntilChanged(), takeUntil(this.destroy$))
      .subscribe((value) => {
        // selected items will contain here only the state but the datatable will emit to the calling component the state and the item
        this.selectedItems.emit({ state: value });
        return;
      });
  }
}
