import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectors } from '@twaice-fe/frontend/shared/store';
import { filter } from 'rxjs';

const { systemSelectors } = selectors;

export enum UnitConversion {
  PercentageStorageEnergy = 'PercentageStorageEnergy',
  TemperatureCelsiusFahrenheit = 'TemperatureCelsiusFahrenheit',
}

@Injectable({
  providedIn: 'root',
})
export class UnitConversionService {
  designEnergyCapacityMwh: number | null = null;

  constructor(private store: Store) {
    this.store
      .select(systemSelectors.getSelected)
      .pipe(filter((system) => !!system))
      .subscribe(
        (system) =>
          (this.designEnergyCapacityMwh = system.metadata?.designEnergyCapacityKwh
            ? system.metadata?.designEnergyCapacityKwh / 1_000
            : null)
      );
  }

  getAvailableConversions() {
    if (this.designEnergyCapacityMwh) {
      return [UnitConversion.PercentageStorageEnergy, UnitConversion.TemperatureCelsiusFahrenheit];
    } else {
      return [UnitConversion.TemperatureCelsiusFahrenheit];
    }
  }

  convertValueToUnit(value: number, from: string, to: string): number {
    if (from === to) return value;
    if (from === '%' && to === 'MWh') return this.convertPercentageToMegaWattHours(value);
    if (from === 'MWh' && to === '%') return this.convertMegaWattHoursToPercentage(value);
    if (from === '°C' && to === '°F') return this.convertCelsiusToFahrenheit(value);
    if (from === '°F' && to === '°C') return this.convertFahrenheitToCelsius(value);
    return value;
  }

  convertValueUsingTransformationFunction(value: number, transformationFunc: (value: number) => number): number {
    return transformationFunc(value);
  }

  private convertCelsiusToFahrenheit(temperatureInCelsius: number): number {
    return (temperatureInCelsius * 9) / 5 + 32;
  }

  private convertFahrenheitToCelsius(temperatureInFahrenheit: number) {
    return ((temperatureInFahrenheit - 32) * 5) / 9;
  }

  private convertPercentageToMegaWattHours(pctValue: number): number {
    return (pctValue / 100) * this.designEnergyCapacityMwh;
  }

  private convertMegaWattHoursToPercentage(megaWattHoursValue: number) {
    return (megaWattHoursValue / this.designEnergyCapacityMwh) * 100;
  }
}
