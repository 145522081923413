export enum CellTypeEnum {
  TEXT = 'text',
  NUMBER = 'number',
  DATE = 'date',
  BUTTON = 'button',
  ICON_BUTTON = 'icon_button',
  ICON_LINK = 'icon_link',
  ICON = 'ICON',
  IMAGE = 'IMAGE',
  STATUS = 'STATUS',
  TAGS = 'TAGS',
  TOOLTIP = 'TOOLTIP',
  ACTIONS_BUTTON = 'ACTIONS_BUTTON',
}
