import { Component, Input } from '@angular/core';
import { IconSource, MaterialIconType, SvgNameType, TablerIconType } from './svg-icon.types';
@Component({
  selector: 'twaice-fe-svg-icon',
  templateUrl: './svg-icon.component.html',
  styleUrls: ['./svg-icon.component.scss'],
  standalone: false,
})
export class SvgComponent {
  @Input() source: IconSource = 'custom';
  @Input() size?: string;
  @Input() width?: string;
  @Input() height?: string;
  @Input() classList: string;
  @Input() stroke: string;
  @Input() fill: string;
  @Input() color: string;
  @Input() name: SvgNameType | MaterialIconType | TablerIconType;
}
