@use '@angular/material' as mat;
@use 'twaice-theme';

.cell-prefix {
  margin-right: 32px;
}

.cell-value {
  &.blue-500 {
    color: mat.get-color-from-palette(twaice-theme.$twaice-blue, 500);
  }

  &.red-500 {
    color: mat.get-color-from-palette(twaice-theme.$twaice-red, 500);
  }

  &.green-500 {
    color: twaice-theme.$green-500;
  }

  &.black-semi-transparent {
    color: rgba(4, 15, 38, 0.5);
  }
}

.mat-sort-header-content {
  text-align: left !important;
}

.text-header {
  white-space: pre-line;
}

.selected-row {
  background-color: twaice-theme.$blue-gray-200;
}

.table-checkbox {
  padding-right: 24px;

  &.mat-checkbox-checked,
  &.mat-checkbox-indeterminate {
    .mat-checkbox-background {
      background-color: twaice-theme.$navy-blue;
    }
  }

  .mat-checkbox-frame {
    border-color: twaice-theme.$navy-blue;
  }

  .mat-checkbox-disabled {
    .mat-checkbox-background {
      background-color: mat.get-color-from-palette(twaice-theme.$primary, 500);
    }

    .mat-checkbox-frame {
      border-color: mat.get-color-from-palette(twaice-theme.$primary, 500);
    }
  }
}
