import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'twaice-fe-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  standalone: false,
})
export class ButtonComponent {
  @Input() disabled: boolean = false;
  @Input() variant: 'primary' | 'secondary' | 'tertiary' = 'primary';
  @Input() size: 'small' | 'medium' | 'large' = 'medium';
  @Output() onClick: EventEmitter<Event> = new EventEmitter();

  onClickButton(event: Event) {
    this.onClick.emit(event);
  }
}
