import { inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { actions, selectors } from '@twaice-fe/frontend/shared/store';
import * as routes from '@twaice-fe/shared/constants';
import { FeatureFlagsEnum, Solution } from '@twaice-fe/shared/models';
import { isEqual } from '@twaice-fe/shared/utilities';
import { distinctUntilChanged, filter, map, of, switchMap, tap } from 'rxjs';

const { configsSelectors, systemSelectors } = selectors;
const { systemActions } = actions;
/**
 * Guard for protecting routes that the user does not have access to
 */
export const hasSolution =
  (solution: Solution): CanActivateFn =>
  (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    const [store, router] = [inject(Store), inject(Router)];

    return store.select(configsSelectors.getAvailableSolutionList).pipe(
      takeUntilDestroyed(),
      map((solutions) => solutions.some((s) => s === solution)),
      // check if we have an empty page for the solution so we can navigate there instead of 404ing
      map((canNavigate) => {
        // avoid user "cheating" out of empty page into solution sub pages
        if (state.url.includes('/empty') && router.url.replace('/empty', '') === state.url.replace('/empty', '')) return false;

        // if user has solution -> just continue
        if (canNavigate || state.url.includes('/empty')) return true;

        if (state.url.includes(routes.ENERGY_INCIDENTS_ROUTE)) {
          return router.parseUrl(`${routes.ENERGY_INCIDENTS_ROUTE}/empty`);
        }

        if (state.url.includes(routes.ENERGY_PREDICTION_ROUTE)) {
          return router.parseUrl(`${routes.ENERGY_PREDICTION_ROUTE}/empty`);
        }

        if (state.url.includes(routes.MODEL_LIBRARY_BASE_ROUTE)) {
          return router.parseUrl(`${routes.MODEL_LIBRARY_BASE_ROUTE}/empty`);
        }

        if (state.url.includes(routes.ENERGY_PERFORMANCE_MANAGER_ROUTE)) {
          return router.parseUrl(`${routes.ENERGY_PERFORMANCE_MANAGER_ROUTE}/empty`);
        }

        return false;
      }),
      map((canNavigate) => canNavigate || router.parseUrl('/'))
    );
  };

/**
 * Guard for navigating the user to their respective landing page, given their customer type
 */
export const redirectToSolutionLanding: CanActivateFn = () => {
  const [router, store] = [inject(Router), inject(Store)];

  return store.select(configsSelectors.getBaseUrl).pipe(
    takeUntilDestroyed(),
    map((baseUrl) => (baseUrl ? router.parseUrl(baseUrl) : router.parseUrl('/missing-configuration')))
  );
};

export const redirectToSystem: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const [router, store] = [inject(Router), inject(Store)];

  const skipRedirect = route.params['systemBk'] || state.url.startsWith('/customer/');
  if (skipRedirect) return of(true);

  // if the route still contains a legacy system ID query param we use it and set that system as active
  const legacySystemId = route.queryParams['systemID'];

  return store.select(systemSelectors.getSelected).pipe(
    switchMap((selectedSystem) => {
      if (selectedSystem) return of(selectedSystem);

      // default to initial system if no systemBk in route
      return store.select(systemSelectors.getSystemDetailsList).pipe(
        filter((systemList) => !!systemList && systemList.length !== 0),
        tap((systemList) => store.dispatch(systemActions.selectSystem({ systemId: legacySystemId || systemList[0].id }))),
        map((systemList) => systemList.find((s) => s.id === legacySystemId) || systemList[0])
      );
    }),
    map((selectedSystem) =>
      router.parseUrl(`/customer/${selectedSystem.customerBk}/system/${selectedSystem.systemBk}${state.url}`)
    )
  );
};

export const hasFeatureFlag =
  ({ featureFlag, redirectPath }: { featureFlag: FeatureFlagsEnum; redirectPath?: string }): CanActivateFn =>
  () => {
    const [router, store] = [inject(Router), inject(Store)];

    return store.select(configsSelectors.getConfigFeatureFlagList).pipe(
      distinctUntilChanged(isEqual),
      map((featureFlags) => {
        const listFeatureFlags = featureFlags as FeatureFlagsEnum[];
        const isAvailable = listFeatureFlags.includes(featureFlag);
        if (!isAvailable) {
          if (!redirectPath) return false;
          return router.parseUrl(redirectPath || '/404');
        }
        return true;
      })
    );
  };

export const hasFeatureFlagDisabled =
  ({ featureFlag }: { featureFlag: FeatureFlagsEnum }): CanActivateFn =>
  () => {
    const store = inject(Store);

    return store.select(configsSelectors.getConfigFeatureFlagList).pipe(
      distinctUntilChanged(isEqual),
      map((featureFlags: FeatureFlagsEnum) => !featureFlags.includes(featureFlag))
    );
  };
