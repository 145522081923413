import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SYSTEMS_FEATURE_KEY, State, systemDetailsAdapter } from '../reducers/systems.reducer';

// Lookup the 'Systems' feature state managed by NgRx
export const getSystemsState = createFeatureSelector<State>(SYSTEMS_FEATURE_KEY);

const systemDetailsSelectors = systemDetailsAdapter.getSelectors();

export const getSystemDetailsList = createSelector(getSystemsState, (state) =>
  systemDetailsSelectors.selectAll(state.systemDetails)
);

export const getSystemDetailsEntities = createSelector(getSystemsState, (state) =>
  systemDetailsSelectors.selectEntities(state.systemDetails)
);

export const getSystemById = (id: string) =>
  createSelector(getSystemDetailsList, (systemDetailsList) => systemDetailsList.find((system) => system.id === id));

export const getSelectedId = createSelector(getSystemsState, (state: State) => state.selectedId);

export const getSelected = createSelector(getSystemDetailsEntities, getSelectedId, (entities, selectedId) =>
  selectedId ? entities[selectedId] : undefined
);

export const getDefaultTimeRange = createSelector(getSystemsState, (state) => state.defaultTimeRange);

export const getSelectedTimeRange = createSelector(getSystemsState, (state) => ({
  timeRange: state.timeRange,
  availableTimeRanges: state.availableTimeRanges,
  timeRangeGranularity: state.timeRangeGranularity,
  timeResolution: state.timeResolution,
}));
