import { animate, style, transition, trigger } from '@angular/animations';
import { CommonModule } from '@angular/common';
import { Component, DestroyRef, Input, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NzTypographyModule } from 'ng-zorro-antd/typography';
import { interval } from 'rxjs';
import { LoadingTipsService } from './loading-tips.service';

@Component({
  selector: 'twaice-fe-loading-tips',
  imports: [CommonModule, NzTypographyModule],
  providers: [LoadingTipsService],
  templateUrl: './loading-tips.component.html',
  animations: [
    trigger('transitionTip', [
      transition(':enter', [style({ opacity: 0 }), animate('0.4s ease-in', style({ opacity: 0.6 }))]),
      transition(':leave', [style({ opacity: 0.6 }), animate('0.4s ease-in', style({ opacity: 0 }))]),
    ]),
  ],
})
export class LoadingTipsComponent implements OnInit {
  @Input() delay = 5000;
  @Input() tipSet: 'ENERGY_HEALTH' | 'MOBILITY_SINGLE_ALERT';
  @Input() overlay = false;

  show = false;
  tips: string[];
  tipIndex = -1;

  constructor(
    private destroy$: DestroyRef,
    private tipsService: LoadingTipsService
  ) {}

  ngOnInit(): void {
    interval(this.delay)
      .pipe(takeUntilDestroyed(this.destroy$))
      .subscribe(() => {
        this.show = true;
        this.tipIndex = (this.tipIndex + 1) % this.tips.length;
      });

    this.tips = this.tipsService.getTips(this.tipSet);
  }
}
