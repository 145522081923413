@use '@angular/material' as mat;
@use 'twaice-theme';

.stacked-bar-chart-wrapper {
  height: 100%;
  position: relative;

  .stacked-bar-chart {
    opacity: 0;
    height: 100%;

    &.loaded {
      transition: opacity 0.5s ease-in;
      opacity: 1;
    }

    .x-axis,
    .y-axis {
      line,
      path {
        stroke: mat.get-color-from-palette(twaice-theme.$primary, 500);
      }

      &.hide-domain {
        line,
        path {
          stroke: none;
        }
      }

      text {
        font-family: twaice-theme.$font-family-proxima-nova;
        font-size: twaice-theme.$font-size-sm;
        line-height: twaice-theme.$font-size-base;
        color: rgba(0, 0, 0, 0.45);
      }
    }
    .x-axis text {
      white-space: pre-wrap !important;
    }
    .x-axis .tick {
      line {
        display: none;
      }
    }

    .y-axis .tick {
      text {
        display: none;
      }
    }

    .x-axis-label,
    .y-axis-label {
      position: absolute;
      font-family: twaice-theme.$font-family-proxima-nova;
      font-size: twaice-theme.$font-size-sm;
      line-height: twaice-theme.$font-size-sm;
      color: mat.get-color-from-palette(twaice-theme.$primary, 600);
      white-space: nowrap;
      text-align: center;
    }

    .y-axis-label {
      writing-mode: vertical-lr;
      top: 50%;
      transform: translate(0, -65%) rotate(180deg);
    }

    .grid {
      line {
        stroke: mat.get-color-from-palette(twaice-theme.$twaice-gray, 100);
      }

      text,
      path {
        display: none;
      }
    }

    .stacked-bars {
      &.enable-selection {
        cursor: pointer;
      }

      &.disable-selection {
        cursor: not-allowed;
      }

      &.enable-hover {
        cursor: pointer;
      }

      rect {
        stroke-linecap: round;
        stroke-linejoin: round;
      }
    }

    .interaction-bar.selected {
      fill: rgba(4, 15, 38, 0.04);
      stroke: twaice-theme.$blue-500;
      rect {
        opacity: 1;
      }
    }

    .bar-group {
      &.hover {
        .interaction-bar {
          fill: rgba(4, 15, 38, 0.04);
        }

        rect {
          opacity: 1;
        }
      }

      &.not-hover {
        rect {
          opacity: 0.4;
        }
      }
    }
  }

  twaice-fe-alert-panel {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    text-align: center;
    white-space: pre-line;
    justify-content: center;
    font-weight: twaice-theme.$font-weight-semibold;
    font-size: twaice-theme.$font-size-base;
    line-height: twaice-theme.$font-size-base;
  }
}

#tooltip {
  display: none;
}
