import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'MWhFormatter',
  pure: false,
  standalone: true,
})
export class MWhFormatterPipe implements PipeTransform {
  transform(value: number | undefined | null, placeholder?: string): string {
    if (value === null || value === undefined) {
      return placeholder ?? '-';
    }

    if (value === 0) {
      return '0 MWh';
    }

    if (Math.abs(value) < 1) {
      return `${Math.round(value * 1000)} kWh`;
    }

    // Avoids rounding issues with many decimal points
    return `${Math.round((value + Number.EPSILON) * 100) / 100} MWh`;
  }
}
