import { Pipe, PipeTransform } from '@angular/core';
import { CustomerNameTranslationService } from './customer-name-translation.service';
import { StorageLevel } from '@twaice-fe/shared/models';

@Pipe({
  name: 'levelIdentifierToLevelName',
  pure: false,
  standalone: true,
})
export class LevelNameTranslationPipe implements PipeTransform {
  constructor(private customerNameTranslationService: CustomerNameTranslationService) {}

  transform(value: string | undefined, selectedStorageLevels: StorageLevel[] | undefined = undefined): string | undefined {
    return this.customerNameTranslationService.replaceLevelIdentifiers(value, selectedStorageLevels);
  }
}
