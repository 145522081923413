import { CommonModule, LowerCasePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { NzAutocompleteModule } from 'ng-zorro-antd/auto-complete';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { DataDisplayModule } from '../data-display/data-display.module';
import { LoadingStripesModule } from '../loading-stripes/loading-stripes.module';
import { ProgressSpinnerModule } from '../progress-spinner/progress-spinner.module';
import { SvgIconModule } from '../svg-icon/svg-icon.module';
import { SystemOverviewTableModule } from '../system-overview-table/system-overview-table.module';
import { TimeSelectorModule } from '../time-selector/time-selector.module';
import { NavigationMenuComponent } from './navigation-menu.component';
import { SideNavService } from './side-nav.service';
import { StorageMetadataDialogComponent } from './storage-metadata-dialog/storage-metadata-dialog.component';
import { NzTooltipDirective } from 'ng-zorro-antd/tooltip';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { LevelNameTranslationPipe, StorageLevelToCustomerLevelNamePipe } from '@twaice-fe/frontend/shared/services';
import { FeatureToggleModule } from 'ngx-feature-toggle';

const ANGULAR_MATERIAL_MODULES = [MatMenuModule, MatSidenavModule, MatDialogModule, MatDividerModule];

const NG_ZORRO_MODULES = [NzDropDownModule, NzAutocompleteModule, NzInputModule, NzSpinModule, NzTooltipDirective];

const INTERNAL_MODULES = [
  SvgIconModule,
  DataDisplayModule,
  LoadingStripesModule,
  ProgressSpinnerModule,
  SystemOverviewTableModule,
  TimeSelectorModule,
  FormsModule,
];

const DECLARATIONS = [NavigationMenuComponent, StorageMetadataDialogComponent];

@NgModule({
  declarations: [...DECLARATIONS],
  imports: [
    CommonModule,
    FeatureToggleModule,
    ...INTERNAL_MODULES,
    ...ANGULAR_MATERIAL_MODULES,
    ...NG_ZORRO_MODULES,
    LevelNameTranslationPipe,
    StorageLevelToCustomerLevelNamePipe,
    LowerCasePipe,
  ],
  exports: [...DECLARATIONS],
  providers: [SideNavService],
})
export class NavigationMenuModule {}
