import * as selectors from '../selectors';
import * as actions from '../actions';
import { Injectable } from '@angular/core';
import { createEffect } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { retry, mergeMap, map, catchError, of, distinctUntilChanged, filter, withLatestFrom } from 'rxjs';
import { EnergyAnalyticsService } from '@twaice-fe/frontend/shared/services';

const { configsAction } = actions;
const { systemSelectors, configsSelectors } = selectors;

@Injectable()
export class ConfigEffects {
  fetchStorageNameMappings$ = createEffect(() =>
    this.store.pipe(
      select(systemSelectors.getSelected),
      distinctUntilChanged(
        (previous, current) => previous?.customerBk === current?.customerBk && previous?.systemBk === current?.systemBk
      ),
      filter((system) => !!system && !!system.customerBk && !!system.systemBk),
      withLatestFrom(this.store.select(configsSelectors.getStorageNameMappings)),
      filter(([system, storageMappings]) => storageMappings[`${system?.customerBk}/${system?.systemBk}`] === undefined),
      mergeMap(
        ([system]) =>
          /* eslint-disable @typescript-eslint/no-non-null-assertion */
          this.energyAnalyticsService
            .fetchStorageNameMappings({ customerBk: system!.customerBk!, systemBk: system!.systemBk! })
            .pipe(
              retry(3),
              map((mappings) =>
                configsAction.loadNameMappingSuccess({
                  customerBk: system!.customerBk!,
                  systemBk: system!.systemBk!,
                  componentNameMapping: mappings.componentNameMapping,
                  levelNameMapping: mappings.levelNameMapping,
                })
              ),
              catchError((error) => {
                console.error('[Error/Fetch storage name mappings]', error);
                return of(configsAction.loadNameMappingFailure({ error }));
              })
            )
        /* eslint-enable @typescript-eslint/no-non-null-assertion */
      )
    )
  );

  constructor(
    private energyAnalyticsService: EnergyAnalyticsService,
    protected store: Store
  ) {}
}
