import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, Output, QueryList, TemplateRef, ViewChildren } from '@angular/core';

@Component({
  selector: 'twaice-fe-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  animations: [
    trigger('transformMenu', [
      state(
        'void',
        style({
          opacity: 0,
          transform: 'scale(0.8)',
        })
      ),
      transition(
        'void => enter',
        animate(
          '60ms cubic-bezier(0, 0, 0.2, 1)',
          style({
            opacity: 1,
            transform: 'scale(1)',
          })
        )
      ),
    ]),
  ],
  exportAs: 'twMenu',
  standalone: false,
})
export class MenuComponent {
  @Input() closeOnSelect = true;

  @ViewChildren('menuTemplate') templateRef!: QueryList<TemplateRef<unknown>>;

  @Output()
  click = new EventEmitter();

  @Output()
  close = new EventEmitter();

  animationState: 'void' | 'enter' = 'void';

  handleClick() {
    this.click.emit();
  }

  getTemplateRef() {
    return this.templateRef.first;
  }

  playAnimation() {
    this.animationState = 'enter';
  }
}
