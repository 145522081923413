export interface PerformanceManagerResponseInterface<T> {
  status: string;
  data: T;
}

export enum BalancingPageKpis {
  SOC_SPREAD = 'soc_spread',
  VOLTAGE_SPREAD = 'voltage_spread',
}

export enum TemperatureKpis {
  TEMPERATURE_SPREAD = 'temperature_spread',
  TEMPERATURE_MEAN = 'temperature_mean',
  TEMPERATURE_MAX = 'temperature_max',
}

export enum PerformanceManagerKPI {
  SOC_SPREAD = 'soc_spread',
  VOLTAGE_SPREAD = 'voltage_spread',
  TEMPERATURE_SPREAD = 'temperature_spread',
  TEMPERATURE_MEAN = 'temperature_mean',
  TEMPERATURE_MAX = 'temperature_max',
}

export enum BalancingSeverityEnum {
  CRITICAL = 'high',
  ELEVATED = 'medium',
  NORMAL = 'balanced',
  MISSING = 'missing',
}

export interface SeverityLevelInterface extends BasePerformanceMangerResponse {
  severityLevel: string;
  lowerBoundary: number;
  upperBoundary: number;
}

export interface SystemStatisticsData {
  x: number;
  y: {
    [key: string]: number;
  };
  kpi?: PerformanceManagerKPI;
}

export interface BasePerformanceMangerResponse {
  customerBk?: string;
  systemBk?: string;
  levelBk?: string;
  inverterBk?: string;
  kpi?: PerformanceManagerKPI;
}

export interface SystemStatisticsInterface extends BasePerformanceMangerResponse {
  data: SystemStatisticsData[];
}

export interface BasePerformanceMangerParams {
  customerBk?: string;
  systemBk?: string;
  levelBk?: string;
  kpi?: PerformanceManagerKPI | BalancingPageKpis | TemperatureKpis;
}

export interface SystemStatisticsParams extends BasePerformanceMangerParams {
  startDate: number;
  endDate: number;
}

export interface SystemKPIParams extends BasePerformanceMangerParams {
  date?: number;
}

export interface SystemKPIData {
  componentBk: string;
  inverterName: string;
  eventDate: number;
  severityLevel: string;
  value?: number;
}

export interface SystemKPIInterface extends BasePerformanceMangerResponse {
  data: SystemKPIData[];
}

export enum InsightSeverity {
  Critical = 'critical',
  Elevated = 'elevated',
  High = 'high',
  Medium = 'medium',
}

export interface SystemInsightsDataInterface {
  id: string;
  severity: InsightSeverity;
  mostCritical: string;
  spread: number;
}

export interface SystemInsightsInterface extends BasePerformanceMangerResponse {
  data: SystemInsightsDataInterface[];
}

export interface SystemInsightsState extends SystemInsightsInterface {
  kpi: PerformanceManagerKPI;
  levelBk: string;
  isLoading: boolean;
  error?: string | null;
}

export interface SystemInsightsParams extends BasePerformanceMangerParams {
  date: number;
}

export interface SystemSeverityLevelResultInterface extends BasePerformanceMangerResponse {
  data: { [key: string]: SeverityLevelInterface[] };
}

export interface StringKPIParams extends BasePerformanceMangerParams {
  startDate: number;
  endDate: number;
  inverterBk: string;
}

export interface StringKPIData {
  componentBk: string;
  eventDate: number;
  severityLevel: string;
  value?: number;
  inverterName?: string;
}

export interface StringKPIInterface extends BasePerformanceMangerResponse {
  data: StringKPIData[];
}
