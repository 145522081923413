import { DatatableDateFilterComponent } from './datatable-date-filter/datatable-date-filter.component';
import { DatatableNumberFilterComponent } from './datatable-number-filter/datatable-number-filter.component';
import { DatatableOptionsFilterComponent } from './datatable-options-filter/datatable-options-filter.component';
import { DatatableSelectFilterComponent } from './datatable-select-filter/datatable-select-filter.component';
import { DatatableTextFilterComponent } from './datatable-text-filter/datatable-text-filter.component';

export const DATATABLE_FILTER_COMPONENTS = [
  DatatableOptionsFilterComponent,
  DatatableTextFilterComponent,
  DatatableDateFilterComponent,
  DatatableNumberFilterComponent,
  DatatableSelectFilterComponent,
];
