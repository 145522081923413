import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { IncidentsDetectionService } from '@twaice-fe/frontend/shared/services';
import { RequestQueryBuilder } from '@twaice-fe/frontend/shared/utilities';
import { catchError, map, of, switchMap, takeUntil, withLatestFrom } from 'rxjs';
import * as IncidentRulesActions from '../actions/incidents-rules.actions';
import * as IncidentRulesSelectors from '../selectors/incidents-rules.selectors';

@Injectable()
export class IncidentRulesEffects {
  init$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        ...[
          IncidentRulesActions.fetchIncidentRules,
          IncidentRulesActions.filterIncidentRules,
          IncidentRulesActions.updateIncidentListConfiguration,
          IncidentRulesActions.sortIncidents,
          IncidentRulesActions.resetIncidentRulesFilters,
        ]
      ),
      withLatestFrom(this.store.select(IncidentRulesSelectors.getIncidentThresholdState)),
      switchMap(([, state]) => {
        const { page, limit, order, filter } = state?.config ?? { page: 1, limit: 20 };

        // eslint-disable-next-line @typescript-eslint/naming-convention
        const systemBk = this.incidentsDetectionService.getSelectSystem().getValue()?.systemBk;
        const params = new RequestQueryBuilder().build({ systemBk, page, limit, order, ...filter });
        return this.incidentsDetectionService.fetchIncidentThresholdRules(params).pipe(
          map(({ data }) => IncidentRulesActions.loadIncidentRulesSuccess({ incidentsRules: data })),
          takeUntil(this.actions$.pipe(ofType(IncidentRulesActions.cancelIncidentRulesRequest))),
          catchError((error) => {
            console.error('[Error/Fetch Incidents]', error);
            return of(IncidentRulesActions.loadIncidentRulesFailure({ error }));
          })
        );
      })
    )
  );

  fetchIncidentsDelays$ = createEffect(() =>
    this.actions$.pipe(
      ofType(...[IncidentRulesActions.fetchIncidentDelays]),
      withLatestFrom(this.store.select(IncidentRulesSelectors.getIncidentRulesDelaysState)),
      switchMap(([action, state]) => {
        const { page, limit, order, filter } = state?.config ?? { page: 1, limit: 20 };

        const params = new RequestQueryBuilder().build({ page, limit, order, ...filter });
        return this.incidentsDetectionService.fetchIncidentsDelays(action.systemID, params).pipe(
          map(({ data }) => IncidentRulesActions.loadIncidentDelaysSuccess({ incidentsDelays: data })),
          catchError((error) => {
            console.error('[Error/Fetch Incidents Delays]', error);
            return of(IncidentRulesActions.loadIncidentDelayFailure({ error }));
          })
        );
      })
    )
  );

  constructor(
    private readonly actions$: Actions,
    private incidentsDetectionService: IncidentsDetectionService,
    protected store: Store
  ) {}
}
