<div class="fp-dialog">
  <div class="fp-dialog__header">
    <ng-container *ngIf="icon">
      <mat-icon [svgIcon]="icon" data-testid="dialog-icon"></mat-icon>
    </ng-container>
    <div class="fp-dialog__header-content">
      <ng-content select="[twaice-fe-dialog-header]"></ng-content>
    </div>
    <button (click)="close.emit()" tabindex="-1">
      <mat-icon inline>close</mat-icon>
    </button>
  </div>

  <div class="fp-dialog__content">
    <ng-content select="[twaice-fe-dialog-content]"></ng-content>
  </div>

  <div class="fp-dialog__actions">
    <ng-content select="[twaice-fe-dialog-actions]"></ng-content>
  </div>
</div>
