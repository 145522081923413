import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'twaice-fe-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss'],
  standalone: false,
})
export class TooltipComponent implements AfterViewInit {
  @Input() text: string | string[];

  @ViewChild('tooltip') tooltipRef: ElementRef<HTMLDivElement>;

  @Output() tooltipContentHeightEvent = new EventEmitter<number>(true);

  ngAfterViewInit() {
    this.tooltipContentHeightEvent.emit(this.tooltipRef.nativeElement.clientHeight);
  }
}
//todo refactor this file should be separate components or 1 component with nicer implementation
@Component({
  selector: 'twaice-fe-arrow-tooltip',
  template: `<div class="arrow" [class]="position"></div>`,
  styleUrls: ['./tooltip.component.scss'],
  standalone: false,
})
export class TooltipArrowComponent {
  @Input() position: 'top' | 'bottom' = 'bottom';
}
