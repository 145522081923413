import { Component, Input } from '@angular/core';

@Component({
  selector: 'twaice-fe-loading-stripe',
  templateUrl: './loading-stripes.component.html',
  styleUrls: ['./loading-stripes.component.scss'],
  standalone: false,
})
export class LoadingStripeComponent {
  @Input() theme: 'dark' | 'light' = 'light';
  @Input() size: 'sm' | 'md' | 'lg' | 'xl' = 'lg';
  @Input() type: 'list' | 'card' = 'list';
}
