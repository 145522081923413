<div class="bulk-selection justify-between">
  <div>
    <mat-checkbox [formControl]="selectAllCheckbox" [indeterminate]="checkboxIndeterminate"></mat-checkbox>
    <span> {{ selectionInfo }}</span>
  </div>

  <div class="flex items-center gap-16">
    <div *ngFor="let item of bulkActions">
      <button
        class="section-header_button"
        [class.active]="selectedItems.length > 0"
        [disabled]="!selectedItems"
        nz-button
        nzType="default"
        (click)="handleClick(item.action)"
      >
        <div class="flex items-center gap-4">
          <twaice-fe-svg-icon [name]="item.icon" [stroke]="item.strokeColor" size="20px" />
          {{ item.title }}
        </div>
      </button>
    </div>
  </div>
</div>
