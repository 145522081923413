@use 'twaice-theme';

twaice-fe-datatable-config {
  .filter {
    background-color: white;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0;
    min-height: 48px;

    .list {
      list-style: none;
      display: flex;
      align-items: center;
      margin: 0;

      li {
        min-height: 36px;
      }
    }

    .list-start {
      width: 100%;
      margin-right: twaice-theme.$spacer-l;
    }

    .list-start:has(.search-wrapper) {
      border-bottom: 1px solid twaice-theme.$gray-100;
    }

    .list-end {
      justify-content: end;
      border-bottom: none;
      width: fit-content;
      align-items: baseline;
    }

    .search-wrapper {
      .search-bar > input {
        outline: 0px;
        border: none;
        background: transparent;
        font-size: twaice-theme.$font-size-sm;
        padding: 6px;
      }
    }

    .add-button-datatable {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
    }

    .pl {
      padding-left: twaice-theme.$spacer-m;
    }

    .center {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .datatable-icon {
      width: 20px;
      height: 20px;
    }

    .w-full {
      width: 100%;
    }
  }
}
