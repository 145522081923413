@use '@angular/material' as mat;
@import 'font-faces';
@import 'base-variables';

/**
*  Material component Mixins
**/
@import 'material-mixins/mat-button';
@import 'material-mixins/mat-button-toggle';
@import 'material-mixins/mat-card';
@import 'material-mixins/mat-chip';
@import 'material-mixins/mat-divider';
@import 'material-mixins/mat-form-field';
@import 'material-mixins/mat-menu';
@import 'material-mixins/mat-select';
@import 'material-mixins/mat-table';
@import 'material-mixins/mat-toolbar';
@import 'material-mixins/mat-chip-listbox';
@import 'material-mixins/mat-checkbox';
@import 'material-mixins/mat-radio-button';
@import 'material-mixins/mat-dialog';
@import 'material-mixins/mat-tab';

$twaice-blue: (
  50: #effdff,
  100: $blue-100,
  200: $blue-200,
  300: $blue-300,
  400: $blue-400,
  500: $blue-500,
  600: $blue-600,
  700: $blue-700,
  800: $blue-800,
  900: $blue-900,
  A100: #82b1ff,
  A200: #448aff,
  A400: #2979ff,
  A700: #2962ff,
  contrast: (
    50: $blue-900,
    100: $blue-700,
    200: $blue-700,
    300: $blue-700,
    400: white,
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
    A100: rgba(black, 0.87),
    A200: rgba(black, 0.87),
    A400: rgba(black, 0.87),
    A700: white,
  ),
);

$twaice-gray: (
  50: $gray-50,
  100: $gray-100,
  200: $gray-200,
  300: $gray-300,
  400: $gray-400,
  500: $gray-500,
  600: $gray-600,
  700: $gray-700,
  800: $gray-800,
  900: $gray-900,
  A100: $gray-100,
  A200: $gray-200,
  A400: $gray-400,
  A700: $gray-700,
  contrast: (
    50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: rgba(black, 0.87),
    400: white,
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
    A100: rgba(black, 0.87),
    A200: rgba(black, 0.87),
    A400: white,
    A700: white,
  ),
);

$twaice-orange: (
  50: #ffffff,
  100: $orange-100,
  200: $orange-200,
  300: $orange-300,
  400: $orange-400,
  500: $orange-500,
  600: $orange-600,
  700: $orange-700,
  800: $orange-800,
  900: $orange-900,
  A100: #ff9e80,
  A200: #ff6e40,
  A400: #ff3d00,
  A700: #dd2c00,
  contrast: (
    50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: rgba(black, 0.87),
    400: white,
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
    A100: rgba(black, 0.87),
    A200: rgba(black, 0.87),
    A400: white,
    A700: white,
  ),
);

$twaice-red: (
  50: #ffffff,
  100: $red-100,
  200: $red-200,
  300: $red-300,
  400: $red-400,
  500: $red-500,
  600: $red-600,
  700: $red-700,
  800: $red-800,
  900: $red-900,
  A100: $red-300,
  A200: $red-500,
  A400: $red-600,
  A700: $red-700,
  contrast: (
    50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: rgba(black, 0.87),
    400: white,
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
    A100: rgba(black, 0.87),
    A200: white,
    A400: white,
    A700: white,
  ),
);

$twaice-primary: (
  50: $color-light,
  100: $blue-gray-100,
  200: $blue-gray-200,
  300: $blue-gray-300,
  400: $blue-gray-400,
  500: $blue-gray-500,
  600: $blue-gray-600,
  700: $blue-gray-700,
  800: $blue-gray-800,
  900: $blue-gray-900,
  A100: $blue-gray-400,
  A200: $blue-gray-500,
  A400: $blue-gray-600,
  A700: $blue-gray-700,
  contrast: (
    50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: $blue-gray-700,
    300: $blue-gray-700,
    400: $blue-gray-900,
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
    A100: rgba(black, 0.87),
    A200: rgba(black, 0.87),
    A400: white,
    A700: white,
  ),
);

// create theme (use mat-light-theme for themes with light backgrounds)
$twaice-theme-primary: mat.define-palette($twaice-primary, 400, 300, 900);
$twaice-theme-accent: mat.define-palette($twaice-blue, 500, 300, 900);
$twaice-theme-warn: mat.define-palette($twaice-red, 500, 300, 900);
$twaice-theme: mat.define-light-theme(
  (
    color: (
      primary: $twaice-theme-primary,
      accent: $twaice-theme-accent,
      warn: $twaice-theme-warn,
    ),
    typography:
      mat.define-legacy-typography-config(
        $font-family: $font-family-proxima-nova,
        $headline: mat.define-typography-level(42px, 46px, $font-weight-light, $font-family-proxima-nova),
        $title: mat.define-typography-level(30px, 36px, $font-weight-light, $font-family-proxima-nova),
        $subheading-2: mat.define-typography-level(24px, 28px, $font-weight-light, $font-family-proxima-nova),
        $subheading-1: mat.define-typography-level(20px, 24px, $font-weight-normal, $font-family-proxima-nova),
        $body-1: mat.define-typography-level(16px, 24px, $font-weight-normal),
        $body-2: mat.define-typography-level(16px, 24px, $font-weight-semibold),
        $button: mat.define-typography-level(16px, 13px, $font-weight-normal, $font-family-proxima-nova),
        $input: mat.define-typography-level(16px, 1.125, 400, $font-family-proxima-nova),
      ),
    density: 0,
  )
);

// extract global variables to use it in feature modules
$primary: map-get($twaice-theme, primary);
$accent: map-get($twaice-theme, accent);
$warn: map-get($twaice-theme, warn);

@mixin twaice-theme($theme: $twaice-theme) {
  // generate material components theme
  @include mat.legacy-typography-hierarchy(
    mat.define-legacy-typography-config(
      $font-family: $font-family-proxima-nova,
      $headline: mat.define-typography-level(42px, 46px, $font-weight-light, $font-family-proxima-nova),
      $title: mat.define-typography-level(30px, 36px, $font-weight-light, $font-family-proxima-nova),
      $subheading-2: mat.define-typography-level(24px, 28px, $font-weight-light, $font-family-proxima-nova),
      $subheading-1: mat.define-typography-level(20px, 24px, $font-weight-normal, $font-family-proxima-nova),
      $body-1: mat.define-typography-level(16px, 24px, $font-weight-normal),
      $body-2: mat.define-typography-level(16px, 24px, $font-weight-semibold),
      $button: mat.define-typography-level(16px, 13px, $font-weight-normal, $font-family-proxima-nova),
      $input: mat.define-typography-level(16px, 1.125, 400, $font-family-proxima-nova)
    )
  );
  @include mat.all-legacy-component-typographies(
    mat.define-legacy-typography-config(
      $font-family: $font-family-proxima-nova,
      $headline: mat.define-typography-level(42px, 46px, $font-weight-light, $font-family-proxima-nova),
      $title: mat.define-typography-level(30px, 36px, $font-weight-light, $font-family-proxima-nova),
      $subheading-2: mat.define-typography-level(24px, 28px, $font-weight-light, $font-family-proxima-nova),
      $subheading-1: mat.define-typography-level(20px, 24px, $font-weight-normal, $font-family-proxima-nova),
      $body-1: mat.define-typography-level(16px, 24px, $font-weight-normal),
      $body-2: mat.define-typography-level(16px, 24px, $font-weight-semibold),
      $button: mat.define-typography-level(16px, 13px, $font-weight-normal, $font-family-proxima-nova),
      $input: mat.define-typography-level(16px, 1.125, 400, $font-family-proxima-nova)
    )
  );
  @include mat.legacy-core();
  @include mat.all-legacy-component-themes($theme);
  @include mat.legacy-list-typography(
    mat.define-legacy-typography-config($subheading-2: mat.define-typography-level(16px, 28px, $font-weight-light))
  );
  @include mat.legacy-table-typography(mat.define-legacy-typography-config($body-1: mat.define-typography-level(13px)));

  // apply local material components styles
  @include twaice-mat-button();
  @include twaice-mat-button-toggle();
  @include twaice-mat-card();
  @include twaice-mat-chip();
  @include twaice-mat-divider();
  @include twaice-mat-form-field();
  @include twaice-mat-menu();
  @include twaice-mat-select();
  @include twaice-mat-table();
  @include twaice-mat-toolbar();
  @include twaice-mat-chip-listbox();
  @include twaice-mat-checkbox();
  @include twaice-mat-radio-button();
  @include twaice-mat-dialog();
  @include twaice-mat-tab();
}
