import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'twaice-fe-slide-toggle',
  templateUrl: './slide-toggle.component.html',
  styleUrls: ['./slide-toggle.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class SlideToggleComponent implements OnChanges {
  @Input() id: string;
  @Input() checked: boolean;
  @Output() isCheckedToggle = new EventEmitter<boolean>();
  isChecked = false;

  ngOnChanges(changes: SimpleChanges) {
    if (changes['checked'].currentValue !== changes['checked'].previousValue) {
      this.isChecked = this.checked;
    }
  }

  onChange(event: Event) {
    this.isChecked = (event.target as HTMLInputElement).checked;
    this.isCheckedToggle.emit(this.isChecked);
  }
}
