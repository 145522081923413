import { Component } from '@angular/core';

@Component({
  selector: 'twaice-fe-tab-content',
  templateUrl: './tab-content.component.html',
  styleUrls: ['./tab-content.component.scss'],
  standalone: false,
})
export class TabContentComponent {
  public visible = false;
  constructor() {}
}
