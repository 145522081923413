import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { MatLegacyFormFieldAppearance as MatFormFieldAppearance } from '@angular/material/legacy-form-field';

@Component({
  selector: 'twaice-fe-new-password',
  templateUrl: './new-password.component.html',
  styleUrls: ['./new-password.component.scss'],
  standalone: false,
})
export class NewPasswordComponent implements OnInit {
  @Output() formGroupGenerated: EventEmitter<UntypedFormGroup> = new EventEmitter<UntypedFormGroup>();
  @Input() inputAppearance: MatFormFieldAppearance = 'outline';

  newPasswordForm: UntypedFormGroup = this.fb.group(
    {
      password: new UntypedFormControl('', [
        Validators.required,
        Validators.minLength(10),
        Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\\^$*.\\[\\]{}\\(\\)?\\-“!@#%&\\/,><\\’:;|_~`])\\S{8,99}$'),
      ]),
      confirmPassword: new UntypedFormControl(''), // validation errors set in confirmPasswordValidator
    },
    { validators: this.confirmPasswordValidator.bind(this) }
  );

  constructor(private fb: UntypedFormBuilder) {}

  ngOnInit(): void {
    this.formGroupGenerated.emit(this.newPasswordForm);
  }

  /*
   * To be able to take advantage of the material form
   * */
  confirmPasswordValidator(): ValidationErrors | void {
    if (!this.newPasswordForm) {
      // to not break before it is initialise
      return;
    }

    const password = this.newPasswordForm.get('password').value;
    const confirmPassword = this.newPasswordForm.get('confirmPassword').value;
    const matchingPasswordError = confirmPassword && password !== confirmPassword;

    if (!matchingPasswordError && confirmPassword) {
      this.newPasswordForm.get('confirmPassword').setErrors(null);
    } else {
      if (!confirmPassword) {
        this.newPasswordForm.get('confirmPassword').setErrors({ required: true });
      } else if (matchingPasswordError) {
        this.newPasswordForm.get('confirmPassword').setErrors({ passwordsNotTheSame: true });
      }
    }
  }
}
