import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { actions, selectors } from '@twaice-fe/frontend/shared/store';
import {
  ENERGY_PERFORMANCE_MANAGER_ROUTE,
  MOBILITY_BASE_ROUTE,
  MOBILITY_DEPRECATED_BASE_ROUTE,
} from '@twaice-fe/shared/constants';
import { SystemDetails } from '@twaice-fe/shared/models';
import { TimeRangeEnum } from '@twaice-fe/shared/utilities';
import { BehaviorSubject, Observable, Subject, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { ChatDrawerService } from '../chat/chat-drawer.service';
import { StorageMetadataDialogComponent } from './storage-metadata-dialog/storage-metadata-dialog.component';
// eslint-disable-next-line @nx/enforce-module-boundaries

const { systemSelectors, energyAnalyticsSelectors } = selectors;
const { energyAnalyticsActions, systemActions } = actions;

@Component({
  selector: 'twaice-fe-navigation-menu',
  templateUrl: './navigation-menu.component.html',
  // eslint-disable-next-line @angular-eslint/prefer-standalone
  standalone: false,
})
export class NavigationMenuComponent implements OnInit, OnDestroy {
  @Input() isFleet = false;
  @Input() isGlobal = false;
  @Input() isPerformanceManagerSolution = false;
  @Input() isMonitoringSolution = false;
  @Input() isIncidentDetails = false;
  @Input() isWarrantyTracker = false;
  @Input() isDataExplorerSolution = false;
  @Input() isDataExplorerV2Solution = false;
  intercomTarget = 'navigation-menu';

  // eslint-disable-next-line @typescript-eslint/naming-convention
  TOTAL_STORAGE_COUNT = 10;
  storages$: Observable<SystemDetails[]>;
  filteredStorages$: Observable<SystemDetails[]>;
  selectedSystem: SystemDetails;

  selectedStorage$ = new BehaviorSubject<SystemDetails>(undefined);
  systemIngestionStatisticsLastSeenTimestamp$ = new Observable<Date | null>();

  inputValue?: string;
  timeRangeEnum = TimeRangeEnum;

  private searchTerms = new Subject<string>();
  private destroy$ = new Subject<void>();

  constructor(
    private store: Store,
    private router: Router,
    private dialog: MatDialog,
    private chatDrawerService: ChatDrawerService
  ) {
    this.initComponentState();
    this.setupSearchSubscription();
  }

  get isAvailableEnergyRoute(): boolean {
    return this.router.url.includes(ENERGY_PERFORMANCE_MANAGER_ROUTE + '/availability');
  }

  ngOnInit(): void {
    this.store
      .select(systemSelectors.getSelected)
      .pipe(
        filter((selected) => !!selected?.id),
        takeUntil(this.destroy$)
      )
      .subscribe((selectedSystem) => {
        if (this.selectedSystem?.id !== selectedSystem.id) {
          this.store.dispatch(energyAnalyticsActions.fetchSystemIngestionStatisticsData());
        }

        this.selectedSystem = selectedSystem;
        this.setStorages();
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  async onRowSelected(item: SystemDetails) {
    this.store.dispatch(systemActions.selectSystem({ systemId: item.id }));

    this.selectedSystem = item;
    this.setSelectedStorage();
  }

  openMetadataDialog() {
    this.dialog.open(StorageMetadataDialogComponent, {
      data: this.selectedStorage$.getValue().metadata,
    });
  }

  openChatDrawer() {
    this.chatDrawerService.openChatDrawer({ customerBk: this.selectedSystem.customerBk, systemBk: this.selectedSystem.systemBk });
  }

  handleSearchInput(event) {
    const value = (event.target as HTMLInputElement).value;
    this.searchTerms.next(value);
  }

  private setupSearchSubscription() {
    this.searchTerms
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        switchMap((term) => {
          if (term === '') {
            return this.storages$;
          }

          return this.storages$.pipe(
            map((storages) => storages.filter((storage) => storage.systemName.toLowerCase().includes(term.toLowerCase())))
          );
        }),
        tap((filteredStorages) => {
          this.filteredStorages$ = of(filteredStorages);
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  private setStorages() {
    this.storages$ = this.store
      .select(systemSelectors.getSystemDetailsList)
      .pipe(distinctUntilChanged((prev, curr) => JSON.stringify(prev) === JSON.stringify(curr)));

    this.storages$
      .pipe(
        take(1),
        tap(() => {
          this.setSelectedStorage();
        })
      )
      .subscribe();

    this.filteredStorages$ = this.storages$;
  }

  private setSelectedStorage() {
    if (!this.selectedSystem) return;

    this.store
      .select(systemSelectors.getSelected)
      .pipe(
        distinctUntilChanged((prev, curr) => JSON.stringify(prev) === JSON.stringify(curr)),
        takeUntil(this.destroy$)
      )
      .subscribe((selectedStorage) => this.selectedStorage$.next(selectedStorage));

    this.systemIngestionStatisticsLastSeenTimestamp$ = this.store.select(
      energyAnalyticsSelectors.getSystemIngestionStatisticsLastSeenTimestamp
    );
  }

  private initComponentState() {
    this.isFleet = this.router.url.includes(MOBILITY_BASE_ROUTE) || this.router.url.includes(MOBILITY_DEPRECATED_BASE_ROUTE);
  }
}
