import { Injectable } from '@angular/core';
import { BehaviorSubject, filter, map, Observable, skip, Subscription } from 'rxjs';

// Amplify Libraries -
import { Router } from '@angular/router';
import { Auth, CognitoUser } from '@aws-amplify/auth';
import { Store } from '@ngrx/store';
import { actions } from '@twaice-fe/frontend/shared/store';
import { INTERNAL_UI_CONFIG_OVERRIDE, PASSWORD_EXPIRATION_TIME } from '@twaice-fe/shared/constants';
import { TokenInfo, User } from '@twaice-fe/shared/models';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { authSelectors } from 'libs/frontend/shared/store/src/selectors';

const { authActions } = actions;

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private user: BehaviorSubject<User> = new BehaviorSubject<User>(null);
  private authCompleted: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  // if set to true, all navigation will redirect to the reset password page
  private forcePasswordChange = false;

  constructor(
    private store: Store,
    private router: Router
  ) {
    this.store
      .select(authSelectors.getUser)
      // skip the initial null emission
      .pipe(skip(1))
      .subscribe((state: CognitoUser) => this.onAmplifyStateChange(state));
  }

  /*
   * Logout user, clear local storage and redirect them to login page
   * */
  async logout(): Promise<void> {
    if (this.authCompleted.getValue() && this.isLoggedIn()) {
      try {
        localStorage.removeItem(INTERNAL_UI_CONFIG_OVERRIDE);

        this.user.next(null);
        this.authCompleted.next(true);

        await Auth.signOut();
        // TODO: fix this potencial race condition in those two lines above. Resolves ticket MOB-20
        this.store.dispatch(authActions.logout());

        // do a native refresh to clear js vm
        window.location.href = '/login';
      } catch (error) {
        console.log('[AUTH] Logging out error', error);
      }
    }
  }

  /*
   * check if user is logged in
   * */
  isLoggedIn(): boolean {
    return !!this.user.getValue();
  }

  /*
   * check if user needs to change password
   * */
  isPasswordChangeForced(): boolean {
    return this.forcePasswordChange;
  }

  isMfaEnabled(): boolean {
    return false;
  }

  getUserObservable(): Observable<User | undefined> {
    return this.user.asObservable();
  }

  getUsername(): string {
    const user: User = this.user.getValue();
    return user ? user.username : '';
  }

  waitForAuthComplete(): Promise<boolean> {
    let authCompletedSubscription: Subscription;
    if (this.authCompleted.getValue()) {
      // if there is a current user value, then the authorisation was already resolved
      // and we can return the user value
      return Promise.resolve(true);
    } else {
      // If there is no current user value, then we want to wait for the authentication to be completed
      // and then provide the existence of the user/non-existence of the user
      return new Promise<boolean>((resolve, reject) => {
        authCompletedSubscription = this.authCompleted.subscribe({
          next: (completed) => {
            if (completed) {
              resolve(true);
            }
          },
          error: (error) => {
            reject(error);
          },
        });
      }).then(() => {
        authCompletedSubscription.unsubscribe();
        return true;
      });
    }
  }

  getUsersCustomerBK(): string {
    const user = this.user.getValue();
    return user ? user.customerBK : null;
  }

  /*
   * This function returns the access token regardless of the type of
   * authorisation used for signing in
   * */
  getAccessToken(): Promise<string> {
    return Auth.currentSession().then((session) => {
      const accessToken = session.getAccessToken();
      return accessToken ? accessToken.getJwtToken() : null;
    });
  }

  /*
   * This function returns the ID token regardless of the type of
   * authorisation used for signing in
   * */
  getIdToken(): Promise<string> {
    return Auth.currentSession().then((session) => {
      const idToken = session.getIdToken();
      return idToken ? idToken.getJwtToken() : null;
    });
  }

  getTokens(): Promise<TokenInfo> {
    return Auth.currentSession().then((session) => ({
      idToken: session.getIdToken().getJwtToken(),
      accessToken: session.getAccessToken().getJwtToken(),
      refreshToken: session.getRefreshToken().getToken(),
    }));
  }

  isInternalUser() {
    return this.getUserObservable().pipe(
      filter((user) => user && !!user.username),
      map(
        (user) =>
          // TODO(FLT-1559): remove hardcoded mobility user blacklist
          user.username.endsWith('@twaice.com') && !['mobility@twaice.com', 'internal.keolis@twaice.com'].includes(user.username)
      )
    );
  }

  async checkIfPasswordResetNecessary(user: CognitoUser): Promise<void> {
    const firstLogInTimestamp = user['attributes']?.['custom:password_change_ts'];

    if (
      firstLogInTimestamp &&
      firstLogInTimestamp !== '0' &&
      Number(firstLogInTimestamp) + PASSWORD_EXPIRATION_TIME < Date.now()
    ) {
      this.forcePasswordChange = true;
      return;
    }

    this.forcePasswordChange = false;
  }

  /*
   * Every time we change the state of user, we refresh the values signedIn, and refresh the user data
   * */
  private onAmplifyStateChange(user: CognitoUser) {
    if (!user) {
      this.user.next(null);
      this.authCompleted.next(true);
      return;
    }

    const cookieSettings = user?.['attributes']?.['custom:ui_cookies'];
    const cookieOptIn = cookieSettings && cookieSettings !== 'None' ? JSON.parse(cookieSettings)?.optInV3 : null;
    this.user.next({
      username: user ? user['attributes']['email'] : (user as CognitoUser).getUsername(),
      customerBK: user['attributes'] ? user['attributes']['custom:customer_bk'] : null,
      // is cookieOptIn is a boolean it has been set by the user and we respect it. Otherwise it is null
      cookieSettings: cookieOptIn === false || cookieOptIn === true ? { optIn: cookieOptIn } : null,
      preferredMFA: user['preferredMFA'],
    });

    this.checkIfPasswordResetNecessary(user);
    this.authCompleted.next(true);
  }
}
