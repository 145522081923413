import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'twaice-fe-search-input-field',
  templateUrl: './search-input-field.component.html',
  styleUrls: ['./search-input-field.component.scss'],
  standalone: false,
})
export class SearchInputFieldComponent {
  searchTextFilter: string;

  @Input() label = 'Filter by text';
  @Output() filterChange: EventEmitter<string> = new EventEmitter<string>();

  clearTextSearchFilter() {
    this.searchTextFilter = '';
    this.onFilterChange();
  }

  onFilterChange() {
    this.searchTextFilter = this.searchTextFilter.trim();

    // we differentiate between undefined for no filter and null for when there is a sensor without a unit(status,...)
    if (!this.searchTextFilter) {
      //if both filters are empty, we clear them
      this.filterChange.emit(null);
      return;
    }

    this.filterChange.emit(this.searchTextFilter);
  }
}
