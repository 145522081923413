import { Container } from '../common/container.models';
import { Sensor } from '../common/sensors.models';

export class Measurements {
  tid?: string;
  sensorBk?: string;
  timestamps?: string[];
  values: number[];
  sampling?: string;
}

export class DESensor extends Sensor {
  label?: string;
  longLabel?: string;
  containerPath?: string;

  measurements?: Measurements;

  // for filtering purposes
  show?: boolean;
  isChecked?: boolean;
}

export class DEContainer extends Container {
  selectedSensors?: DESensor[];
  containerPath?: string;
  siblingsContainers?: DEContainer[];
}

export class PaginationMetadata<T> {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  total_pages: number;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  page_size: number;
  page: number;
  prev: string | null;
  next: string | null;
  items: T[];
}
export class PaginationWrapper<T> {
  data: PaginationMetadata<T>;
}

export class PaginatedSensor {
  data: DESensor[];
}

export class PaginatedComponent {
  data: DEContainer[];
}
