import { Pipe, PipeTransform } from '@angular/core';
import { StorageLevel } from '@twaice-fe/shared/models';
import { StorageLevelIdentifierUtils } from './storage-level-identifier.utils';
import { CustomerNameTranslationService } from './customer-name-translation.service';

@Pipe({
  name: 'storageLevelToCustomerLevelName',
  pure: false,
  standalone: true,
})
export class StorageLevelToCustomerLevelNamePipe implements PipeTransform {
  constructor(private customerNameTranslationService: CustomerNameTranslationService) {}

  transform(
    level: StorageLevel,
    capitalized: boolean = false,
    componentCount: number | undefined = undefined,
    includeCount: boolean = false
  ): string {
    return this.customerNameTranslationService.replaceLevelIdentifiers(
      StorageLevelIdentifierUtils.toLevelString(level, capitalized, componentCount, includeCount)
    );
  }
}
