import { HttpClient } from '@angular/common/http';
import { DestroyRef, Injectable, OnDestroy } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { actions, selectors } from '@twaice-fe/frontend/shared/store';
import { extractAllParamsFromRouter } from '@twaice-fe/frontend/shared/utilities';
import { DataNotFoundError, SystemDetails, Sensor, TWSingleResponse, UiConfig, CustomerType } from '@twaice-fe/shared/models';
import { INTERNAL_UI_CONFIG_OVERRIDE, SKIP_REQUESTED_SYSTEMS_INTERCEPTOR_HEADER } from '@twaice-fe/shared/constants';
import { BehaviorSubject, Observable, Subscription, combineLatest, firstValueFrom, throwError } from 'rxjs';
import { catchError, distinctUntilChanged, filter, first, map, skipWhile, switchMap } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { keysToCamel } from '@twaice-fe/shared/utilities';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
const { systemSelectors, authSelectors, configsSelectors } = selectors;
const { systemActions } = actions;

@Injectable({
  providedIn: 'root',
})
export class SystemsService implements OnDestroy {
  userSubscription: Subscription;

  private systemList$: Observable<SystemDetails[]> = new BehaviorSubject<SystemDetails[]>([]);
  private systemUiConfig: BehaviorSubject<UiConfig> = new BehaviorSubject<UiConfig>(null);
  private uiConfig: UiConfig;

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private snackBar: MatSnackBar,
    private router: Router,
    private route: ActivatedRoute,
    protected store: Store,
    private destroy$: DestroyRef
  ) {
    this.systemList$ = this.store.select(systemSelectors.getSystemDetailsList);
    this.updateCurrentSystemUiConfiguration();

    combineLatest([this.systemList$, this.route.params, this.store.select(systemSelectors.getSelected)])
      .pipe(
        skipWhile(() => !this.authService.isLoggedIn || !this.router.navigated),
        first(([systemList]) => !!(systemList && systemList.length))
      )
      .subscribe(([systemList]) => {
        const params = extractAllParamsFromRouter(this.router);
        const systemBk = params['systemBk'];
        // if the route still contains a legacy system ID query param we use it and set that system as active
        const legacySystemId = new URLSearchParams(window.location.search).get('systemID');

        if (!systemBk) return this.setCurrentSystem(systemList.find((s) => s.id === legacySystemId) || systemList[0]);

        const systemMatch = systemList.find((system) => system.systemBk === systemBk);

        if (systemMatch) {
          this.setCurrentSystem(systemMatch);
          return;
        }

        alert('ERROR: Invalid URL parameters! You tried to access a system that your user credentials do not have access to.');

        return this.store.dispatch(systemActions.selectSystem({ systemId: systemList[0].id }));
      });

    this.authService.waitForAuthComplete().then(() => {
      combineLatest([this.authService.getUserObservable(), this.store.select(configsSelectors.getConfigsState)])
        .pipe(
          distinctUntilChanged((prev, curr) => JSON.stringify(prev) === JSON.stringify(curr)),
          takeUntilDestroyed(this.destroy$)
        )
        .subscribe(([user, config]) => {
          if (!user || !config) return;
          if (config.customerType === undefined || config.customerType === CustomerType.MODELS) return;

          this.store.dispatch(systemActions.fetchSystemDetails());
        });
    });
  }

  ngOnDestroy() {
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
  }

  async handleLogout() {
    this.resetConfig();
    await this.authService.logout();
  }

  getCurrentSystem(): Observable<SystemDetails> {
    return this.store.select(systemSelectors.getSelected).pipe(filter((system) => !!system));
  }

  getCurrentTopLevelContainerId(): Observable<string> {
    return this.store.select(systemSelectors.getSelected).pipe(map((system) => system.systemBk));
  }

  async setCurrentSystem(system: SystemDetails) {
    if (!system) return;

    const current = await firstValueFrom(this.store.select(systemSelectors.getSelected));

    // only set the next value if the system actually changes(or you can trigger unnecessary reloading)
    if (!current || current.id !== system.id) {
      this.store.dispatch(systemActions.selectSystem({ systemId: system.id }));
    }

    // not to reset the UI config if UIConfig is already set
    if (!this.uiConfig) {
      this.updateCurrentSystemUiConfiguration();
    }
  }

  getCurrentSystemIdObservable(): Observable<string> {
    return this.store.select(systemSelectors.getSelectedId).pipe(filter((id) => id !== null));
  }

  /*
   * A function that returns a promise of a string for system ID. We want it to be a promise rather then observable, since we want it to
   * provide onetime value to the current user, but we want to wait for the value to actually be returned by the http request
   * */
  getCurrentSystemId(): Promise<string> {
    return firstValueFrom(this.getCurrentSystemIdObservable());
  }

  getCurrentSystemUiConfiguration(): Promise<UiConfig> {
    if (!this.systemUiConfig.getValue()) {
      return this.systemUiConfig
        .pipe(first((system) => system !== null))
        .toPromise()
        .catch((error) => {
          console.error('Router processor', error);
          this.router.navigate(['/missing-configuration']);
        })
        .then((config: UiConfig) => config);
    } else {
      return Promise.resolve(this.systemUiConfig.getValue());
    }
  }

  getSystemUiConfiguration(): Observable<UiConfig> {
    return this.systemUiConfig.asObservable();
  }

  updateCurrentSystemUiConfiguration(): void {
    const uiConfigOverride = JSON.parse(localStorage.getItem(INTERNAL_UI_CONFIG_OVERRIDE));
    if (uiConfigOverride) {
      this.uiConfig = uiConfigOverride.uiConfig;
      this.systemUiConfig.next(uiConfigOverride.uiConfig);
      return;
    }

    this.store
      .select(authSelectors.getUser)
      .pipe(
        filter((user) => !!user),
        switchMap((user) =>
          this.http.get<UiConfig>(
            `ui-config?customerBK=${user['attributes']['custom:customer_bk'] || user['attributes']['custom:customer_id']}`
          )
        )
      )
      .subscribe({
        next: (config) => {
          this.uiConfig = config;
          this.systemUiConfig.next(config);
        },
        error: (error) => this.systemUiConfig.error(error),
      });
  }

  resetConfig() {
    this.systemUiConfig.next(null);
    this.uiConfig = null;
  }

  getStringSensors({
    systemID,
    stringID,
    includeVirtualSensors,
  }: {
    systemID: string;
    stringID: string;
    includeVirtualSensors: boolean;
  }): Promise<Sensor[]> {
    const url = `systems/${systemID}/${stringID}/sensors`;

    return firstValueFrom(this.http.get<Sensor[]>(url, { params: { includeVirtualSensors: includeVirtualSensors } }));
  }

  fetchSystemDetails(fetchAll = false): Observable<SystemDetails[]> {
    const options = fetchAll ? { headers: { [SKIP_REQUESTED_SYSTEMS_INTERCEPTOR_HEADER]: 'true' } } : {};
    return this.http.get<TWSingleResponse<SystemDetails[]>>('analytics/systems', options).pipe(
      map(({ data }) => {
        if (!(data as unknown)) {
          throw new DataNotFoundError(`No system details data found for the given customer.`);
        }
        return keysToCamel(data.map((system) => ({ ...system, id: system['system_tid'] })));
      }),
      catchError((error: Error) => {
        this.router.navigate(['/']);
        const snackBarRef = this.snackBar.open(
          'Error occurred while trying to get a system list. Logging out might resolve it: ',
          'Log out',
          {
            verticalPosition: 'top',
          }
        );

        snackBarRef.onAction().subscribe(() => this.handleLogout());
        return throwError(() => error);
      })
    );
  }
}
