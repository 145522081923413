import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  ElementRef,
  inject,
  QueryList,
  ViewChildren,
} from '@angular/core';

import { NgIf } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCommentAvatarDirective, NzCommentModule } from 'ng-zorro-antd/comment';
import { NZ_DRAWER_DATA } from 'ng-zorro-antd/drawer';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { ChatService } from '../chat.service';
import { ChatMessage } from '../models';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { AuthService } from '@twaice-fe/frontend/shared/services';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { A11yModule } from '@angular/cdk/a11y';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import { SvgIconModule } from '../../svg-icon/svg-icon.module';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzEmptyModule } from 'ng-zorro-antd/empty';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NzDemoTagControlComponent } from './message-tag.component';

@Component({
  selector: 'twaice-fe-system-chat',
  imports: [
    FormsModule,
    NzCommentModule,
    NzCommentAvatarDirective,
    NzAvatarModule,
    NzFormModule,
    NzInputModule,
    NzButtonModule,
    NzSpinModule,
    NgIf,
    SvgIconModule,
    NzIconModule,
    NzDividerModule,
    NzDemoTagControlComponent,
    NzSelectModule,
    SvgIconModule,
    NzEmptyModule,
    NzSwitchModule,
    A11yModule,
  ],
  styleUrl: './chat.component.scss',
  templateUrl: './chat.component.html',
  standalone: true,
})
export class SystemChatComponent implements AfterViewInit {
  @ViewChildren('commentContent') comments: QueryList<ElementRef>;

  nzData: {
    customerBk: string;
    systemBk: string;
    defaultTags?: string[];
    tagFilter?: string;
  } = inject(NZ_DRAWER_DATA);

  isLoading = true;
  chatMessages: ChatMessage[] = [];
  filteredChatMessages: ChatMessage[] = [];
  tags: string[] = [];
  selectedTag?: string;
  includeLink = true;
  newMessage = '';
  submittingMessage = false;

  constructor(
    protected authService: AuthService,
    private chatService: ChatService,
    private modalService: NzModalService,
    private destroy$: DestroyRef,
    private cd: ChangeDetectorRef,
    private router: Router
  ) {}

  ngAfterViewInit(): void {
    this.fetchMessages();

    this.comments.changes.pipe(takeUntilDestroyed(this.destroy$)).subscribe(() => {
      if (!this.comments || !this.comments.last) return;

      this.comments.last.nativeElement.scrollIntoView();
    });
  }

  fetchMessages(): void {
    this.chatService
      .fetchChatMessages(this.nzData.customerBk, this.nzData.systemBk)
      .pipe(takeUntilDestroyed(this.destroy$))
      .subscribe((messages) => {
        this.chatMessages = messages.reverse();
        this.selectedTag = this.nzData.tagFilter;
        this.tags = Array.from(
          new Set([...messages.map((m) => m.tags).flat(), ...(this.nzData.tagFilter ? [this.nzData.tagFilter] : [])])
        );
        this.isLoading = false;
        this.filterByTag(this.selectedTag);
        this.cd.detectChanges();
      });
  }

  postMessage() {
    this.submittingMessage = true;
    this.chatService
      .postChatMessage(
        this.nzData.customerBk,
        this.nzData.systemBk,
        this.newMessage,
        [...this.getMessageTags(), ...(this.nzData.defaultTags || [])],
        this.includeLink
      )
      .subscribe(() => {
        this.newMessage = '';
        this.submittingMessage = false;
        this.fetchMessages();
      });
  }

  openDeleteModal(message: ChatMessage) {
    this.modalService.confirm({
      nzIconType: 'warning',
      nzWidth: 600,
      nzTitle: 'Delete Message',
      nzContent: 'Do you want to delete the message?',
      nzOkText: 'Delete Message',
      nzOkDanger: true,
      nzOnOk: () => {
        this.chatService.deleteChatMessage(this.nzData.customerBk, this.nzData.systemBk, message.timestamp).subscribe(() => {
          this.fetchMessages();
        });
      },
    });
  }

  getDatetimeDisplay(timestamp: string) {
    const date = new Date(timestamp);
    return date.toLocaleString();
  }

  filterByTag(tag: string): void {
    this.selectedTag = tag;
    this.filteredChatMessages = tag ? this.chatMessages.filter((m) => m.tags.includes(tag)) : this.chatMessages;
  }

  goToSourceUrl(url: string): void {
    let protocol = 'https';
    if (location.host.includes('localhost')) {
      protocol = 'http';
    }
    window.open(`${protocol}://${location.host}${url}`, '_blank');
  }

  private getMessageTags(): string[] {
    const url = this.router.url;

    if (url.includes('performance-manager')) {
      return ['Performance'];
    } else if (url.includes('/safety')) {
      return ['Safety'];
    } else if (url.includes('/energy/health')) {
      return ['Health'];
    } else if (url.includes('/warranty-tracker')) {
      return ['Warranty'];
    } else if (url.includes('/data-explorer')) {
      return ['Data Explorer'];
    } else if (url.includes('/system-monitoring')) {
      return ['System Monitoring'];
    } else if (url.includes('/reports')) {
      return ['Reports'];
    } else {
      return [];
    }
  }
}
