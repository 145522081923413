import { TimeRangeGranularityEnum } from '../common/systems.models';

export interface BaseEnergyAnalyticResponseInterface {
  customerBk: string;
  systemBk: string;
}

export interface BaseEnergyAnalyticsParamsInterface {
  customerBk?: string;
  systemBk?: string;
}

// region Safety Recommendation models
export interface SafetyRecommendationDataInterface extends BaseEnergyAnalyticResponseInterface {
  systemState: ActionStateEnum;
  numberOfStableStrings: number;
  requiredActions: ComponentStateInterface[];
  recommendedActions: ComponentStateInterface[];
}

export interface ComponentStateInterface {
  name: string;
  componentBk: string;
  componentState: ActionStateEnum;
  kpiStates: KpiStateInterface[];
}

export interface KpiStateInterface {
  kpi: SafetyKPIEnum;
  state: ActionStateEnum;
  issueTypes: KpiIssueTypeEnum[];
}

export enum SafetyKPIEnum {
  TEMPERATURE = 'TEMPERATURE',
  RESISTANCE = 'RESISTANCE',
}

export enum ActionStateEnum {
  REQUIRED = 'ACTION_REQUIRED',
  RECOMMENDED = 'ACTION_RECOMMENDED',
  STABLE = 'STABLE',
}

export enum KpiIssueTypeEnum {
  ANOMALY = 'ANOMALY',
  TREND = 'TREND',
}

export interface SafetyRecommendationComponentDetailsParamsInterface extends BaseEnergyAnalyticsParamsInterface {
  componentBk: string;
}

export interface KpiStateDetailsInterface {
  kpi: SafetyKPIEnum;
  state: ActionStateEnum;
  issueType: KpiIssueTypeEnum;
  timestamps: Date[];
  outlierComponentValues: number[];
  stableComponentsValues: number[];
}

export interface SafetyRecommendationComponentDetailsInterface extends BaseEnergyAnalyticResponseInterface {
  componentBk: string;
  kpiStates: KpiStateDetailsInterface[];
}
// endregion

// region System Ingestion Statistics Models
export interface SystemIngestionStatistics {
  lastSeen: Date | null;
}
// endregion

// region Warranty Tracker Models
export enum WarrantyKPIEnum {
  EQUIVALENT_FULL_CYCLES = 'efc',
  STATE_OF_HEALTH = 'soh',
  AVAILABILITY = 'availability',
  ROUND_TRIP_EFFICIENCY = 'rte',
}

export enum WarrantyAggregationType {
  MIN = 'MIN',
  MAX = 'MAX',
  MEAN = 'MEAN',
}

export enum OperationType {
  CHARGING = 'CHARGING',
  DISCHARGING = 'DISCHARGING',
  OPERATING = 'OPERATING',
  RESTING = 'RESTING',
  NONE = 'NONE',
}

export enum ThresholdType {
  CONSTANT = 'CONSTANT',
  STEPWISE_LINEAR = 'STEPWISE_LINEAR',
  RANGE = 'RANGE',
}

interface ThresholdStep {
  date: string | Date;
  thresholdValue: number;
}

export enum ComparisonOperator {
  LessThan = 'LT',
  GreaterThan = 'GT',
}

export enum ThresholdBoundaryType {
  Inclusive = 'INCLUSIVE',
  Exclusive = 'EXCLUSIVE',
}

export interface WarrantyBaseThreshold {
  thresholdType: ThresholdType;
  comparisonOperator?: ComparisonOperator;
  boundaryType?: ThresholdBoundaryType;
}

export interface WarrantyConstantThreshold extends WarrantyBaseThreshold {
  thresholdValue: number;
  thresholdType: ThresholdType.CONSTANT;
}

export interface WarrantyStepwiseThreshold extends WarrantyBaseThreshold {
  steps?: ThresholdStep[];
  // used only in the template to correctly show first & last steps separate from intermediate ones
  startAndEndSteps?: ThresholdStep[];
  thresholdType: ThresholdType.STEPWISE_LINEAR;
}

export interface WarrantyRangeThreshold extends WarrantyBaseThreshold {
  upperThresholdValue: number;
  lowerThresholdValue: number;
  thresholdType: ThresholdType.RANGE;
}

export type WarrantyKpiThreshold = WarrantyConstantThreshold | WarrantyStepwiseThreshold | WarrantyRangeThreshold;

export interface KPIDefinition {
  kpiType: string | null;
  operationType: OperationType | null;
  aggregationType: WarrantyAggregationType | null;
  threshold?: WarrantyKpiThreshold | null;
}

export interface KPIDefinitionResponse {
  kpiType: string;
  operationType: OperationType;
  aggregationType: WarrantyAggregationType;
  threshold?: WarrantyKpiThreshold | null;
  kpiUnit: string;
  kpiDisplayName: string;
}

export interface WarrantyKPIConfiguration {
  customerBk: string | undefined;
  systemBk: string | undefined;
  kpis: KPIDefinitionResponse[];
}

export interface WarrantyKPIConfigurationParams extends BaseEnergyAnalyticsParamsInterface {
  kpiConfiguration: KPIDefinition[];
}

export interface ValidKPIConfiguration {
  kpiType: string;
  operationTypes: OperationType[];
  aggregationTypes: WarrantyAggregationType[];
  kpiDisplayName: string;
  kpiUnit: string;
}

export enum SystemSoHSensorEnum {
  TWAICE_SOH_PERCENTAGE = 'TWAICE SoH Percentage',
  TWAICE_SOH_ENERGY = 'TWAICE SoH Energy',
}

export enum ReferenceSoHSensorEnum {
  REFERENCE_SOH_PERCENTAGE = 'Reference SoH Percentage',
  REFERENCE_SOH_ENERGY = 'Reference SoH Energy',
}

export enum SystemEFCSensorEnum {
  TWAICE_CUMULATIVE_EQUIVALENT_FULL_CYCLE = 'TWAICE Cumulative Equivalent Full Cycle',
  TWAICE_DAILY_EQUIVALENT_FULL_CYCLE = 'TWAICE Daily Equivalent Full Cycle',
  TWAICE_CUMULATIVE_ABSOLUTE_THROUGHPUT = 'TWAICE Cumulative Absolute Throughput',
  TWAICE_DAILY_ABSOLUTE_THROUGHPUT = 'TWAICE Daily Absolute Throughput',
  TWAICE_CUMULATIVE_DISCHARGE_THROUGHPUT = 'TWAICE Cumulative Discharge Throughput',
  TWAICE_DAILY_DISCHARGE_THROUGHPUT = 'TWAICE Daily Discharge Throughput',
}

export enum ReferenceEFCSensorEnum {
  REFERENCE_CUMULATIVE_EQUIVALENT_FULL_CYCLE = 'Reference Cumulative Equivalent Full Cycle',
  REFERENCE_DAILY_EQUIVALENT_FULL_CYCLE = 'Reference Daily Equivalent Full Cycle',
  REFERENCE_CUMULATIVE_ABSOLUTE_THROUGHPUT = 'Reference Cumulative Absolute Throughput',
  REFERENCE_DAILY_ABSOLUTE_THROUGHPUT = 'Reference Daily Absolute Throughput',
  REFERENCE_CUMULATIVE_DISCHARGE_THROUGHPUT = 'Reference Cumulative Discharge Throughput',
  REFERENCE_DAILY_DISCHARGE_THROUGHPUT = 'Reference Daily Discharge Throughput',
}

export enum SystemAvailabilitySensorEnum {
  TWAICE_AVAILABILITY_PERCENTAGE = 'TWAICE Availability Percentage',
  TWAICE_AVAILABILITY_ENERGY = 'TWAICE Availability Energy',
}

export enum ReferenceAvailabilitySensorEnum {
  REFERENCE_AVAILABILITY_PERCENTAGE = 'Reference Availability Percentage',
  REFERENCE_AVAILABILITY_ENERGY = 'Reference Availability Energy',
}

export enum SystemRTESensorEnum {
  TWAICE_RTE_PERCENTAGE = 'TWAICE Round Trip Efficiency Percentage',
}

export enum WarrantyKPIUnitEnum {
  CYCLES = 'cycles',
  PERCENTAGE = '%',
  MEGA_WATT_HOURS = 'MWh',
}

export interface WarrantySystemKpiDataInterface {
  timestamp: string;
  value: number;
}

export interface WarrantyReferenceValueInterface {
  referenceName: ReferenceSoHSensorEnum | ReferenceEFCSensorEnum | ReferenceAvailabilitySensorEnum;
  data: WarrantySystemKpiDataInterface[];
  unit: WarrantyKPIUnitEnum;
}

export interface WarrantySensorValueInterface {
  sensorName: SystemSoHSensorEnum | SystemEFCSensorEnum | SystemAvailabilitySensorEnum | SystemRTESensorEnum;
  data: WarrantySystemKpiDataInterface[];
  unit: WarrantyKPIUnitEnum;
}

export interface WarrantyKPIParamsInterface extends BaseEnergyAnalyticsParamsInterface {
  kpi: WarrantyKPIEnum;
  startDate: string;
  endDate: string;
  granularity: TimeRangeGranularityEnum;
}

export interface WarrantyThresholdEvaluation {
  date: string;
  kpiValue: number;
  thresholdValue: number;
  deltaToThresholdValue: number;
}

export interface WarrantyThresholdEvaluationResult {
  breaches: WarrantyThresholdEvaluation[];
  avgAbsDeltaToBreach: number | null;
  lastDayEvaluation: WarrantyThresholdEvaluation | null;
}

export interface WarrantySystemKpiRequestParams extends BaseEnergyAnalyticsParamsInterface {
  kpiType: string;
  operationType: OperationType;
  aggregationType: WarrantyAggregationType;
  timeRangeStart: string;
  timeRangeEnd: string;
  timeGranularity: TimeRangeGranularityEnum;
  includeBreaches?: boolean;
}

export interface WarrantySystemKpiResponseInterface extends BaseEnergyAnalyticResponseInterface {
  kpiType: string;
  operationType: OperationType;
  aggregationType: WarrantyAggregationType;
  timeRangeStart: string;
  timeRangeEnd: string;
  timeGranularity: TimeRangeGranularityEnum;
  includeBreaches: boolean;
  data: WarrantySystemKpiDataInterface[];
  thresholdEvaluation: WarrantyThresholdEvaluationResult | null;
  timeRangeAggregate: number | null;
}

export interface WarrantyKPIDataInterface extends BaseEnergyAnalyticResponseInterface {
  kpi: WarrantyKPIEnum;
  startDate?: string;
  endDate?: string;
  granularity?: TimeRangeGranularityEnum;
  warrantySensorValues: WarrantySensorValueInterface[];
  warrantyReferenceValues: WarrantyReferenceValueInterface[];
}
// endregion

// region Health KPI Models
export enum HealthKPIAggregationScope {
  PER_ENTITY = 'per-entity',
  ACROSS_ENTITIES = 'across-entities',
}

export enum HealthKPIAggregationType {
  AVERAGE = 'avg',
  MAX = 'max',
  MIN = 'min',
  NONE = 'none',
}

export enum HealthKPIEnum {
  DCR = 'dcr',
  EFC = 'efc',
  SOH = 'soh-c',
  TEMPERATURE_SPREAD = 'module-temperature-spread',
  VOLTAGE_SPREAD = 'cell-voltage-spread',
}

export interface HealthKPILatestParameters {
  levelBk: string;
  aggregationScope: HealthKPIAggregationScope;
  aggregationType: HealthKPIAggregationType;
  kpi: HealthKPIEnum;
}

export interface HealthKPILatestRequestParameters extends BaseEnergyAnalyticsParamsInterface, HealthKPILatestParameters {}

export interface HealthKPIAggregatedParameters extends HealthKPILatestParameters {
  startDate: string;
  endDate: string;
}
export interface HealthKPIAggregatedRequestParameters extends BaseEnergyAnalyticsParamsInterface, HealthKPIAggregatedParameters {}

export enum StandardSensorBk {
  DCR = 'twaice_dc_resistance',
  EFC = 'twaice_equivalent_full_cycles_cumulative',
  SOH = 'twaice_soh',
  TEMPERATURE_SPREAD = 'twaice_module_temperature_spread',
  VOTLAGE_SPREAD = 'twaice_cell_voltage_spread',
}
export interface HealthKPIComponent {
  componentBk?: string;
  componentName?: string;
  standardSensorBk: StandardSensorBk;
  value?: number;
  timestamp?: string;
}
export interface HealthKPILatestResponseSchema extends HealthKPILatestRequestParameters {
  data: Array<HealthKPIComponent>;
}

export interface HealthKPIAggregatedResponseSchema extends HealthKPIAggregatedRequestParameters {
  data: Array<HealthKPIComponent>;
}
// endregion

// region Storage Configuration Models
export interface StorageNameMappingsParamsInterface {
  customerBk: string;
  systemBk: string;
}
// endregion
