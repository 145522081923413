<nav class="breadcrumbs" aria-label="Breadcrumbs">
  <ul>
    <li *ngFor="let breadcrumb of breadcrumbs">
      <a
        [routerLink]="breadcrumb.url?.constructor.name === 'String' ? breadcrumb.url : breadcrumb.url()"
        queryParamsHandling="preserve"
      >
        {{ transformBreadcrumb(breadcrumb.label?.constructor.name === 'String' ? breadcrumb.label : breadcrumb.label()) }}
      </a>
    </li>
  </ul>
</nav>
