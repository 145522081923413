@use 'twaice-theme';

twaice-fe-v2-datatable {
  .datatable-card {
    background-color: white;
    padding: twaice-theme.$spacer-m;
    border-radius: twaice-theme.$spacer-s;
  }

  div.datatable {
    overflow-x: auto;
    overflow-y: auto;
  }

  .datatable tbody {
    overflow-y: auto;
  }

  table.datatable {
    border-collapse: collapse;

    thead {
      background-color: transparent;
      border-bottom: 1px solid twaice-theme.$gray-100;
      width: 100%;
      position: sticky;

      z-index: 5;

      th {
        color: twaice-theme.$blue-gray-600;
        text-align: left;
        border-top: none;
        border-bottom: none;
        height: 48px !important;
        font-size: twaice-theme.$font-size-sm;
        font-weight: twaice-theme.$font-weight-normal;
        line-height: twaice-theme.$font-size-sm;
        width: fit-content !important;
        position: sticky;

        .header:not(.single) {
          gap: twaice-theme.$spacer-xs;
          span {
            padding: twaice-theme.$spacer;
          }

          // reduce right padding if sort or filter options are present
          span:not(:only-child) {
            padding-right: 0;
          }
        }

        .header {
          display: flex;
          flex-direction: row;
          align-items: center;
          height: twaice-theme.$spacer;

          &.right {
            text-align: right;
            justify-content: end;
          }

          &.center {
            text-align: center;
            justify-content: center;
          }

          &.left {
            text-align: left;
            justify-content: start;
          }
        }

        mat-icon {
          font-size: 20px;
          width: 20px;
          height: 20px;
        }

        .sortable {
          cursor: pointer;
        }

        &.placeholder {
          width: 15px;
        }

        &.new-head {
          width: 0 !important;
        }

        &:hover {
          background-color: transparent;
        }
      }
    }

    tbody {
      width: 100%;

      tr {
        outline: none;
        background-color: transparent;
        transition: all ease 0.3s;
        position: relative;
        border-bottom: 1px solid twaice-theme.$gray-50;
        width: 100%;
        height: 60px;

        &:hover {
          background-color: #040f260a;
        }

        &.selected {
          background-color: #040f260a;
          font-weight: twaice-theme.$font-weight-semibold;
        }

        td {
          overflow: hidden;
          text-align: left;
          cursor: pointer;
          border-top: none;
          vertical-align: middle;

          white-space: normal;
          word-wrap: break-word;

          span {
            display: inline-block;

            &.status-dot::before {
              display: inline-block;
              content: '';
              width: twaice-theme.$spacer-s;
              height: twaice-theme.$spacer-s;
              border-radius: 50%;
              margin-right: 6px;
              background: twaice-theme.$blue-500;
            }
          }

          span:not(.value):not(.measurand):not(.measurand-spacing):not(div.icon):not(.sign) {
            padding: twaice-theme.$spacer-m twaice-theme.$spacer;
          }

          mat-chip-list {
            padding: twaice-theme.$spacer-m twaice-theme.$spacer;
            mat-chip {
              span {
                padding: 0px !important;
              }
            }
          }

          twaice-fe-data-display,
          twaice-fe-loading-stripe {
            padding: twaice-theme.$spacer-m twaice-theme.$spacer;
          }

          &:after {
            clear: both;
            content: '';
          }

          &.right {
            text-align: right;
          }

          &.center {
            text-align: center;
          }

          &.left {
            text-align: left;
          }

          &.deleted {
            text-decoration: line-through;
          }

          &.checkbox-cell {
            width: 20px;
          }
        }
      }
      // overwrite some styles for dense mode
      tr.dense {
        font-size: twaice-theme.$font-size-sm;
        height: auto;

        td {
          span:not(.value):not(.measurand):not(.measurand-spacing):not(div.icon):not(.sign) {
            padding: twaice-theme.$spacer twaice-theme.$spacer;
          }
        }
      }
    }

    .selected-list {
      background-color: white;

      &:hover {
        background-color: white;
      }
    }

    th,
    td,
    .header {
      &.single {
        width: 80px !important;
      }

      &.double {
        min-width: 160px !important;
      }

      &.triple {
        min-width: 240px !important;
      }
    }
  }

  .card-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
    gap: 14px;
    padding: 0 10px 0 10px;

    > div.selected {
      background-color: rgba(twaice-theme.$navy-blue, 0.04);
      font-weight: twaice-theme.$font-weight-semibold;
    }
  }

  .no-results {
    padding: 20px;
    width: 100%;
    text-align: center;
  }
}

::ng-deep span.mdc-list-item__primary-text {
  color: #040f26;
}
