import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { objectToHttpParams } from '@twaice-fe/frontend/shared/utilities';
import {
  BaseEnergyAnalyticsParamsInterface,
  ComponentNameMapping,
  DataNotFoundError,
  HealthKPIAggregatedRequestParameters,
  HealthKPIAggregatedResponseSchema,
  HealthKPIEnum,
  HealthKPILatestRequestParameters,
  HealthKPILatestResponseSchema,
  KPIDefinition,
  LevelNameMapping,
  SafetyRecommendationComponentDetailsInterface,
  SafetyRecommendationComponentDetailsParamsInterface,
  SafetyRecommendationDataInterface,
  StorageNameMapping,
  StorageNameMappingsParamsInterface,
  SystemIngestionStatistics,
  TWSingleResponse,
  ValidKPIConfiguration,
  WarrantyKPIConfiguration,
  WarrantyKPIConfigurationParams,
  WarrantyKPIDataInterface,
  WarrantyKPIParamsInterface,
} from '@twaice-fe/shared/models';
import { keysToCamel, keysToSnake } from '@twaice-fe/shared/utilities';
import { Observable, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

/* eslint-disable @typescript-eslint/naming-convention */
interface StorageNameMappingResponse {
  level_name_mapping: LevelNameMapping;
  component_name_mapping: ComponentNameMapping;
}
/* eslint-enable @typescript-eslint/naming-convention */

@Injectable({
  providedIn: 'root',
})
export class EnergyAnalyticsService {
  constructor(private httpClient: HttpClient) {}

  // region Safety Recommendation service functions
  fetchSafetyRecommendationData(params: BaseEnergyAnalyticsParamsInterface): Observable<SafetyRecommendationDataInterface> {
    const url = this.buildUrl('analytics/safety/actions-overview', [params.customerBk, params.systemBk]);

    return this.fetchData<BaseEnergyAnalyticsParamsInterface, SafetyRecommendationDataInterface>(url);
  }

  fetchSafetyRecommendationComponentDetails(
    params: SafetyRecommendationComponentDetailsParamsInterface
  ): Observable<SafetyRecommendationComponentDetailsInterface> {
    const url = this.buildUrl('analytics/safety/actions-details', [params.customerBk, params.systemBk, params.componentBk]);

    return this.fetchData<SafetyRecommendationComponentDetailsParamsInterface, SafetyRecommendationComponentDetailsInterface>(
      url
    );
  }
  // endregion

  // region System Ingestion Statistics service functions
  fetchSystemIngestionStatistics(params: BaseEnergyAnalyticsParamsInterface): Observable<SystemIngestionStatistics> {
    const url = this.buildUrl('analytics/systems', [params.customerBk, params.systemBk, 'ingestion-statistics']);

    return this.fetchData<BaseEnergyAnalyticsParamsInterface, SystemIngestionStatistics>(url);
  }
  // endregion

  // region Warranty tracker service functions
  fetchWarrantyKPIData(params: WarrantyKPIParamsInterface): Observable<WarrantyKPIDataInterface> {
    const url = this.buildUrl('analytics/warranty', [params.customerBk, params.systemBk, params.kpi]);

    return this.fetchData<unknown, WarrantyKPIDataInterface>(url, {
      startDate: params.startDate,
      endDate: params.endDate,
      granularity: params.granularity,
    });
  }

  fetchWarrantyKPIConfiguration(params: BaseEnergyAnalyticsParamsInterface): Observable<WarrantyKPIConfiguration> {
    const url = `analytics/warranty/customers/${params.customerBk}/systems/${params.systemBk}/kpi-configuration`;

    return this.fetchData<unknown, WarrantyKPIConfiguration>(url);
  }

  createWarrantyKPIConfiguration(params: WarrantyKPIConfigurationParams): Observable<WarrantyKPIConfiguration> {
    const url = `analytics/warranty/customers/${params.customerBk}/systems/${params.systemBk}/kpi-configuration`;

    return this.createData<unknown, KPIDefinition[], WarrantyKPIConfiguration>(url, null, params.kpiConfiguration);
  }

  fetchWarrantyValidKPIConfiguration(): Observable<ValidKPIConfiguration[]> {
    const url = `analytics/warranty/valid-kpi-definitions`;

    const fetchedData = this.fetchData<unknown, { kpiDefinitions: Record<string, ValidKPIConfiguration> }>(url);
    return fetchedData.pipe(map((data) => Object.values(data.kpiDefinitions)));
  }
  // endregion

  // region Health KPI functions
  fetchLatestHealthKPIData(
    kpi: HealthKPIEnum,
    params: HealthKPILatestRequestParameters
  ): Observable<HealthKPILatestResponseSchema> {
    const url = this.buildUrl(`analytics/health-kpis`, [kpi, 'latest']);
    const fetchedData = this.fetchData<HealthKPILatestRequestParameters, HealthKPILatestResponseSchema>(url, params);

    // Remove this once the backend is fixed and returns the kpi in the response
    return fetchedData.pipe(tap((data) => (data.kpi = kpi)));
  }

  fetchAggregatedHealthKPIData(
    kpi: HealthKPIEnum,
    params: HealthKPIAggregatedRequestParameters
  ): Observable<HealthKPIAggregatedResponseSchema> {
    const url = this.buildUrl(`analytics/health-kpis`, [kpi]);
    const fetchedData = this.fetchData<HealthKPIAggregatedRequestParameters, HealthKPIAggregatedResponseSchema>(url, params);

    // Remove this once the backend is fixed and returns the kpi in the response
    return fetchedData.pipe(tap((data) => (data.kpi = kpi)));
  }
  // endregion

  // region Storage Configuration functions
  fetchStorageNameMappings(params: StorageNameMappingsParamsInterface): Observable<StorageNameMapping> {
    const url = this.buildUrl('analytics/storage-configuration', [
      'customers',
      params.customerBk,
      'systems',
      params.systemBk,
      'name-mappings',
    ]);

    const response = this.fetchData<unknown, StorageNameMappingResponse>(url, undefined, false);
    return response.pipe(
      map((data) => ({
        levelNameMapping: Object.fromEntries(
          Object.entries(data.level_name_mapping).map(([key, value]) => [key, keysToCamel(value)])
        ) as LevelNameMapping,
        componentNameMapping: data.component_name_mapping,
      }))
    );
  }
  // endregion

  private fetchData<T, R>(url: string, params?: T, transformToCamelCase: boolean = true): Observable<R> {
    const httpParams = params ? objectToHttpParams(keysToSnake(params)) : undefined;

    return this.httpClient.get<TWSingleResponse<R>>(url, { params: httpParams }).pipe(
      map(({ data }) => {
        if (!(data as unknown)) {
          throw new DataNotFoundError(`No data found for the given parameters.`);
        }
        if (transformToCamelCase) {
          return keysToCamel(data);
        }
        return data;
      }),
      catchError((error: Error) => {
        console.error(`Error occurred while fetching data from ${url} with params: ${params?.toString()}`, error.message);
        return throwError(() => error);
      })
    );
  }

  private createData<T, B, R>(url: string, params?: T, body?: B): Observable<R> {
    const httpParams = params ? objectToHttpParams(keysToSnake(params)) : undefined;
    const bodyKeysToSnake = body ? keysToSnake(body) : undefined;

    return this.httpClient.put<TWSingleResponse<R>>(url, bodyKeysToSnake, { params: httpParams }).pipe(
      map(({ data }) => {
        if (!(data as unknown)) {
          throw new DataNotFoundError(`No data found for the given parameters.`);
        }
        return keysToCamel(data);
      }),
      catchError((error: Error) => {
        console.error(`Error occurred while posting to ${url}`, error.message);
        return throwError(() => error);
      })
    );
  }

  private buildUrl(endpoint: string, params?: (string | number)[]): string {
    return params ? `${endpoint}/${params.join('/')}` : `${endpoint}`;
  }
}
