import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Store } from '@ngrx/store';
import { actions, selectors } from '@twaice-fe/frontend/shared/store';
import {
  SelectorTimeRange,
  TIME_RESOLUTION_MAPPING,
  TimeRangeGranularityEnum,
  TimeRangeInterface,
  TimeResolution,
} from '@twaice-fe/shared/models';
import {areDatesEqual, TimeLength, TimeRangeEnum, TimeRangeEnumDE} from '@twaice-fe/shared/utilities';
import {
  startOfMonth,
  endOfMonth,
} from 'date-fns';
import {filter, Subject, takeUntil} from 'rxjs';

const { systemSelectors } = selectors;
const { systemActions } = actions;

@Component({
  selector: 'twaice-fe-time-selector',
  templateUrl: './time-selector.component.html',
  styleUrl: './time-selector.component.scss',
  standalone: false,
})
export class TimeSelectorComponent implements OnInit, OnDestroy, OnChanges {
  @Input() showGranularity = false;
  @Input() showFullYearSelector = false;
  @Input() isDataExplorerSolution = false;

  defaultTimeRange: TimeRangeEnum | TimeRangeEnumDE = this.isDataExplorerSolution ? TimeRangeEnumDE.LAST_7_DAYS : TimeRangeEnum.LAST_SEVEN_DAYS;
  timeRangeMapping: TimeRangeInterface[] = [];
  selectedTimeRange: TimeRangeInterface;
  selectedTimeRangeIndex: number;
  timeResolutionMapping: TimeRangeInterface[] = [
    { id: 'dailyView', value: 'date', label: 'Daily view', disabled: false },
    { id: 'monthlyView', value: 'month', label: 'Monthly view', disabled: false },
  ];
  selectedTimeResolution: TimeRangeInterface;
  selectedTimeResolutionIndex: number;
  pickerValue: [Date, Date];
  systemID: string;
  granularityOptions = Object.values(TimeRangeGranularityEnum);
  selectedGranularity: TimeRangeGranularityEnum = TimeRangeGranularityEnum.WEEKLY;
  timeResolutionDE = Object.values(TIME_RESOLUTION_MAPPING);
  selectedTimeResolutionDE: TimeResolution = TIME_RESOLUTION_MAPPING.find(
    (resolution) => resolution.value === TimeLength.QUARTER_HOUR
  );

  viewTimeRanges: SelectorTimeRange[];
  public ranges: NonNullable<unknown>;

  private destroy$ = new Subject<void>();

  constructor(protected store: Store) {}

  ngOnInit(): void {
    this.store
      .select(systemSelectors.getDefaultTimeRange)
      .pipe(takeUntil(this.destroy$))
      .subscribe((defaultTimeRange) => {
        this.defaultTimeRange = defaultTimeRange;
      });
    this.store
      .select(systemSelectors.getSelectedTimeRange)
      .pipe(
        filter(({ timeRange }) => !!timeRange),
        takeUntil(this.destroy$)
      )
      .subscribe(({ timeRange, availableTimeRanges, timeRangeGranularity, timeResolution }) => {
        this.selectedTimeRange = timeRange;
        // we mutate this object so it needs to be unfrozen from the store
        this.timeRangeMapping = JSON.parse(JSON.stringify(availableTimeRanges));
        this.timeRangeMapping = this.timeRangeMapping.map((range) => ({
          ...range,
          value: (range.value as [Date, Date]).map((t) => new Date(t)) as [Date, Date],
        }));
        this.viewTimeRanges = this.timeRangeMapping.map((t, i) => ({ ...t, value: i }));

        if (this.isDataExplorerSolution) {
          this.selectedTimeResolution = { id: 'dailyView', value: 'date', label: 'Daily view', disabled: false };
        }

        const ranges = Object.entries(this.timeRangeMapping).reduce((acc, [_, timeRange]) => {
            const dateRange = timeRange.value as [Date, Date];
            acc[timeRange.label] = [new Date(dateRange[0]), new Date(dateRange[1])];
            return acc;
        }, {} as Record<string, [Date, Date]>);


        this.ranges = ranges;
        this.viewTimeRanges = this.timeRangeMapping.map((t, i) => ({ ...t, value: i }));
        this.pickerValue = this.selectedTimeRange.value as [Date, Date];
        this.selectedTimeRangeIndex = availableTimeRanges.findIndex((range) => range.id === timeRange.id);
        this.selectedGranularity = timeRangeGranularity;
        this.selectedTimeResolutionDE = timeResolution;
        this.evaluateAvailableOptions();
      });
  }

  compareTimeResolutionDEFn(o1: { value: number } | undefined, o2: { value: number } | undefined): boolean {
    return o1?.value === o2?.value;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.showFullYearSelector) {
      this.evaluateAvailableOptions();
    }
  }

  evaluateAvailableOptions(): void {
    const fullYearObj = this.timeRangeMapping.find((t) => t.id === 'threeHundredSixtyFiveDays');
    if (fullYearObj) fullYearObj.disabled = !this.showFullYearSelector;
  }

  async onDatePickerChange(result: Date[], isFromPicker = true) {
    if (result[0] == null || result[1] == null) {
      return;
    }
    const preselectedRange = Object.entries(this.ranges).find(([_, range]) =>
      range[0].getTime() === result[0].getTime() &&
      range[1].getTime() === result[1].getTime()
    ) || null;

    this.pickerValue = [result[0], result[1]];

    if (this.selectedTimeResolution?.id === 'monthlyView' && areDatesEqual(result[0], result[1])) {
      const start = startOfMonth(result[0]);
      const end = endOfMonth(result[1]);
      this.pickerValue = [start, end];
    }

    this.selectedTimeRange = {
      id: preselectedRange ? preselectedRange[0].charAt(0).toLowerCase() + preselectedRange[0].slice(1).replace(/\s/g, "") : 'custom',
      value: [new Date(result[0]), new Date(result[1])],
      label: preselectedRange ? preselectedRange[0] : 'Custom',
      disabled: false
    };

    this.store.dispatch(
      systemActions.setTimeRange({
        timeRange: {
          ...this.selectedTimeRange,
        },
      })
    );
  }

  async onTimeResolutionChange(selectedTimeResolution: string) {
    this.selectedTimeResolution = this.timeResolutionMapping.find((t) => t.value === selectedTimeResolution);
  }

  async onGranularityChange(selectedGranularity: TimeRangeGranularityEnum) {
    this.store.dispatch(
      systemActions.setTimeRangeGranularity({
        granularity: selectedGranularity,
      })
    );
  }

  async onTimeResolutionDEChange(selectedTimeResolutionDE: TimeResolution) {
    this.store.dispatch(
      systemActions.setTimeResolution({
        granularity: selectedTimeResolutionDE,
      })
    );
  }
}
