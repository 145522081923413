@use '@angular/material' as mat;
@use 'twaice-theme';

.line-chart-wrapper {
  position: relative;
  display: grid;
  align-items: center;
  height: auto;
  grid-template-rows: minmax(16px, auto) minmax(200px, 1fr);
  row-gap: twaice-theme.$spacer-md;

  .card-content {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
}

.line-chart {
  opacity: 0;
  height: 100%;

  &.loaded {
    transition: opacity 0.5s ease-in;
    opacity: 1;
  }

  .x-axis,
  .y-axis {
    line,
    path {
      stroke: mat.get-color-from-palette(twaice-theme.$primary, 500);
    }

    &.hide-domain {
      line,
      path {
        stroke: none;
      }
    }

    text {
      font-family: twaice-theme.$font-family-proxima-nova;
      font-size: twaice-theme.$font-size-sm;
      color: mat.get-color-from-palette(twaice-theme.$primary, 600);
    }

    &-label {
      font-size: twaice-theme.$font-size-sm;
      color: mat.get-color-from-palette(twaice-theme.$primary, 700);
      text-transform: uppercase;
    }
  }

  .grid {
    line {
      stroke: mat.get-color-from-palette(twaice-theme.$twaice-gray, 100);
    }

    text,
    path {
      display: none;
    }
  }

  .lines {
    stroke-width: twaice-theme.$spacer-xxs;
    fill: none;

    .line {
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke-width: 1.5;
      opacity: 0.25;

      &.selected {
        opacity: 1;
        stroke-width: 3;
      }

      &.enable-hover {
        cursor: crosshair;
        pointer-events: all;
      }
    }
  }

  .area {
    opacity: 0;

    &.selected {
      opacity: 0.1;
    }
  }

  .line-tooltip {
    stroke-width: 1;
    opacity: 0.75;
    stroke: twaice-theme.$navy-blue;
  }
}

.icon {
  background-color: var(--legend-color, mat.get-color-from-palette(twaice-theme.$twaice-primary, 50));
  width: 7px;
  height: 7px;
  margin-right: twaice-theme.$spacer-s;

  &.round {
    border-radius: 50%;
  }

  &.square {
    border-radius: 0;
  }
}

.card-header {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .card-title-label {
    font-size: twaice-theme.$font-size-base;
    font-weight: twaice-theme.$font-weight-semibold;
    display: flex;
    align-items: center;
    gap: twaice-theme.$spacer-xs;

    twaice-fe-svg-icon {
      cursor: grab;
    }
  }

  .card-items-container {
    display: flex;
    align-items: center;
    gap: twaice-theme.$spacer;

    .card-item {
      &-container {
        display: flex;
        align-items: center;
      }

      &-label {
        font-size: twaice-theme.$font-size-sm;
      }
    }
  }
}

twaice-fe-alert-panel {
  position: absolute;
  top: -(twaice-theme.$spacer);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  text-align: center;
  white-space: pre-line;
  justify-content: center;
  font-weight: twaice-theme.$font-weight-semibold;
  font-size: twaice-theme.$font-size-base;
  line-height: twaice-theme.$font-size-base;
}

.line-chart__tooltip {
  position: absolute;
  display: none;
  top: 0;
  background-color: twaice-theme.$color-light;
  font-size: twaice-theme.$font-size-sm;
  line-height: twaice-theme.$font-size-base;
  z-index: 10;
  padding: twaice-theme.$spacer-s;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.16);
  border-radius: twaice-theme.$spacer-xxs;
  min-width: 14px;
  align-items: center;
  column-gap: twaice-theme.$spacer-s;

  &--column {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    row-gap: twaice-theme.$spacer-xs;
  }

  &--row {
    display: flex;
    align-items: center;
    column-gap: twaice-theme.$spacer;
  }

  span {
    font-family: twaice-theme.$font-family-proxima-nova;
    font-size: twaice-theme.$font-size-sm;
    color: twaice-theme.$navy-blue;
    font-weight: twaice-theme.$font-weight-normal;

    &.value {
      font-weight: twaice-theme.$font-weight-bold;
    }
  }

  /* Style the dot that shows line color */
  .dot {
    width: twaice-theme.$spacer-xs;
    height: 100%;
    min-height: 34px;
    margin-right: twaice-theme.$spacer-s;
    border-radius: twaice-theme.$spacer-xxs;
    background-color: var(--legend-color, twaice-theme.$color-light);
  }
}
