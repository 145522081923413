import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SystemMetadata, StorageLevel } from '@twaice-fe/shared/models';

@Component({
  selector: 'twaice-fe-storage-metadata-dialog',
  templateUrl: 'storage-metadata-dialog.component.html',
  standalone: false,
})
export class StorageMetadataDialogComponent {
  readonly storageLevel = StorageLevel;
  constructor(@Inject(MAT_DIALOG_DATA) public data: SystemMetadata) {}

  keepOrder() {
    return 0;
  }
}
