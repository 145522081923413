<table
  mat-table
  aria-label="Data table"
  data-test="table"
  [dataSource]="dataSource"
  [class.hoverAccent]="!config?.disableHoverAccent"
  multiTemplateDataRows
  matSort
  matSortDisableClear
>
  <ng-container matColumnDef="select" *ngIf="config?.enableBulkSelection">
    <th mat-header-cell *matHeaderCellDef style="width: 54px">
      <mat-checkbox
        *ngIf="config?.enableAllSelection"
        (change)="$event ? toggleAllRows() : null"
        [checked]="selection.hasValue() && isAllSelected()"
        [indeterminate]="selection.hasValue() && !isAllSelected()"
        class="table-checkbox"
      >
      </mat-checkbox>
    </th>
    <td mat-cell *matCellDef="let element" style="text-align: center">
      <mat-checkbox
        (click)="$event.stopPropagation()"
        (change)="$event ? onSelectionChange(element) : null"
        [checked]="selection.isSelected(element)"
        [disabled]="!selection.isSelected(element) && !config?.enableAllSelection && isLimitReached()"
        class="table-checkbox"
      >
      </mat-checkbox>
    </td>
  </ng-container>
  <ng-container *ngFor="let column of columnsConfig" [matColumnDef]="column.name">
    <th
      *matHeaderCellDef
      [disabled]="!column.sortable"
      scope="col"
      mat-header-cell
      mat-sort-header
      data-test="table-header"
      [ngStyle]="{ width: column.width }"
    >
      <span
        [twaiceFeTooltip]="column.tooltipDashed ? column.tooltip : null"
        [dashedUnderline]="column.tooltipDashed"
        data-test="info-icon"
        class="text-header"
      >
        {{ column.header | titlecase }}
      </span>
      <mat-icon
        *ngIf="column.tooltip && !column.tooltipDashed"
        data-test="info-icon"
        color="accent"
        [twaiceFeTooltip]="column.tooltip"
      >
        info_outline
      </mat-icon>
    </th>
    <td *matCellDef="let element" [class.bg-danger]="element['displayError']" mat-cell data-test="table-cell">
      <mat-icon *ngIf="column.iconName" [style.color]="element[column.iconColor]" inline class="cell-prefix">
        {{ column.iconName }}
      </mat-icon>
      <twaice-fe-data-display
        class="cell-value"
        [class]="element[column.textColor] || column.textColor"
        [value]="element[column.name]"
        [copyable]="column.copyable"
        [numberFormat]="column.numberFormat"
        [isDate]="column.isDate"
        [dateFormat]="column.dateFormat"
        [displayTimeAgo]="column.displayTimeAgo"
        [measurand]="column.measurand"
        [sign]="element[column.sign]"
        [customPipe]="column.customPipe"
      ></twaice-fe-data-display>
    </td>
  </ng-container>

  <tr *matHeaderRowDef="displayedColumns" mat-header-row data-test="table-header-row"></tr>
  <tr
    *matRowDef="let element; columns: displayedColumns"
    (click)="onRowClicked(element)"
    (mouseover)="onMouseEvent(element, true)"
    (mouseleave)="onMouseEvent(element, false)"
    [class.clickable]="isRowClickable"
    mat-row
    data-test="table-row"
    [class.selected-row]="config?.highlightSelectedRow && selectedRow === element"
  ></tr>
</table>
<mat-paginator
  *ngIf="paginationConfig?.isVisible"
  [pageSizeOptions]="paginationConfig.pageSize"
  (page)="pageChanged()"
  [pageSize]="paginationConfig.defaultPageSize"
  showFirstLastButtons
></mat-paginator>
