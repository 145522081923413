import { ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { NzNoAnimationDirective } from 'ng-zorro-antd/core/no-animation';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { ChatMessage } from '../models';
import { ChatService } from '../chat.service';

@Component({
  selector: 'twaice-fe-message-tags',
  imports: [FormsModule, NzIconModule, NzInputModule, NzTagModule, NzNoAnimationDirective],
  template: `
    @for (tag of tags; track tag) {
    <nz-tag [nzMode]="'closeable'" (nzOnClose)="deleteTag(tag)">
      {{ sliceTagName(tag) }}
    </nz-tag>
    } @if (!inputVisible) {
    <nz-tag class="editable-tag" nzNoAnimation (click)="showInput()">
      <nz-icon nzType="plus" />
      New Tag
    </nz-tag>
    } @else {
    <input
      #inputElement
      nz-input
      nzSize="small"
      type="text"
      [(ngModel)]="inputValue"
      style="width: 78px;"
      (blur)="createTag()"
      (keydown.enter)="createTag()"
    />
    }
  `,
  styles: [
    `
      .editable-tag {
        background: rgb(255, 255, 255);
        border-style: dashed;
      }
    `,
  ],
})
export class NzDemoTagControlComponent implements OnInit {
  @Input() customerBk: string;
  @Input() systemBk: string;
  @Input() chatMessage: ChatMessage;
  @ViewChild('inputElement', { static: false }) inputElement?: ElementRef;

  inputVisible = false;
  inputValue = '';

  tags: string[];
  messageTimestamp: string;

  constructor(
    private chatService: ChatService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.tags = structuredClone(this.chatMessage.tags);
    this.messageTimestamp = this.chatMessage.timestamp;
  }

  deleteTag(tag: string): void {
    let modifiedTags = structuredClone(this.tags);
    modifiedTags = modifiedTags.filter((t) => t !== tag);
    this.updateTags(modifiedTags);
  }

  sliceTagName(tag: string): string {
    const isLongTag = tag.length > 20;
    return isLongTag ? `${tag.slice(0, 20)}...` : tag;
  }

  showInput(): void {
    this.inputVisible = true;
    setTimeout(() => {
      this.inputElement?.nativeElement.focus();
    }, 10);
  }

  createTag(): void {
    if (this.inputValue && this.tags.indexOf(this.inputValue) === -1) {
      const modifiedTags = [...this.tags, this.inputValue];
      this.updateTags(modifiedTags);
    }
    this.inputValue = '';
    this.inputVisible = false;
  }

  private updateTags(tags: string[]): void {
    this.chatService
      .patchChatMessage(this.customerBk, this.systemBk, this.chatMessage.timestamp, undefined, tags)
      .subscribe((updatedMessage) => {
        console.log(updatedMessage);
        this.tags = updatedMessage.tags;
        this.cdr.detectChanges();
      });
  }
}
