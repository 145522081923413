import { ValidationTypes } from '../../validation/ValidationTypes';
import { ValidationMetadata } from '../../metadata/ValidationMetadata';
import { getMetadataStorage } from '../../metadata/MetadataStorage';
/**
 * Checks if value is missing and if so, ignores all validators.
 */
export function IsOptional(validationOptions) {
  return function (object, propertyName) {
    const args = {
      type: ValidationTypes.CONDITIONAL_VALIDATION,
      target: object.constructor,
      propertyName: propertyName,
      constraints: [(object, value) => {
        return object[propertyName] !== null && object[propertyName] !== undefined;
      }],
      validationOptions: validationOptions
    };
    getMetadataStorage().addValidationMetadata(new ValidationMetadata(args));
  };
}
