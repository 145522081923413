import {
  AfterContentInit,
  ChangeDetectionStrategy,
  Component,
  ContentChildren,
  Input,
  OnDestroy,
  QueryList,
} from '@angular/core';
import { ReplaySubject, takeUntil } from 'rxjs';
import { AccordionItemComponent } from './accordion-item/accordion-item.component';

@Component({
  selector: 'twaice-fe-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class AccordionComponent implements AfterContentInit, OnDestroy {
  @Input() multi: boolean = false;

  @ContentChildren(AccordionItemComponent) items: QueryList<AccordionItemComponent>;

  destroy$ = new ReplaySubject<boolean>();

  private expandedItems = new Set<number>();

  constructor() {}

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  ngAfterContentInit(): void {
    this.items.map((item, index) =>
      item.expandedChange.pipe(takeUntil(this.destroy$)).subscribe(() => this.handleExpandedChange(index))
    );
  }

  handleExpandedChange(id: number): void {
    this.expandedItems.add(id);

    if (!this.multi && this.expandedItems.size > 0) {
      this.items.forEach((item, index) => {
        if (index !== id) {
          item.toggleExpandedState(false);
          this.expandedItems.delete(index);
        }
      });
    }
  }
}
