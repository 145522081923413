import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ChartInfoItemContent } from '../models/chart-info.models';

@Component({
  selector: 'twaice-fe-chart-info-item',
  templateUrl: './chart-info-item.component.html',
  styleUrls: ['./chart-info-item.component.scss'],
  standalone: false,
})
export class ChartInfoItemComponent {
  @Input() item: ChartInfoItemContent;
  @Output() itemClick: EventEmitter<ChartInfoItemContent> = new EventEmitter<ChartInfoItemContent>();

  onClick(item: ChartInfoItemContent) {
    this.itemClick.emit(item);
  }
}
