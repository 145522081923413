import { Directive, HostBinding, HostListener, Input } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Store } from '@ngrx/store';
import { authSelectors } from 'libs/frontend/shared/store/src/selectors';
import { NzModalService } from 'ng-zorro-antd/modal';
import { DemoLinkEmbedComponent } from './demo-link-embed.component';

@Directive({
  selector: '[twDemoLink]',
  standalone: false,
})
export class DemoLinkDirective {
  @HostBinding('class')
  elementClass = 'cursor-pointer';

  @Input() twDemoLink: string;

  private userEmail: string;

  constructor(
    private modal: NzModalService,
    private sanitizer: DomSanitizer,
    private store: Store
  ) {
    this.store
      .select(authSelectors.getUser)
      .pipe(takeUntilDestroyed())
      .subscribe((user) => {
        this.userEmail = user?.['attributes']['email'];
      });
  }

  @HostListener('click', ['$event']) openDemo() {
    this.modal.create<DemoLinkEmbedComponent, SafeResourceUrl>({
      nzTitle: 'View Demo',
      nzContent: DemoLinkEmbedComponent,
      nzData: this.sanitizer.bypassSecurityTrustResourceUrl(
        `${this.twDemoLink}${this.userEmail ? `&email=${this.userEmail}` : ''}`
      ),
      nzCentered: true,
      nzWidth: '90vw',
      nzFooter: null,
      nzBodyStyle: {
        padding: '0',
      },
    });
  }
}
