/*
* Plotly does not support the use of font weights, but we do need a bold font we can use for labels
* in plotly. This is therefore a workaround to achieve this - having a font-family which is defined as bold
*/
@font-face {
  font-family: 'proxima-nova-bold';
  src:
    local('proxima-nova'),
    url('https://use.typekit.net/af/e4b1a9/000000000000000077359571/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/e4b1a9/000000000000000077359571/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3')
      format('woff'),
    url('https://use.typekit.net/af/e4b1a9/000000000000000077359571/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3')
      format('opentype');
  font-display: auto;
  font-style: normal;
  font-weight: 600;
}
