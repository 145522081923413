import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'replaceUnderscore',
  pure: false,
  standalone: true,
})
export class ReplaceUnderscorePipe implements PipeTransform {
  transform(value: string | undefined): string | undefined {
    if (!value) return '';

    // Replace underscores with spaces
    return value.replace('_', ' ');
  }
}
