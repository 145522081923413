import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { objectToHttpParams } from '@twaice-fe/frontend/shared/utilities';
import { keysToCamel, keysToSnake } from '@twaice-fe/shared/utilities';
import { DataNotFoundError, TWSingleResponse } from '@twaice-fe/shared/models';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(private httpClient: HttpClient) {}

  fetchData<T, R>(url: string, params?: T): Observable<R> {
    const httpParams = params ? objectToHttpParams(keysToSnake(params)) : undefined;

    return this.httpClient.get<TWSingleResponse<R>>(url, { params: httpParams }).pipe(
      map(({ data }) => {
        if (!(data as unknown)) {
          throw new DataNotFoundError(`No data found for the given parameters.`);
        }
        return keysToCamel(data);
      }),
      catchError((error: Error) => {
        console.error(`Error occurred while fetching data from ${url} with params: ${params?.toString()}`, error.message);
        return throwError(() => error);
      })
    );
  }

  buildUrl(endpoint: string, params?: (string | number)[]): string {
    return params ? `${endpoint}/${params.join('/')}` : `${endpoint}`;
  }
}
