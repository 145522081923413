import { Action, createReducer, on } from '@ngrx/store';
import * as configsActions from '../actions/config.actions';

import { ConfigsInterface, CustomerType, FeatureFlagsEnum, Solution, StorageNameMapping } from '@twaice-fe/shared/models';

export const CONFIGS_FEATURE_KEY = 'configs';

export interface State extends ConfigsInterface {
  featureFlags: string[] | FeatureFlagsEnum[];
  solutions: Solution[];
  customerType?: CustomerType;
  baseUrl?: string;
  customerBk?: string;
  useCustomerNaming: boolean;
  storageNameMappings: { [key: string]: StorageNameMapping };
}

export const initialState: State = {
  featureFlags: [],
  solutions: [],
  useCustomerNaming: true,
  storageNameMappings: {} as { [key: string]: StorageNameMapping },
};

const configReducer = createReducer(
  initialState,
  on(configsActions.loadFeatureFlagsSuccess, (state, { featureFlags }) => ({
    ...state,
    featureFlags: [...featureFlags],
  })),
  on(configsActions.loadSolutionConfigSuccess, (state, { solutions }) => ({
    ...state,
    solutions: [...solutions],
  })),
  on(configsActions.loadConfigSuccess, (state, { solutions, featureFlags, customerType, baseUrl, customerBk }) => ({
    ...state,
    solutions: [...solutions],
    featureFlags: [...featureFlags],
    customerType,
    baseUrl,
    customerBk,
  })),
  on(configsActions.useCustomerNamingChanged, (state, { useCustomerNaming }) => ({
    ...state,
    useCustomerNaming,
  })),
  on(configsActions.loadNameMappingSuccess, (state, { customerBk, systemBk, componentNameMapping, levelNameMapping }) => ({
    ...state,
    storageNameMappings: {
      ...state.storageNameMappings,
      ...{ [`${customerBk}/${systemBk}`]: { componentNameMapping, levelNameMapping } },
    },
  }))
);
export function reducer(state: State | undefined, action: Action) {
  return configReducer(state, action);
}
