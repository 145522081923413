<div>
  <nz-input-group [nzPrefix]="searchPrefix" [nzSuffix]="searchSuffix">
    <input
      nz-input
      [(ngModel)]="searchTextFilter"
      (ngModelChange)="onFilterChange()"
      [placeholder]="label"
      type="text"
      aria-label="Search"
    />
  </nz-input-group>

  <ng-template #searchPrefix>
    <i nz-icon nzType="search"></i>
  </ng-template>

  <ng-template #searchSuffix>
    <i
      *ngIf="searchTextFilter"
      nz-icon
      nzType="close-circle"
      nzTheme="fill"
      class="clear-icon"
      (click)="clearTextSearchFilter()"
    ></i>
  </ng-template>
</div>
