import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CONFIGS_FEATURE_KEY, State } from '../reducers/config.reducer';

export const getConfigsState = createFeatureSelector<State>(CONFIGS_FEATURE_KEY);

export const getConfigFeatureFlagList = createSelector(getConfigsState, (state: State) => state.featureFlags);
export const getAvailableSolutionList = createSelector(getConfigsState, (state: State) => state.solutions);

export const getCustomerType = createSelector(getConfigsState, (state: State) => state.customerType);
export const getBaseUrl = createSelector(getConfigsState, (state: State) => state.baseUrl);
export const getUseCustomerNaming = createSelector(getConfigsState, (state: State) => state.useCustomerNaming);
export const getStorageNameMappings = createSelector(getConfigsState, (state: State) => state.storageNameMappings);
export const getStorageMappingConfiguration = createSelector(getConfigsState, (state: State) => ({
  useCustomerNaming: state.useCustomerNaming,
  storageNameMappingsKeys: Object.keys(state.storageNameMappings).sort(),
}));
