// SoH
export const SENSOR_TWAICE_STR_SOH_SPECIFIED_NOMINAL_CAPACITY = '[TWAICE] String SoH (based on specified nominal capacity)';
export const SENSOR_TWAICE_STR_SOH_SPECIFIED_NOMINAL_CAPACITY_CI_LOWER_BOUND =
  '[TWAICE] String SoH (based on specified nominal capacity) Confidence Interval Lower Bound';
export const SENSOR_TWAICE_STR_SOH_SPECIFIED_NOMINAL_CAPACITY_CI_UPPER_BOUND =
  '[TWAICE] String SoH (based on specified nominal capacity) Confidence Interval Upper Bound';
export const SENSOR_TWAICE_STR_SOH_SPECIFIED_NOMINAL_CAPACITY_PREDICTION =
  '[TWAICE] SoH (based on specified nominal capacity) (prediction-ui)';

export const STANDARD_SENSOR_BK_TWAICE_STR_SOH_SPECIFIED_NOMINAL_CAPACITY = 'twaice_soh';
export const STANDARD_SENSOR_BK_TWAICE_STR_SOH_SPECIFIED_NOMINAL_CAPACITY_CI_LOWER_BOUND = 'twaice_soh_conf_lower_bound';
export const STANDARD_SENSOR_BK_TWAICE_STR_SOH_SPECIFIED_NOMINAL_CAPACITY_CI_UPPER_BOUND = 'twaice_soh_conf_upper_bound';

// SoC
export const SENSOR_TWAICE_STR_SOC = '[TWAICE] String SoC';
export const SENSOR_TWAICE_STR_CELL_SOC_MAX = '[TWAICE] String Cell SoC max';
export const SENSOR_TWAICE_STR_CELL_SOC_MIN = '[TWAICE] String Cell SoC min';
export const SENSOR_TWAICE_STR_CELL_SOC_SPREAD = '[TWAICE] String Cell SoC Spread';
export const SENSOR_STR_BMS_SOC = 'String BMS SoC';

export const STANDARD_SENSOR_BK_TWAICE_STR_CELL_SOC_MAX = 'twaice_cell_soc_max';
export const STANDARD_SENSOR_BK_TWAICE_STR_CELL_SOC_MIN = 'twaice_cell_soc_min';

// energy throughput
export const SENSOR_TWAICE_STR_ENERGY_THROUGHPUT_NET_DAILY = '[TWAICE] String Energy Throughput net (daily)';
export const SENSOR_TWAICE_STR_ENERGY_THROUGHPUT_CHARGE_DAILY = '[TWAICE] String Energy Throughput charge (daily)';
export const SENSOR_TWAICE_STR_ENERGY_THROUGHPUT_DISCHARGE_DAILY = '[TWAICE] String Energy Throughput discharge (daily)';

// equivilant full cycles
export const SENSOR_TWAICE_STR_EFC_DAILY = '[TWAICE] String Equivalent Full Cycles (daily)';
export const SENSOR_TWAICE_STR_EFC_CUMULATIVE = '[TWAICE] String Equivalent Full Cycles (cumulative)';

export const STANDARD_SENSOR_BK_TWAICE_STR_EFC_CUMULATIVE = 'twaice_equivalent_full_cycles_cumulative';

// temperature
export const SENSOR_TWAICE_STR_MODULE_TEMPERATURE_SPREAD = '[TWAICE] String Module Temperature Spread';
export const SENSOR_STR_MODULE_TEMPERATURE_MAX = 'String Module Temperature max';
export const SENSOR_STR_MODULE_TEMPERATURE_MIN = 'String Module Temperature min';
export const SENSOR_STR_MODULE_TEMPERATURE = 'String Temperature';

export const STANDARD_SENSOR_BK_TWAICE_STR_MODULE_TEMPERATURE_SPREAD = 'twaice_module_temperature_spread';
export const STANDARD_SENSOR_BK_STR_MODULE_TEMPERATURE = 'temperature_avg';
export const STANDARD_SENSOR_BK_STR_MODULE_TEMPERATURE_MAX = 'temperature_max';
export const STANDARD_SENSOR_BK_STR_MODULE_TEMPERATURE_MIN = 'temperature_min';

// cell voltage
export const SENSOR_TWAICE_STR_CELL_VOLTAGE_SPREAD = '[TWAICE] String Cell Voltage Spread';
export const STANDARD_SENSOR_BK_TWAICE_STR_CELL_VOLTAGE_SPREAD = 'twaice_cell_voltage_spread';

// warranty
export const SENSOR_TWAICE_STR_WARRANTY_RELEVANT_CYCLES_CUMULATIVE = '[TWAICE] String Warranty-relevant Cycles (cumulative)';
export const SENSOR_TWAICE_STR_WARRANTY_RELEVANT_CYCLES_DAILY = '[TWAICE] String Warranty-relevant Cycles (daily)';

// penalty cycles
export const SENSOR_TWAICE_STR_PENALTY_CYCLES_CUMULATIVE = '[TWAICE] String Penalty Cycles (cumulative)';
export const SENSOR_TWAICE_STR_PENALTY_CYCLES_DAILY = '[TWAICE] String Penalty Cycles (daily)';

// dcr
export const SENSOR_TWAICE_STR_DCR = '[TWAICE] String DC Resistance';
export const SENSOR_TWAICE_STR_DCR_CI_LOWER_BOUND = '[TWAICE] String DC Resistance Confidence Interval Lower Bound';
export const SENSOR_TWAICE_STR_DCR_CI_UPPER_BOUND = '[TWAICE] String DC Resistance Confidence Interval Upper Bound';

export const STANDARD_SENSOR_BK_TWAICE_STR_DCR = 'twaice_dc_resistance';
