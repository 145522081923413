import { SafetyState } from '../features/incidents-detection.models';
import { TimeLength } from '@twaice-fe/shared/utilities';

// TODO: Delete after removing the rest use of CCS system endpoint
export class System {
  id: string;
  name?: string;
  description?: string;
  customerId?: string;
  customerBk?: string;
  rootContainerId?: string;
  systemBk?: string;
}

export class SystemDetails {
  id: string;
  systemTid: string;
  customerBk: string;
  systemBk: string;
  systemName: string;
  safetySummary: SafetySummary | null;
  healthSummary: HealthSummary | null;
  performanceSummary: PerformanceSummary | null;
  metadata: SystemMetadata | null;
  newChatMessagesAvailable: boolean;
}

export interface TimeRangeInterface {
  id: string;
  value: [Date, Date] | string;
  label: string;
  disabled?: boolean;
}

// To be used only to map between Ant components and TimeRangeInterface
export interface SelectorTimeRange {
  id: string;
  value: number;
  label: string;
  disabled?: boolean;
}

export enum TimeRangeGranularityEnum {
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY',
  MONTHLY = 'MONTHLY',
  YEARLY = 'YEARLY',
}

export interface SafetySummary {
  safetyState: SafetyState;
}

export interface HealthSummary {
  soh: SohSummary | null;
  throughput: ThroughputSummary | null;
}

interface SohSummary {
  min: number | null;
  max: number | null;
  spread: number | null;
  spreadState: SpreadState | null;
}

interface ThroughputSummary {
  last30DaysEfc: number | null;
  totalEfc: number | null;
}

export enum SpreadState {
  STABLE = 'STABLE',
  MEDIUM = 'MEDIUM',
  HIGH = 'HIGH',
}

export interface PerformanceSummary {
  dailyEnergyLossWh: number | null;
  dailyFinancialLoss: FinancialValue | null;
  imbalanceSummary: ImbalanceSummary | null;
  rteSummary: RTESummary | null;
}

export interface FinancialValue {
  value: number;
  currency: string;
}

export interface ImbalanceSummary {
  componentCount: ImbalanceComponentCount | null;
  imbalanceState: ImbalanceState | null;
  imbalancedComponentsPercentage: number | null;
}

interface ImbalanceComponentCount {
  high: number;
  medium: number;
  balanced: number;
  missing: number;
}

export enum ImbalanceState {
  HIGH = 'HIGH',
  MEDIUM = 'MEDIUM',
  BALANCED = 'BALANCED',
}

export enum HierarchyLevel {
  PORTFOLIO = 'PORTFOLIO',
  SYSTEM = 'SYSTEM',
  CLUSTER = 'CLUSTER',
  INVERTER = 'INVERTER',
  STRING = 'STRING',
  MODULE = 'MODULE',
  CELL = 'CELL',
}

export interface ComponentLevelCount {
  levelBk: HierarchyLevel;
  count: number;
}

export class SystemMetadata {
  name: string;
  location: string | null;
  energyKwh: number | null;
  maxPowerKw: number | null;
  designEnergyCapacityKwh: number | null;
  installationDate: string | null;
  commercialOperationDate: string | null;
  containersInfo: ComponentLevelCount[];
  integrator: string | null;
  operationAndMaintenance: string | null;
  commercializer: string | null;
  timezone: string | null;
  batteryType: string | null;
  assetOwner: string | null;
  calculationLevel: number | null;
}

export interface RTESummary {
  rteState: RoundTripEfficiencyRating | null;
  rtePercentage: number | null;
}

export enum RoundTripEfficiencyRating {
  HIGH = 'HIGH',
  MEDIUM = 'MEDIUM',
  LOW = 'LOW',
}

export interface TimeResolution {
  value: TimeLength;
  label: string;
  disabled?: boolean;
}

export const TIME_RESOLUTION_MAPPING: TimeResolution[] = [
  { value: TimeLength.ZERO, label: 'Raw data', disabled: false },
  { value: TimeLength.SECOND, label: '1s', disabled: false },
  { value: TimeLength.HALF_MINUTE, label: '30s', disabled: false },
  { value: TimeLength.MINUTE, label: '1min', disabled: false },
  { value: TimeLength.QUARTER_HOUR, label: '15min', disabled: false },
  { value: TimeLength.HOUR, label: '1h', disabled: false },
  { value: TimeLength.QUARTER_DAY, label: '6h', disabled: false },
  { value: TimeLength.HALF_DAY, label: '12h', disabled: false },
  { value: TimeLength.DAY, label: '1 day', disabled: false },
  { value: TimeLength.WEEK, label: '1 week', disabled: false },
];
