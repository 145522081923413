import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import {
  SystemDetails,
  TimeRangeGranularityEnum,
  TimeRangeInterface,
  TIME_RESOLUTION_MAPPING,
  TimeResolution,
} from '@twaice-fe/shared/models';
import {TimeRangeEnum, TimeLength, TimeRangeEnumDE} from '@twaice-fe/shared/utilities';
import { systemActions } from '../actions';

export const SYSTEMS_FEATURE_KEY = 'systems';

interface RemoteState {
  isLoading: boolean;
  error: string | null;
}

export interface State extends RemoteState {
  systemDetails: EntityState<SystemDetails>;
  selectedId?: string;
  timeRange?: TimeRangeInterface | null;
  availableTimeRanges?: TimeRangeInterface[] | null;
  defaultTimeRange?: TimeRangeEnum | TimeRangeEnumDE;
  timeRangeGranularity: TimeRangeGranularityEnum | null;
  timeResolution?: TimeResolution | null;
}

export const systemDetailsAdapter: EntityAdapter<SystemDetails> = createEntityAdapter<SystemDetails>({
  selectId: (systemDetails) => systemDetails.systemTid,
});
export const initialState: State = {
  systemDetails: systemDetailsAdapter.getInitialState(),
  selectedId: '',
  isLoading: false,
  error: null,
  timeRange: null,
  availableTimeRanges: null,
  defaultTimeRange: TimeRangeEnum.LAST_THIRTY_DAYS,
  timeRangeGranularity:
    TimeRangeGranularityEnum[
      (new URL(window.location.href).searchParams.get('granularity') as keyof typeof TimeRangeGranularityEnum) || 'WEEKLY'
    ],
  timeResolution: (() => {
    const timeRes = Number(new URL(window.location.href).searchParams.get('timeRes'));
    if (timeRes) {
      return TIME_RESOLUTION_MAPPING.find((g) => g.value === timeRes)
        ? TIME_RESOLUTION_MAPPING.find((g) => g.value === timeRes)!
        : TIME_RESOLUTION_MAPPING.find((g) => g.value === TimeLength.QUARTER_HOUR)!;
    }
    return TIME_RESOLUTION_MAPPING.find((g) => g.value === TimeLength.QUARTER_HOUR)!;
  })(),
};

type ActionReducer<T = unknown> = (state: State, payload: T) => State;

const reduceSystemRouteSettled: ActionReducer<{ systemId?: string }> = (state, { systemId }) => ({
  ...state,
  selectedId: systemId || state.selectedId,
});

const reduceFetchSystemDetails: ActionReducer = (state) => ({
  ...state,
  systemDetails: systemDetailsAdapter.getInitialState(),
  isLoading: true,
  error: null,
});
const reduceLoadSystemDetailsSuccess: ActionReducer<{ systemDetails: SystemDetails[] }> = (state, { systemDetails }) => ({
  ...state,
  systemDetails: systemDetailsAdapter.upsertMany(systemDetails, state.systemDetails),
  isLoading: false,
  error: null,
});
const reduceLoadSystemDetailsFailure: ActionReducer<{ error: string }> = (state, { error }) => ({
  ...state,
  systemDetails: state.systemDetails,
  isLoading: false,
  error,
});

const reduceSetDefaultTimeRange: ActionReducer<{ defaultTimeRange: TimeRangeEnum | TimeRangeEnumDE}> = (state, { defaultTimeRange }) => ({
  ...state,
  defaultTimeRange,
});

const reduceSetTimeRange: ActionReducer<{ timeRange: TimeRangeInterface }> = (state, { timeRange }) => ({
  ...state,
  timeRange,
});

const reduceSetInitialTimeRange: ActionReducer<{ timeRange: TimeRangeInterface; availableTimeRanges: TimeRangeInterface[] }> = (
  state,
  { timeRange, availableTimeRanges }
) => ({
  ...state,
  timeRange,
  availableTimeRanges,
});

const reduceSetTimeRangeGranularity: ActionReducer<{ granularity: TimeRangeGranularityEnum }> = (state, { granularity }) => ({
  ...state,
  timeRangeGranularity: granularity,
});

const reduceSetTimeResolution: ActionReducer<{ granularity: TimeResolution }> = (state, { granularity }) => ({
  ...state,
  timeResolution: granularity,
});

const systemsReducer = createReducer(
  initialState,
  on(systemActions.fetchSystemDetails, reduceFetchSystemDetails),
  on(systemActions.loadSystemDetailsSuccess, reduceLoadSystemDetailsSuccess),
  on(systemActions.loadSystemDetailsFailure, reduceLoadSystemDetailsFailure),
  on(systemActions.systemRouteSettled, reduceSystemRouteSettled),
  on(systemActions.setDefaultTimeRange, reduceSetDefaultTimeRange),
  on(systemActions.setTimeRange, reduceSetTimeRange),
  on(systemActions.setInitialTimeRange, reduceSetInitialTimeRange),
  on(systemActions.setTimeRangeGranularity, reduceSetTimeRangeGranularity),
  on(systemActions.setTimeResolution, reduceSetTimeResolution)
);

export function reducer(state: State | undefined, action: Action) {
  return systemsReducer(state, action);
}
