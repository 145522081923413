import { DatatableConfigInterface } from '../models/datatable.interface';
import {
  FilterConfigType,
  FilterTypeEnum,
  BaseFilterType,
  DateFilterInterface,
  NumberFilterInterface,
} from '../models/filter-config.interface';

export function getAvailableFilters(tableConfig: DatatableConfigInterface): string[] {
  const filters = Object.keys(tableConfig)
    .map((key: string) => {
      const filterObject = tableConfig[key].filter ?? ({} as FilterConfigType);

      if (Object.keys(filterObject).length > 0) {
        if ([FilterTypeEnum.OPTIONS, FilterTypeEnum.SELECT, FilterTypeEnum.TEXT].includes(filterObject.type)) {
          return (filterObject as BaseFilterType).filterBy;
        }

        if (filterObject.type === FilterTypeEnum.DATE) {
          const { from, to } = filterObject as DateFilterInterface;
          return [from, to].flat();
        }

        if (filterObject.type === FilterTypeEnum.NUMBER) {
          const { from, to } = filterObject as NumberFilterInterface;
          return [from, to].flat();
        }

        return undefined;
      }
      return undefined;
    })
    .filter((filterKey) => !!filterKey);

  return filters.flat();
}

export function validateQueryParams(queryParams: Record<string, string | undefined>, tableConfig: DatatableConfigInterface) {
  const paramsObject = Object.assign({}, queryParams);
  const paramKeys = Object.keys(queryParams);
  const possibleFilterKeys = getAvailableFilters(tableConfig);

  paramKeys.forEach((key) => {
    if (!possibleFilterKeys.includes(key)) delete paramsObject[key];
  });

  return paramsObject;
}
