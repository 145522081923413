import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuardService {
  constructor(
    public authService: AuthService,
    public router: Router
  ) {}

  async canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    // if the user is not logged in, store the attempted URL in localstorage and redirect to login page
    const isLoggedIn = this.authService.isLoggedIn();
    if (!isLoggedIn) {
      localStorage.setItem('redirectTo', `${location.pathname}${location.search ?? ''}`);
      this.router.navigate(['login']);
      return false;
    }

    // if password needs to be changed, redirect to settings and block all other navigation
    if (this.authService.isPasswordChangeForced() && !route.url.toString().includes('settings')) {
      await this.router.navigate(['/settings/password-change'], { queryParams: { forceChange: true } });
      return false;
    }

    // check if there is a stored redirect URL from when the user was logged-out, if yes, delete and navigate to it
    const redirectUrl = localStorage.getItem('redirectTo');
    if (redirectUrl) {
      localStorage.removeItem('redirectTo');
      await this.router.navigateByUrl(redirectUrl);
    }

    return true;
  }
}
