import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

const SIDE_NAV_EXPANDED_STATE_KEY = 'sideNavExpandedState';
const INSIGHTS_DRAWER_EXPANDED_STATE_KEY = 'insightDrawerExpandedState';
const DEFAULT_SIDE_NAV_STATE: SideNavStates = 'expanded';
const DEFAULT_INSIGHTS_DRAWER_STATE: InsightDrawerStates = 'open';

type SideNavStates = 'expanded' | 'collapsed';
type InsightDrawerStates = 'open' | 'closed';

@Injectable({
  providedIn: 'root',
})
export class SideNavService {
  private sideNavStateSubject: BehaviorSubject<SideNavStates>;
  private insightDrawerStateSubject: BehaviorSubject<InsightDrawerStates>;
  private insightDrawerResizedSubject: BehaviorSubject<void> = new BehaviorSubject(null);

  constructor() {
    this.sideNavStateSubject = new BehaviorSubject<SideNavStates>(
      this.getStoredState(SIDE_NAV_EXPANDED_STATE_KEY, DEFAULT_SIDE_NAV_STATE)
    );
    this.insightDrawerStateSubject = new BehaviorSubject<InsightDrawerStates>(
      this.getStoredState(INSIGHTS_DRAWER_EXPANDED_STATE_KEY, DEFAULT_INSIGHTS_DRAWER_STATE)
    );
  }

  get sideNavState$(): Observable<SideNavStates> {
    return this.sideNavStateSubject.asObservable();
  }

  get insightsDrawerState$(): Observable<InsightDrawerStates> {
    return this.insightDrawerStateSubject.asObservable();
  }

  get insightDrawerResized$(): Observable<void> {
    return this.insightDrawerResizedSubject.asObservable();
  }

  pushInsightsDrawerResizedEvent(): void {
    this.insightDrawerResizedSubject.next();
  }

  setSideNavState(state: SideNavStates): void {
    sessionStorage.setItem(SIDE_NAV_EXPANDED_STATE_KEY, state);
    this.sideNavStateSubject.next(state);
  }

  toggleSideNavState(): void {
    const currentState = this.sideNavStateSubject.value;
    const newState = currentState === 'collapsed' ? 'expanded' : 'collapsed';
    this.setSideNavState(newState);
  }

  setInsightsDrawerState(state: InsightDrawerStates): void {
    sessionStorage.setItem(INSIGHTS_DRAWER_EXPANDED_STATE_KEY, state);
    this.insightDrawerStateSubject.next(state);
  }

  toggleInsightDrawerState(): void {
    const currentState = this.insightDrawerStateSubject.value;
    const newState = currentState === 'open' ? 'closed' : 'open';

    this.setInsightsDrawerState(newState);
  }

  private getStoredState<T>(key: string, defaultValue: T): T {
    const storedValue = sessionStorage.getItem(key);
    return (storedValue as T) || defaultValue;
  }
}
