import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { TxNativeModule } from '@transifex/angular';
import { BATTERY_ENCYCLOPEDIA_LINK, DEMO_DOWNLOAD_LINK } from '@twaice-fe/shared/constants';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzTypographyModule } from 'ng-zorro-antd/typography';
import { DemoLinkModule } from '../demo-link/demo-link.module';

@Component({
  selector: 'twaice-fe-empty-solution',
  imports: [CommonModule, NzTypographyModule, NzButtonModule, TxNativeModule, DemoLinkModule],
  templateUrl: './empty-solution.component.html',
  styleUrl: './empty-solution.component.scss',
})
export class EmptySolutionComponent {
  @Input() illustration: string;
  @Input() headline: string;
  @Input() demoLink: string;
  @Input() downloadDemo: boolean;

  BATTERY_ENCYCLOPEDIA_LINK = BATTERY_ENCYCLOPEDIA_LINK;
  DEMO_DOWNLOAD_LINK = DEMO_DOWNLOAD_LINK;

  openDemoLink(): void {
    window.open(this.demoLink, '_blank', 'noopener noreferrer');
  }
}
