/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAction, props } from '@ngrx/store';
import {
  IncidentComponentOverview,
  IncidentInterface,
  IncidentStorageOverview,
  IncidentsAnalyticsInterface,
  PaginatedResponse,
  SingleIncidentParams,
  MailConfiguration,
} from '@twaice-fe/shared/models';
import { ListConfigurationInterface } from '../types/list-configuration.interface';

export const fetchIncidents = createAction('[Incidents] Fetch incident log');
export const fetchIncidentsAnalytics = createAction('[Incidents] Fetch incident log analytics');
export const fetchSingleIncident = createAction('[Incidents] Fetch single incident', props<SingleIncidentParams>());

export const loadIncidentsSuccess = createAction(
  '[Incidents/API] Load incidents Success',
  props<{ incidents: PaginatedResponse<IncidentInterface> }>()
);

export const loadIncidentsAnalyticsSuccess = createAction(
  '[Incidents/API] Load incidents analytics Success',
  props<{ analytics: PaginatedResponse<IncidentsAnalyticsInterface> }>()
);

export const loadSingleIncidentSuccess = createAction(
  '[Incidents/API] Load single incident Success',
  props<{ incident: IncidentInterface }>()
);

export const loadIncidentsFailure = createAction('[Incidents/API] Load Incidents Failure', props<{ error: any }>());
export const loadSingleIncidentFailure = createAction('[Incidents/API] Load Single Incident Failure', props<{ error: any }>());

export const updateIncidentListConfiguration = createAction(
  '[Incidents] Update table configuration',
  props<{ config: ListConfigurationInterface; isLoading?: boolean }>()
);

export const cancelIncidentsRequest = createAction('[Incidents/API] Cancel incidents http request');

export const sortIncidents = createAction('[Incidents/ API] Sort incidents', props<{ order: string }>());

export const filterIncidents = createAction(
  '[Incidents/ API] Filter incidents',
  props<{ filter: Record<string, string | undefined> }>()
);

export const filterIncidentsAnalytics = createAction(
  '[Incidents/ API] Filter incidents analytics',
  props<{ filter: Record<string, string | undefined> }>()
);

export const resetIncidentFilters = createAction('[Incidents/ API] Reset Filter Incidents');
export const incidentColumnPicker = createAction('[Datatable/Config] Incidents column picker', props<{ columns: string[] }>());

/**
 * Storage Overview
 */
export const fetchStorageOverview = createAction('[Incidents/Storage Overview] Fetch storage overview');
export const loadStorageOverviewSuccess = createAction(
  '[Incidents/Storage Overview] Fetch storage overview success',
  props<{ overview: IncidentStorageOverview }>()
);
export const loadStorageOverviewFailure = createAction(
  '[Incidents/Storage Overview] Fetch storage overview failure',
  props<{ error: any }>()
);
export const fetchNodeOverview = createAction('[Incidents/Node Overview] Fetch node overview');
export const loadNodeOverviewSuccess = createAction(
  '[Incidents/Node Overview] Fetch node overview success',
  props<{ overview: IncidentComponentOverview | null }>()
);
export const loadNodeOverviewFailure = createAction(
  '[Incidents/Node Overview] Fetch node overview failure',
  props<{ error: any }>()
);

export const fetchStringOverview = createAction(
  '[Incidents/String Overview] Fetch string overview',
  props<{ parentComponentBk?: string; groupBy: 'component' | 'sensor_name' }>()
);
export const loadStringOverviewSuccess = createAction(
  '[Incidents/String Overview] Fetch string overview success',
  props<{ overview: IncidentComponentOverview | null; parentComponentBk?: string | null }>()
);
export const loadStringOverviewFailure = createAction(
  '[Incidents/String Overview] Fetch string overview failure',
  props<{ error: any }>()
);

/**
 * Mail Notifications
 */
export const fetchMailNotificationConfig = createAction('[Incidents/Mail Notifications] Fetch mail configuration');
export const fetchMailNotificationConfigSuccess = createAction(
  '[Incidents/Mail Notifications] Fetch mail configuration success',
  props<{ mailConfig: MailConfiguration }>()
);
export const fetchMailNotificationConfigFailure = createAction(
  '[Incidents/Mail Notifications] Fetch mail configuration failure',
  props<{ error: any }>()
);
export const updateMailNotificationConfig = createAction(
  '[Incidents/Mail Notifications] Update mail configuration',
  props<{ mailConfig: MailConfiguration }>()
);
export const updateMailNotificationConfigSuccess = createAction(
  '[Incidents/Mail Notifications] Update mail configuration success',
  props<{ mailConfig: MailConfiguration }>()
);
export const updateMailNotificationConfigFailure = createAction(
  '[Incidents/Mail Notifications] Update mail configuration failure',
  props<{ error: any }>()
);

export const updateDateFilter = createAction('[Incidents] Change date filter', props<{ startTime: string; endTime: string }>());
