import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { SafeResourceUrl } from '@angular/platform-browser';
import { NZ_MODAL_DATA } from 'ng-zorro-antd/modal';
import { FakeLoadingComponent } from '../fake-loading/fake-loading.component';

@Component({
  selector: 'twaice-fe-demo-link-embed',
  imports: [CommonModule, FakeLoadingComponent],
  templateUrl: './demo-link-embed.component.html',
  styleUrl: './demo-link-embed.component.scss',
})
export class DemoLinkEmbedComponent {
  loading = true;

  readonly demoLink: SafeResourceUrl = inject(NZ_MODAL_DATA);

  onLoad() {
    this.loading = false;
  }
}
