import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { PerformanceManagerService } from '@twaice-fe/frontend/shared/services';
import { updateQueryParameter } from '@twaice-fe/frontend/shared/utilities';
import { PerformanceManagerKPI } from '@twaice-fe/shared/models';
import { catchError, concatMap, filter, map, of, switchMap, takeUntil, tap, withLatestFrom } from 'rxjs';
import * as performanceManagerActions from '../actions/performance-manager.actions';
import * as selectors from '../selectors';
export * as systemSelectors from '../selectors/systems.selectors';
import * as performanceManagerSelectors from '../selectors/performance-manager.selectors';

const { systemSelectors } = selectors;

@Injectable()
export class PerformanceManagerEffects {
  fetchBalancingSystemStatistics$ = createEffect(() =>
    this.actions$.pipe(
      ofType(performanceManagerActions.fetchBalancingSystemStatistics),
      withLatestFrom(
        this.store.select(systemSelectors.getSelected),
        this.store.select(performanceManagerSelectors.getSelectedDay)
      ),
      filter(([, system]) => system != null),
      switchMap(([params, system, selectedDay]) =>
        this.performanceManagerService
          .fetchSystemStatistics({ ...params, customerBk: system?.customerBk, systemBk: system?.systemBk })
          .pipe(
            tap((statistics) => {
              // if the currently selected day is not part of the returned data, we default back to the most recent one
              const needToResetSelectedDay = !statistics.data.some((s) => s.x === selectedDay);
              if (needToResetSelectedDay) {
                this.store.dispatch(
                  performanceManagerActions.selectBalancingSystemStatisticsDay({
                    selectedDay: statistics.data[statistics.data.length - 1].x,
                  })
                );
              }
            }),
            map((balancingSystemStatistics) =>
              performanceManagerActions.loadBalancingSystemStatisticsSuccess({ balancingSystemStatistics })
            ),
            takeUntil(this.actions$.pipe(ofType(performanceManagerActions.cancelBalancingSystemStatisticsRequest))),
            catchError((error) => {
              console.error('[Error/Fetch system statistics]', error);
              return of(performanceManagerActions.loadBalancingSystemStatisticsFailure({ error }));
            })
          )
      )
    )
  );

  fetchTemperatureSystemStatistics$ = createEffect(() =>
    this.actions$.pipe(
      ofType(performanceManagerActions.fetchTemperatureSystemStatistics),
      withLatestFrom(
        this.store.select(systemSelectors.getSelected),
        this.store.select(performanceManagerSelectors.getSelectedDay)
      ),
      filter(([, system]) => system != null),
      switchMap(([params, system, selectedDay]) =>
        this.performanceManagerService
          .fetchSystemStatistics({ ...params, customerBk: system?.customerBk, systemBk: system?.systemBk })
          .pipe(
            tap((statistics) => {
              // if the currently selected day is not part of the returned data, we default back to the most recent one
              const needToResetSelectedDay = !statistics.data.some((s) => s.x === selectedDay);
              if (needToResetSelectedDay) {
                this.store.dispatch(
                  performanceManagerActions.selectTemperatureSystemStatisticsDay({
                    selectedDay: statistics.data[statistics.data.length - 1].x,
                  })
                );
              }
            }),
            map((temperatureSystemStatistics) =>
              performanceManagerActions.loadTemperatureSystemStatisticsSuccess({ temperatureSystemStatistics })
            ),
            takeUntil(this.actions$.pipe(ofType(performanceManagerActions.cancelTemperatureSystemStatisticsRequest))),
            catchError((error) => {
              console.error('[Error/Fetch system statistics]', error);
              return of(performanceManagerActions.loadTemperatureSystemStatisticsFailure({ error }));
            })
          )
      )
    )
  );

  fetchSystemKPIDataInverter$ = createEffect(() =>
    this.actions$.pipe(
      ofType(performanceManagerActions.fetchSystemKPIDataInverter),
      withLatestFrom(
        this.store.select(systemSelectors.getSelected),
        this.store.select(performanceManagerSelectors.getSystemKPIDataInverter)
      ),
      filter(([, system]) => system != null),
      switchMap(([params, system]) =>
        this.performanceManagerService
          .fetchSystemKPIData({ ...params, customerBk: system?.customerBk, systemBk: system?.systemBk })
          .pipe(
            map((systemKPIData) => performanceManagerActions.loadSystemKPIDataInverterSuccess({ systemKPIData })),
            takeUntil(this.actions$.pipe(ofType(performanceManagerActions.cancelSystemKPIDataInverterRequest))),
            catchError((error) => of(performanceManagerActions.loadSystemKPIDataInverterFailure({ error })))
          )
      )
    )
  );

  fetchSystemKPIDataString$ = createEffect(() =>
    this.actions$.pipe(
      ofType(performanceManagerActions.fetchSystemKPIDataString),
      withLatestFrom(
        this.store.select(systemSelectors.getSelected),
        this.store.select(performanceManagerSelectors.getSystemKPIDataString)
      ),
      filter(([, system]) => system != null),
      switchMap(([params, system]) =>
        this.performanceManagerService
          .fetchSystemKPIData({ ...params, customerBk: system?.customerBk, systemBk: system?.systemBk })
          .pipe(
            map((systemKPIData) => performanceManagerActions.loadSystemKPIDataStringSuccess({ systemKPIData })),
            takeUntil(this.actions$.pipe(ofType(performanceManagerActions.cancelSystemKPIDataStringRequest))),
            catchError((error) => of(performanceManagerActions.loadSystemKPIDataStringFailure({ error })))
          )
      )
    )
  );

  fetchSystemInsights$ = createEffect(() =>
    this.actions$.pipe(
      ofType(performanceManagerActions.fetchSystemInsights),
      withLatestFrom(this.store.select(systemSelectors.getSelected)),
      concatMap(([params, system]) =>
        this.performanceManagerService
          .fetchSystemInsights({ ...params, customerBk: system?.customerBk, systemBk: system?.systemBk })
          .pipe(
            map((systemInsights) => {
              if (PerformanceManagerKPI.TEMPERATURE_MAX === params.kpi) {
                return performanceManagerActions.loadSystemInsightsSuccess({
                  systemInsights: {
                    temperature: {
                      [PerformanceManagerKPI.TEMPERATURE_MAX]: systemInsights,
                    },
                  },
                });
              }

              if (PerformanceManagerKPI.TEMPERATURE_SPREAD === params.kpi) {
                return performanceManagerActions.loadSystemInsightsSuccess({
                  systemInsights: {
                    temperature: {
                      [PerformanceManagerKPI.TEMPERATURE_SPREAD]: systemInsights,
                    },
                  },
                });
              }

              if (PerformanceManagerKPI.TEMPERATURE_MEAN === params.kpi) {
                return performanceManagerActions.loadSystemInsightsSuccess({
                  systemInsights: {
                    temperature: {
                      [PerformanceManagerKPI.TEMPERATURE_MEAN]: systemInsights,
                    },
                  },
                });
              }

              return performanceManagerActions.loadSystemInsightsSuccess({
                systemInsights: {
                  balancing: systemInsights,
                },
              });
            }),
            takeUntil(this.actions$.pipe(ofType(performanceManagerActions.cancelSystemInsightsRequest))),
            catchError((error) => {
              console.error('[Error/Fetch system insights data]', error);
              return of(performanceManagerActions.loadSystemInsightsFailure({ error }));
            })
          )
      )
    )
  );

  fetchSystemSeverityLevel$ = createEffect(() =>
    this.actions$.pipe(
      ofType(performanceManagerActions.fetchSystemSeverityLevel),
      withLatestFrom(
        this.store.select(systemSelectors.getSelected),
        this.store.select(performanceManagerSelectors.getSystemSeverityLevel)
      ),
      filter(([, system]) => system != null),
      switchMap(([, system]) =>
        this.performanceManagerService
          .fetchSystemSeverityLevel({ customerBk: system?.customerBk, systemBk: system?.systemBk })
          .pipe(
            map(({ data }) => performanceManagerActions.loadSystemSeverityLevelSuccess({ systemSeverityLevel: data })),
            takeUntil(this.actions$.pipe(ofType(performanceManagerActions.cancelSystemSeverityLevelRequest))),
            catchError((error) => {
              console.error('[Error/Fetch system severity level data]', error);
              return of(performanceManagerActions.loadSystemSeverityLevelFailure({ error }));
            })
          )
      )
    )
  );

  fetchStringKPIData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(performanceManagerActions.fetchStringKPIData),
      withLatestFrom(this.store.select(systemSelectors.getSelected), this.store.select(performanceManagerSelectors.getStringKPI)),
      filter(([, system]) => system != null),
      switchMap(([params, system]) =>
        this.performanceManagerService
          .fetchStringKPIData({ ...params, customerBk: system?.customerBk, systemBk: system?.systemBk })
          .pipe(
            map((stringKPIData) => performanceManagerActions.loadStringKPIDataSuccess({ stringKPIData })),
            takeUntil(this.actions$.pipe(ofType(performanceManagerActions.cancelStringKPIDataRequest))),
            catchError((error) => {
              console.error('[Error/Fetch string KPI data]', error);
              return of(performanceManagerActions.loadStringKPIDataFailure({ error }));
            })
          )
      )
    )
  );

  // TODO: Exclude landing page
  syncSelectedDayIntoUrl$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        performanceManagerActions.selectBalancingSystemStatisticsDay,
        performanceManagerActions.selectTemperatureSystemStatisticsDay
      ),
      withLatestFrom(this.router.events.pipe(map(() => this.router.url))),
      map(([{ selectedDay }, url]) => {
        if (url.toString().includes('/balancing') || url.toString().includes('/temperature'))
          updateQueryParameter(this.router, this.route, ['selectedDay'], [selectedDay]);

        return performanceManagerActions.syncSelectedDayIntoUrlSuccess();
      })
    )
  );

  constructor(
    private readonly actions$: Actions,
    private performanceManagerService: PerformanceManagerService,
    protected store: Store,
    private router: Router,
    private route: ActivatedRoute
  ) {}
}
