/**
 * Function that returns true when the provided string is in a proper date format
 * stringDate: string - string that we are checking for the correctness of the format
 *
 * @returns {boolean} - true when the provided string is in a proper date format
 */
export function isValidStringDate(stringDate: string): boolean {
  const date = new Date(stringDate);
  return !isNaN(date.getTime());
}
