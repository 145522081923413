import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzSegmentedModule } from 'ng-zorro-antd/segmented';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { ClampDateRangeDirective } from '../clamp-date-range/clamp-date-range.directive';
import { SvgIconModule } from '../svg-icon/svg-icon.module';
import { TimeSelectorComponent } from './time-selector.component';

const NG_ZORRO_MODULES = [NzDatePickerModule, NzSegmentedModule, NzSelectModule, NzToolTipModule];

@NgModule({
  declarations: [TimeSelectorComponent],
  imports: [CommonModule, FormsModule, SvgIconModule, ...NG_ZORRO_MODULES, ClampDateRangeDirective],
  exports: [TimeSelectorComponent],
})
export class TimeSelectorModule {}
