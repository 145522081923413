import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

// eslint-disable-next-line @nx/enforce-module-boundaries
import { SystemsService } from '@twaice-fe/frontend/shared/services';
import { catchError, concatMap, exhaustMap, map, mergeMap, of, switchMap, take, takeUntil, withLatestFrom } from 'rxjs';

import { Router } from '@angular/router';
import { updateQueryParameter } from '@twaice-fe/frontend/shared/utilities';
import { from } from 'rxjs';

import { ActivatedRoute } from '@angular/router';
import { getUnixTimestampFromDate } from '@twaice-fe/shared/utilities';
import { systemActions } from '../actions';
import { systemSelectors } from '../selectors';

@Injectable()
export class SystemsEffects {
  fetchSystemDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(systemActions.fetchSystemDetails),
      switchMap(() =>
        this.systemService.fetchSystemDetails().pipe(
          map((systemDetails) => systemActions.loadSystemDetailsSuccess({ systemDetails })),
          takeUntil(this.actions$.pipe(ofType(systemActions.cancelSystemDetailsRequest))),
          catchError((error) => {
            console.error('[Error/Fetch system details]', error);
            return of(systemActions.loadSystemDetailsFailure({ error }));
          })
        )
      )
    )
  );

  // redirect to new system route when system changes
  selectSystem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(systemActions.selectSystem),
      switchMap(({ systemId }) => this.store.select(systemSelectors.getSystemById(systemId))),
      withLatestFrom(this.store.select(systemSelectors.getSelected)),
      switchMap(([currentSystem, previousSystem]) => {
        if (!previousSystem || previousSystem.systemBk === currentSystem?.systemBk) {
          return of(systemActions.systemRouteSettled({ systemId: currentSystem?.id }));
        }

        const routeSegments = this.router.routerState.snapshot.url.split('/');

        // replace old systemBK in route with new one and drop query params, handle edgecase where customerBk === systemBk
        const newRoute = routeSegments.map((segment, i) =>
          segment === previousSystem?.systemBk && routeSegments[i - 1] === 'system'
            ? currentSystem?.systemBk
            : segment.split('?')[0]
        );

        return from(
          this.router.navigate(newRoute, {
            queryParamsHandling: 'merge',
          })
        ).pipe(map(() => systemActions.systemRouteSettled({ systemId: currentSystem?.id })));
      })
    )
  );
  // Having all navigation side effects in a single effect allows us to handle them synchronously and avoid navigation conflicts
  syncTimeIntoUrl$ = createEffect(() =>
    this.actions$.pipe(
      ofType(systemActions.setTimeRange, systemActions.setTimeResolution, systemActions.setTimeRangeGranularity),
      concatMap((action) => {
        switch (action.type) {
          case systemActions.setTimeRange.type:
            return from(
              updateQueryParameter(
                this.router,
                this.route,
                ['timeRange', 'startDate', 'endDate'],
                [
                  action.timeRange.id,
                  getUnixTimestampFromDate(action.timeRange.value[0] as Date),
                  getUnixTimestampFromDate(action.timeRange.value[1] as Date),
                ]
              )
            ).pipe(map(() => systemActions.timeRangeUrlSyncSuccess()));

          case systemActions.setTimeResolution.type:
            return from(updateQueryParameter(this.router, this.route, ['timeRes'], [action.granularity.value])).pipe(
              map(() => systemActions.timeRangeUrlSyncSuccess())
            );

          case systemActions.setTimeRangeGranularity.type:
            return from(updateQueryParameter(this.router, this.route, ['granularity'], [action.granularity])).pipe(
              map(() => systemActions.timeRangeUrlSyncSuccess())
            );
        }
      })
    )
  );

  constructor(
    private readonly actions$: Actions,
    private router: Router,
    private route: ActivatedRoute,
    protected store: Store,
    private systemService: SystemsService
  ) {}
}
