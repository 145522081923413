import { Injectable } from '@angular/core';
import { blue500, blueGray800 } from '@twaice-fe/frontend/shared/utilities';
import 'cookieconsent';
import { Observable, ReplaySubject, filter, from, of, startWith, switchMap, take } from 'rxjs';
import { AmplifyInternalService } from './amplify-internal.service';
import { AuthService } from './auth.service';

@Injectable({ providedIn: 'root' })
export class CookieConsentService {
  // cookieconsent attaches itself to the window
  private cc = (window as any).cookieconsent;

  constructor(
    private authService: AuthService,
    private amplifyInternalService: AmplifyInternalService
  ) {}

  getChoices(): Observable<boolean | undefined> {
    return this.authService.getUserObservable().pipe(
      filter((user) => !!user),
      switchMap((user) => {
        if (!user.cookieSettings) {
          // emit undefined meaning no choice has been made
          return this.showCookieBanner().pipe(startWith(undefined));
        }

        return of(user.cookieSettings.optIn);
      })
    );
  }

  setCookieChoice(optIn: boolean): Observable<string> {
    return from(this.amplifyInternalService.updateUserAttribute({ 'custom:ui_cookies': JSON.stringify({ optInV3: optIn }) }));
  }

  private showCookieBanner(): Observable<boolean> {
    const choice$ = new ReplaySubject<boolean>();

    this.cc.initialise({
      palette: {
        popup: {
          background: 'white',
          text: blueGray800,
        },
        button: {
          background: blue500,
        },
      },
      onStatusChange: (status: 'allow' | 'deny') => {
        const optIn = status === 'allow';
        this.setCookieChoice(optIn)
          .pipe(take(1))
          .subscribe(() => {
            choice$.next(optIn);
          });

        // cookieconsent stays around to give users the option to revoke consent
        // we do this in the account settings so we remove the dialog manually
        document.querySelectorAll('.cc-revoke, .cc-window').forEach((item) => item.remove());
      },
      theme: 'classic',
      position: 'bottom-left',
      type: 'opt-out',
      content: {
        message: 'TWAICE uses cookies to ensure you get the best experience in our product',
        href: '/privacy-policy',
      },
      cookie: { name: '' },
    });

    return choice$.asObservable();
  }
}
