import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'twaice-fe-placeholder',
  templateUrl: './placeholder.component.html',
  styleUrls: ['./placeholder.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class PlaceholderComponent {
  @Input()
  type: 'info' | 'error' | 'empty' | 'inspect';

  @Input()
  size: 's' | 'm' | 'l' = 'm';
}
