import { StackedBarChartData, StackedBarInfo } from '@twaice-fe/frontend/shared/utilities';
import { KpiAlertModels } from '@twaice-fe/shared/models';
import { AlertType } from '../alert-panel/models/alert-panel.models';

export enum SeverityEnumMock {
  CRITICAL = 'critical',
  ELEVATED = 'elevated',
  NORMAL = 'normal',
  MISSING = 'missing',
}

export const stackedBarInfoMock: StackedBarInfo = {
  [SeverityEnumMock.CRITICAL]: { color: '#FA541C', label: 'High imbalances' },
  [SeverityEnumMock.ELEVATED]: { color: '#D9C468', label: 'Medium imbalances' },
  [SeverityEnumMock.NORMAL]: { color: '#E6E7E9', label: 'Balanced' },
  [SeverityEnumMock.MISSING]: { color: 'rgba(0, 0, 0, 0.04)', label: 'Missing data' },
};

export const alertStackedBarMock: KpiAlertModels = {
  type: AlertType.MISSING_DATA,
  title: 'It looks like the sensor is not receiving data.',
  subTitle: 'We are already working on this, for any questions, please talk to your account representative.',
};

export const energyStackedBarChartMockFirst30: StackedBarChartData[] = [
  { x: 1675209600, y: { normal: 14, elevated: 0, critical: 0 } },
  { x: 1675296000, y: { normal: 12, elevated: 0, critical: 2 } },
  { x: 1675382400, y: { normal: 12, elevated: 0, critical: 2 } },
  { x: 1675468800, y: { normal: 12, elevated: 0, critical: 2 } },
  { x: 1675555200, y: { normal: 14, elevated: 0, critical: 0 } },
  { x: 1675641600, y: { normal: 12, elevated: 0, critical: 2 } },
  { x: 1675728000, y: { missing: 1, normal: 11, elevated: 0, critical: 2 } },
  { x: 1675814400, y: { normal: 14, elevated: 0, critical: 0 } },
  { x: 1675900800, y: { normal: 12, elevated: 0, critical: 2 } },
  { x: 1675987200, y: { normal: 14, elevated: 0, critical: 0 } },
  { x: 1676073600, y: { normal: 14, elevated: 0, critical: 0 } },
  { x: 1676160000, y: { normal: 14, elevated: 0, critical: 0 } },
  { x: 1676246400, y: { normal: 0, elevated: 0, critical: 14 } },
  { x: 1676332800, y: { normal: 14, elevated: 0, critical: 0 } },
  { x: 1676419200, y: { normal: 14, elevated: 0, critical: 0 } },
  { x: 1676505600, y: { normal: 14, elevated: 0, critical: 0 } },
  { x: 1676592000, y: { normal: 14, elevated: 0, critical: 0 } },
  { x: 1676678400, y: { normal: 14, elevated: 0, critical: 0 } },
  { x: 1676764800, y: { normal: 14, elevated: 0, critical: 0 } },
  { x: 1676851200, y: { normal: 14, elevated: 0, critical: 0 } },
  { x: 1676937600, y: { normal: 12, elevated: 0, critical: 2 } },
  { x: 1677024000, y: { normal: 12, elevated: 0, critical: 2 } },
  { x: 1677110400, y: { normal: 12, elevated: 0, critical: 2 } },
  { x: 1677196800, y: { normal: 14, elevated: 0, critical: 0 } },
  { x: 1677283200, y: { normal: 10, elevated: 0, critical: 4 } },
  { x: 1677369600, y: { normal: 10, elevated: 0, critical: 4 } },
  { x: 1677456000, y: { normal: 4, elevated: 0, critical: 10 } },
  { x: 1677542400, y: { normal: 14, elevated: 0, critical: 0 } },
  { x: 1677628800, y: { normal: 14, elevated: 0, critical: 0 } },
  { x: 1677715200, y: { normal: 12, elevated: 0, critical: 2 } },
];

export const energyStackedBarChartMockSecond30: StackedBarChartData[] = [
  {
    x: 1677801600,
    y: {
      normal: 10,
      elevated: 0,
      critical: 4,
    },
  },
  {
    x: 1677888000,
    y: {
      normal: 14,
      elevated: 0,
      critical: 0,
    },
  },
  {
    x: 1677974400,
    y: {
      normal: 12,
      elevated: 0,
      critical: 2,
    },
  },
  {
    x: 1678060800,
    y: {
      normal: 12,
      elevated: 0,
      critical: 2,
    },
  },
  {
    x: 1678147200,
    y: {
      normal: 14,
      elevated: 0,
      critical: 0,
    },
  },
  {
    x: 1678233600,
    y: {
      normal: 12,
      elevated: 0,
      critical: 2,
    },
  },
  {
    x: 1678320000,
    y: {
      normal: 12,
      elevated: 0,
      critical: 2,
    },
  },
  {
    x: 1678406400,
    y: {
      normal: 12,
      elevated: 0,
      critical: 2,
    },
  },
  {
    x: 1678492800,
    y: {
      normal: 12,
      elevated: 0,
      critical: 2,
    },
  },
  {
    x: 1678579200,
    y: {
      normal: 12,
      elevated: 0,
      critical: 2,
    },
  },
  {
    x: 1678665600,
    y: {
      normal: 12,
      elevated: 0,
      critical: 2,
    },
  },
  {
    x: 1678752000,
    y: {
      normal: 14,
      elevated: 0,
      critical: 0,
    },
  },
  {
    x: 1678838400,
    y: {
      normal: 10,
      elevated: 0,
      critical: 4,
    },
  },
  {
    x: 1678924800,
    y: {
      normal: 14,
      elevated: 0,
      critical: 0,
    },
  },
  {
    x: 1679011200,
    y: {
      normal: 12,
      elevated: 0,
      critical: 2,
    },
  },
  {
    x: 1679097600,
    y: {
      normal: 4,
      elevated: 10,
      critical: 0,
    },
  },
  {
    x: 1679184000,
    y: {
      normal: 12,
      elevated: 0,
      critical: 2,
    },
  },
  {
    x: 1679270400,
    y: {
      normal: 14,
      elevated: 0,
      critical: 0,
    },
  },
  {
    x: 1679356800,
    y: {
      normal: 14,
      elevated: 0,
      critical: 0,
    },
  },
  {
    x: 1679443200,
    y: {
      normal: 14,
      elevated: 0,
      critical: 0,
    },
  },
  {
    x: 1679529600,
    y: {
      normal: 14,
      elevated: 0,
      critical: 0,
    },
  },
  {
    x: 1679616000,
    y: {
      normal: 10,
      elevated: 0,
      critical: 4,
    },
  },
  {
    x: 1679702400,
    y: {
      normal: 12,
      elevated: 0,
      critical: 2,
    },
  },
  {
    x: 1679788800,
    y: {
      normal: 14,
      elevated: 0,
      critical: 0,
    },
  },
  {
    x: 1679875200,
    y: {
      normal: 14,
      elevated: 0,
      critical: 0,
    },
  },
  {
    x: 1679961600,
    y: {
      normal: 12,
      elevated: 0,
      critical: 2,
    },
  },
  {
    x: 1680048000,
    y: {
      normal: 14,
      elevated: 0,
      critical: 0,
    },
  },
  {
    x: 1680134400,
    y: {
      normal: 14,
      elevated: 0,
      critical: 0,
    },
  },
  {
    x: 1680220800,
    y: {
      normal: 10,
      elevated: 0,
      critical: 4,
    },
  },
  {
    x: 1680307200,
    y: {
      normal: 12,
      elevated: 0,
      critical: 2,
    },
  },
];

export const energyStackedBarChartMockThird30: StackedBarChartData[] = [
  {
    x: 1680393600,
    y: {
      normal: 14,
      elevated: 0,
      critical: 0,
    },
  },
  {
    x: 1680480000,
    y: {
      normal: 12,
      elevated: 0,
      critical: 2,
    },
  },
  {
    x: 1680566400,
    y: {
      normal: 12,
      elevated: 0,
      critical: 2,
    },
  },
  {
    x: 1680652800,
    y: {
      normal: 14,
      elevated: 0,
      critical: 0,
    },
  },
  {
    x: 1680739200,
    y: {
      normal: 10,
      elevated: 2,
      critical: 2,
    },
  },
  {
    x: 1680825600,
    y: {
      normal: 9,
      elevated: 1,
      critical: 4,
    },
  },
  {
    x: 1680912000,
    y: {
      normal: 14,
      elevated: 0,
      critical: 0,
    },
  },
  {
    x: 1680998400,
    y: {
      normal: 14,
      elevated: 0,
      critical: 0,
    },
  },
  {
    x: 1681084800,
    y: {
      normal: 14,
      elevated: 0,
      critical: 0,
    },
  },
  {
    x: 1681171200,
    y: {
      normal: 14,
      elevated: 0,
      critical: 0,
    },
  },
  {
    x: 1681257600,
    y: {
      normal: 12,
      elevated: 1,
      critical: 1,
    },
  },
  {
    x: 1681344000,
    y: {
      normal: 14,
      elevated: 0,
      critical: 0,
    },
  },
  {
    x: 1681430400,
    y: {
      normal: 14,
      elevated: 0,
      critical: 0,
    },
  },
  {
    x: 1681516800,
    y: {
      normal: 14,
      elevated: 0,
      critical: 0,
    },
  },
  {
    x: 1681603200,
    y: {
      normal: 12,
      elevated: 0,
      critical: 2,
    },
  },
  {
    x: 1681689600,
    y: {
      normal: 14,
      elevated: 0,
      critical: 0,
    },
  },
  {
    x: 1681776000,
    y: {
      normal: 14,
      elevated: 0,
      critical: 0,
    },
  },
  {
    x: 1681862400,
    y: {
      normal: 12,
      elevated: 0,
      critical: 2,
    },
  },
  {
    x: 1681948800,
    y: {
      normal: 12,
      elevated: 0,
      critical: 2,
    },
  },
  {
    x: 1682035200,
    y: {
      normal: 14,
      elevated: 0,
      critical: 0,
    },
  },
  {
    x: 1682121600,
    y: {
      normal: 10,
      elevated: 0,
      critical: 4,
    },
  },
  {
    x: 1682208000,
    y: {
      normal: 14,
      elevated: 0,
      critical: 0,
    },
  },
  {
    x: 1682294400,
    y: {
      normal: 12,
      elevated: 0,
      critical: 2,
    },
  },
  {
    x: 1682380800,
    y: {
      normal: 12,
      elevated: 1,
      critical: 1,
    },
  },
  {
    x: 1682467200,
    y: {
      normal: 14,
      elevated: 0,
      critical: 0,
    },
  },
  {
    x: 1682553600,
    y: {
      normal: 14,
      elevated: 0,
      critical: 0,
    },
  },
  {
    x: 1682640000,
    y: {
      normal: 12,
      elevated: 2,
      critical: 0,
    },
  },
  {
    x: 1682726400,
    y: {
      normal: 2,
      elevated: 1,
      critical: 11,
    },
  },
  {
    x: 1682812800,
    y: {
      normal: 14,
      elevated: 0,
      critical: 0,
    },
  },
  {
    x: 1682899200,
    y: {
      normal: 14,
      elevated: 0,
      critical: 0,
    },
  },
];

export const energyStackedBarChartMockFourth30: StackedBarChartData[] = [
  {
    x: 1682985600,
    y: {
      normal: 12,
      elevated: 0,
      critical: 2,
    },
  },
  {
    x: 1683072000,
    y: {
      normal: 14,
      elevated: 0,
      critical: 0,
    },
  },
  {
    x: 1683158400,
    y: {
      normal: 12,
      elevated: 0,
      critical: 2,
    },
  },
  {
    x: 1683244800,
    y: {
      normal: 14,
      elevated: 0,
      critical: 0,
    },
  },
  {
    x: 1683331200,
    y: {
      normal: 14,
      elevated: 0,
      critical: 0,
    },
  },
  {
    x: 1683417600,
    y: {
      normal: 14,
      elevated: 0,
      critical: 0,
    },
  },
  {
    x: 1683504000,
    y: {
      normal: 14,
      elevated: 0,
      critical: 0,
    },
  },
  {
    x: 1683590400,
    y: {
      normal: 12,
      elevated: 0,
      critical: 2,
    },
  },
  {
    x: 1683676800,
    y: {
      normal: 14,
      elevated: 0,
      critical: 0,
    },
  },
  {
    x: 1683763200,
    y: {
      normal: 12,
      elevated: 0,
      critical: 2,
    },
  },
  {
    x: 1683849600,
    y: {
      normal: 9,
      elevated: 3,
      critical: 2,
    },
  },
  {
    x: 1683936000,
    y: {
      normal: 12,
      elevated: 0,
      critical: 2,
    },
  },
  {
    x: 1684022400,
    y: {
      normal: 14,
      elevated: 0,
      critical: 0,
    },
  },
  {
    x: 1684108800,
    y: {
      normal: 0,
      elevated: 0,
      critical: 14,
    },
  },
  {
    x: 1684195200,
    y: {
      normal: 11,
      elevated: 2,
      critical: 1,
    },
  },
  {
    x: 1684281600,
    y: {
      normal: 1,
      elevated: 11,
      critical: 2,
    },
  },
  {
    x: 1684368000,
    y: {
      normal: 14,
      elevated: 0,
      critical: 0,
    },
  },
  {
    x: 1684454400,
    y: {
      normal: 0,
      elevated: 11,
      critical: 1,
    },
  },
  {
    x: 1684540800,
    y: {
      normal: 5,
      elevated: 7,
      critical: 2,
    },
  },
  {
    x: 1684627200,
    y: {
      normal: 0,
      elevated: 0,
      critical: 14,
    },
  },
  {
    x: 1684713600,
    y: {
      normal: 2,
      elevated: 11,
      critical: 1,
    },
  },
  {
    x: 1684800000,
    y: {
      normal: 14,
      elevated: 0,
      critical: 0,
    },
  },
  {
    x: 1684886400,
    y: {
      normal: 0,
      elevated: 0,
      critical: 14,
    },
  },
  {
    x: 1684972800,
    y: {
      normal: 12,
      elevated: 0,
      critical: 2,
    },
  },
  {
    x: 1685059200,
    y: {
      normal: 0,
      elevated: 0,
      critical: 14,
    },
  },
  {
    x: 1685145600,
    y: {
      normal: 0,
      elevated: 0,
      critical: 14,
    },
  },
  {
    x: 1685232000,
    y: {
      normal: 14,
      elevated: 0,
      critical: 0,
    },
  },
  {
    x: 1685318400,
    y: {
      normal: 14,
      elevated: 0,
      critical: 0,
    },
  },
  {
    x: 1685404800,
    y: {
      normal: 0,
      elevated: 0,
      critical: 14,
    },
  },
  {
    x: 1685491200,
    y: {
      normal: 14,
      elevated: 0,
      critical: 0,
    },
  },
];

export const energyStackedBarChartMockFifth30: StackedBarChartData[] = [
  {
    x: 1685577600,
    y: {
      normal: 0,
      elevated: 0,
      critical: 14,
    },
  },
  {
    x: 1685664000,
    y: {
      normal: 0,
      elevated: 0,
      critical: 14,
    },
  },
  {
    x: 1685750400,
    y: {
      normal: 14,
      elevated: 0,
      critical: 0,
    },
  },
  {
    x: 1685836800,
    y: {
      normal: 0,
      elevated: 0,
      critical: 14,
    },
  },
  {
    x: 1685923200,
    y: {
      normal: 14,
      elevated: 0,
      critical: 0,
    },
  },
  {
    x: 1686009600,
    y: {
      normal: 6,
      elevated: 8,
      critical: 0,
    },
  },
  {
    x: 1686096000,
    y: {
      normal: 0,
      elevated: 0,
      critical: 14,
    },
  },
  {
    x: 1686182400,
    y: {
      normal: 14,
      elevated: 0,
      critical: 0,
    },
  },
  {
    x: 1686268800,
    y: {
      normal: 12,
      elevated: 0,
      critical: 2,
    },
  },
  {
    x: 1686355200,
    y: {
      normal: 10,
      elevated: 4,
      critical: 0,
    },
  },
  {
    x: 1686441600,
    y: {
      normal: 14,
      elevated: 0,
      critical: 0,
    },
  },
  {
    x: 1686528000,
    y: {
      normal: 14,
      elevated: 0,
      critical: 0,
    },
  },
  {
    x: 1686614400,
    y: {
      normal: 12,
      elevated: 0,
      critical: 2,
    },
  },
  {
    x: 1686700800,
    y: {
      normal: 14,
      elevated: 0,
      critical: 0,
    },
  },
  {
    x: 1686787200,
    y: {
      normal: 14,
      elevated: 0,
      critical: 0,
    },
  },
  {
    x: 1686873600,
    y: {
      normal: 11,
      elevated: 1,
      critical: 2,
    },
  },
  {
    x: 1686960000,
    y: {
      normal: 12,
      elevated: 0,
      critical: 2,
    },
  },
  {
    x: 1687046400,
    y: {
      normal: 0,
      elevated: 0,
      critical: 14,
    },
  },
  {
    x: 1687132800,
    y: {
      normal: 11,
      elevated: 1,
      critical: 2,
    },
  },
  {
    x: 1687219200,
    y: {
      normal: 10,
      elevated: 0,
      critical: 4,
    },
  },
  {
    x: 1687305600,
    y: {
      normal: 0,
      elevated: 0,
      critical: 14,
    },
  },
  {
    x: 1687392000,
    y: {
      normal: 14,
      elevated: 0,
      critical: 0,
    },
  },
  {
    x: 1687478400,
    y: {
      normal: 12,
      elevated: 0,
      critical: 2,
    },
  },
  {
    x: 1687564800,
    y: {
      normal: 0,
      elevated: 0,
      critical: 14,
    },
  },
  {
    x: 1687651200,
    y: {
      normal: 1,
      elevated: 11,
      critical: 2,
    },
  },
  {
    x: 1687737600,
    y: {
      normal: 0,
      elevated: 0,
      critical: 14,
    },
  },
  {
    x: 1687824000,
    y: {
      normal: 0,
      elevated: 0,
      critical: 14,
    },
  },
  {
    x: 1687910400,
    y: {
      normal: 14,
      elevated: 0,
      critical: 0,
    },
  },
  {
    x: 1687996800,
    y: {
      normal: 0,
      elevated: 0,
      critical: 14,
    },
  },
  {
    x: 1688083200,
    y: {
      normal: 14,
      elevated: 0,
      critical: 0,
    },
  },
];
