/* eslint-disable @angular-eslint/component-selector */
import { Component, Input } from '@angular/core';

@Component({
  selector: '[card]',
  templateUrl: './card.component.html',
  standalone: false,
})
export class CardComponent {
  @Input() content: unknown;
  @Input() uniqueListName: string;
  @Input() template = '';
  @Input() enableBulkSelection = false;
  @Input() bulkSelected = false;
}
