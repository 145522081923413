import { ActionsButtonsCellComponent } from './actions-buttons-cell/actions-buttons-cell.component';
import { CellComponent } from './cell.component';
import { IconCellComponent } from './icon-cell-component/icon-cell.component';
import { IconLinkCellComponent } from './icon-link-cell-component/icon-link-cell.component';
import { StatusCellComponent } from './status-cell-component/status-cell.component';
import { TagCellComponent } from './tag-cell-component/tag-cell.component';
import { TextCellComponent } from './text-cell-component/text-cell.component';
import { TooltipCellComponent } from './tooltip-cell-component/tooltip-cell.component';

export const DATATABLE_CELL_COMPONENTS = [
  CellComponent,
  TextCellComponent,
  StatusCellComponent,
  TagCellComponent,
  TooltipCellComponent,
  ActionsButtonsCellComponent,
  IconCellComponent,
  IconLinkCellComponent,
];
