import { createAction, props } from '@ngrx/store';
import { CustomerType, FeatureFlagsEnum, Solution, ComponentNameMapping, LevelNameMapping } from '@twaice-fe/shared/models';

export const loadFeatureFlagsSuccess = createAction(
  '[Config/Feature flags] Load configs Success',
  props<{ featureFlags: string[] | FeatureFlagsEnum[] }>()
);

export const loadSolutionConfigSuccess = createAction(
  '[Config/Solutions] Load configs success',
  props<{ solutions: Solution[] }>()
);

export const loadConfigSuccess = createAction(
  '[Config] Load configs success',
  props<{
    featureFlags: string[] | FeatureFlagsEnum[];
    solutions: Solution[];
    customerType: CustomerType;
    baseUrl: string;
    customerBk: string;
  }>()
);

export const internalUIConfigChanged = createAction('[Config] Internal UI config changed');

export const useCustomerNamingChanged = createAction(
  '[Config] Use customer naming changed',
  props<{ useCustomerNaming: boolean }>()
);

export const loadNameMappingSuccess = createAction(
  '[Config] Load name mapping success',
  props<{
    customerBk: string;
    systemBk: string;
    componentNameMapping: ComponentNameMapping;
    levelNameMapping: LevelNameMapping;
  }>()
);
export const loadNameMappingFailure = createAction(
  '[Config] Load name mapping failure',
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  props<{ error: any }>()
);
