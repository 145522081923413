import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, of, takeUntil } from 'rxjs';
import { BaseListComponent } from './base-list.component';
import { CellConfigInterface } from './models/cell-config.interface';
import { DatatableConfigInterface } from './models/datatable.interface';
import { ListModeEnum } from './models/list-mode.enum';

@Component({
  template: '',
  standalone: false,
})
export abstract class BaseTableComponent extends BaseListComponent implements OnInit, OnDestroy {
  @Input() config: DatatableConfigInterface;
  @Input() bulkSelectionEnabled = false;
  @Input() enableRowSelection = false;
  @Input() mode?: ListModeEnum;
  // For filter component options
  @Input() cardEnabled = false;
  @Input() addActionBtnText = '';
  @Input() bodyMaxHeight? = 'max-h-[600px]';
  @Input() customClass? = 'datatable';
  @Input() enableVirtualScroll = false;

  @Output() sortChanged = new EventEmitter();

  hasHeaderSection = false;
  headerSections$: Observable<CellConfigInterface[]>;

  tableDisplayMode$: Observable<ListModeEnum>;
  columns$: Observable<string[]>;
  orderDirection$: Observable<string>;
  orderColumn$: Observable<string>;

  order: string;

  computedClasses: { [key: string]: boolean } = {};

  constructor(
    protected store: Store,
    public breakpointObserver: BreakpointObserver
  ) {
    super(store);
  }

  ngOnInit() {
    super.ngOnInit();

    this.computedClasses[this.bodyMaxHeight] = true;
    this.computedClasses[this.customClass] = true;

    this.initColumns();
    this.tableDisplayMode$ = of(this.mode);

    this.store
      .select<unknown>(this.getSelector())
      .pipe(takeUntil(this.destroy$))
      .subscribe(({ config }) => {
        this.order = 'asc';

        if (config.order) {
          const [column, direction] = config.order.split(':');
          this.order = direction;
          this.orderColumn$ = of(column);
          this.orderDirection$ = of(this.order);
        }

        this.tableDisplayMode$ = of(this.mode ?? config.listMode ?? ListModeEnum.LIST);
        this.columns$ = of(config.columns);
      });

    if (this.cardEnabled) {
      this.breakpointObserver.observe(['(min-width: 1024px)']).subscribe(({ matches }: BreakpointState) => {
        if (!matches) {
          this.tableDisplayMode$ = of(ListModeEnum.CARD);
          return;
        }

        this.tableDisplayMode$ = of(this.mode ?? ListModeEnum.LIST);
      });
    }

    const configKeys = Object.keys(this.config);
    this.hasHeaderSection = configKeys.some((key) => this.config[key].section);
    this.headerSections$ = of(configKeys.filter((key) => this.config[key].section).map((key) => this.config[key]));
  }

  sort(sortableKey: string) {
    this.store.dispatch(this.getSortActions(`${sortableKey}:${this.order === 'asc' ? 'desc' : 'asc'}`));
    this.orderDirection$ = of(this.order);
  }

  private initColumns = () => {
    this.store.dispatch(this.getTableConfigActions({ columns: Object.keys(this.config) }));
  };
}
