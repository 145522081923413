import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { actions } from '@twaice-fe/frontend/shared/store';
import { ChartInfoItemContent } from '@twaice-fe/frontend/shared/ui-components';
import { FilterBarValues, missingCalculationAlert } from '@twaice-fe/frontend/shared/utilities';
import { AggregationType, HealthV2, HealthRequestParameters, PaginatedResponse } from '@twaice-fe/shared/models';
import { BehaviorSubject, Observable } from 'rxjs';
import { kpisChartInfoConfig, kpisChartLayoutConfig, sortDataByConfig } from '../kpis-distribution/kpis-distribution.config';
import {
  chartInfoTitleMapping,
  DistributionKpisEnum,
  FeHealthContainer,
  FilteredHealthKpiData,
  KPIChartData,
  KPIDistributionData,
  KpiDistributionInterface,
  KPITableData,
} from '../models/kpis-distribution.models';

const { healthAction } = actions;

@Injectable({
  providedIn: 'root',
})
export class KpisDistributionService {
  kpisChartLayout = kpisChartLayoutConfig;

  kpisChartInfoConfig = kpisChartInfoConfig;

  private kpiTableDataExist$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(protected store: Store) {}

  setKpiTableDataExist(value: boolean): void {
    this.kpiTableDataExist$.next(value);
  }

  getKpiTableDataExist(): Observable<boolean> {
    return this.kpiTableDataExist$.asObservable();
  }

  createKpiDistributionData({
    health,
    healthRequestParameters,
  }: {
    health: HealthV2;
    healthRequestParameters: HealthRequestParameters[];
  }): KPIDistributionData {
    const healthContainerList = health.data;

    const kpiName = health.kpi as DistributionKpisEnum;

    if (!healthContainerList.length) return { kpiName: kpiName };

    const kpiChartData: number[] = [];

    const kpiTableIds: { [key: string]: string } = {};

    const kpiTableValues: { [key: string]: number } = {};

    const multiply = healthRequestParameters.find((requestParams) => requestParams.kpiName === health.kpi)?.multiply;

    healthContainerList.forEach((container) => {
      let value = container.value;

      kpiTableIds[container.component_bk] = container.component_name;

      if (value !== null && value !== undefined) {
        if (multiply) {
          value *= 1000;
        }

        kpiChartData.push(value);

        kpiTableValues[container.component_bk] = value;
      }
    });

    return {
      kpiName: kpiName,
      kpiChartData,
      kpiTableIds,
      kpiTableValues,
    };
  }

  createHealthKpiChartData(kpiDistributionData: KPIDistributionData): KPIChartData {
    if (!kpiDistributionData.kpiChartData || !kpiDistributionData.kpiChartData.length) return { alert: missingCalculationAlert };

    const kpiData = kpiDistributionData.kpiChartData.filter((val) => val != null).sort((a, b) => a - b);

    return { data: { x: [kpiData], y: [] } };
  }

  createHealthKpiTableData(tableData: KPITableData[], kpiDistributionData: KPIDistributionData): KPITableData[] {
    const { kpiTableIds, kpiTableValues, kpiName } = kpiDistributionData;

    // Check if the data is present before proceeding
    if (!kpiTableIds || !kpiTableValues || !Object.keys(kpiTableIds).length || !Object.keys(kpiTableValues).length) {
      return tableData;
    }

    if (!tableData.length) {
      // eslint-disable-next-line guard-for-in
      for (const containerID in kpiTableIds) {
        tableData.push({
          containerID,
          containerName: kpiTableIds[containerID],
          [kpiName]: kpiTableValues[containerID],
        });
      }

      this.setKpiTableDataExist(true);
    } else {
      tableData = tableData.map((tableRow) => ({
        ...tableRow,
        [kpiName]: kpiTableValues[tableRow.containerID],
      }));
    }

    this.store.dispatch(
      healthAction.loadHealthStringsDistributionSuccess({
        data: {
          items: sortDataByConfig(tableData as any[], 'containerID' as keyof KpiDistributionInterface, 'asc'),
        } as PaginatedResponse<KpiDistributionInterface>,
      })
    );

    return tableData;
  }

  createKpiInfoChartData({
    health,
    healthRequestParameters,
  }: {
    health: HealthV2;
    healthRequestParameters: HealthRequestParameters[];
  }): ChartInfoItemContent[] {
    if (!health.data.length) return [];

    const kpiName = health.kpi as DistributionKpisEnum;

    const healthContainerList: FeHealthContainer[] = health.data
      .filter((container) => container.value != null)
      .sort((a, b) => a.value - b.value)
      .map((healthContainer) => ({
        containerID: healthContainer.component_bk,
        containerName: healthContainer.component_name,
        value: healthContainer.value,
      }));

    if (!healthContainerList || !healthContainerList.length) return [];

    const multiply = healthRequestParameters.find((requestParams) => requestParams.kpiName === health.kpi)?.multiply;

    return this.kpisChartInfoConfig[kpiName].map((aggregationType) => {
      const containerWithAggregation = this.aggregateData(healthContainerList, aggregationType);

      let value = containerWithAggregation.value;

      if (multiply && value) {
        value *= 1000;
      }

      return {
        titleTop: chartInfoTitleMapping[aggregationType],
        titleBottom: containerWithAggregation.containerID,
        valueZip: {
          id: containerWithAggregation.containerID,
          value: value,
        },
        measurand: this.kpisChartLayout[kpiName].xUnit,
        numberFormat: this.kpisChartLayout[kpiName].numberFormat,
      };
    });
  }

  aggregateData(healthContainerList: FeHealthContainer[], aggregationType: AggregationType): FeHealthContainer {
    switch (aggregationType) {
      case AggregationType.MIN:
        return healthContainerList[0];
      case AggregationType.MAX:
        return healthContainerList[healthContainerList.length - 1];
      default:
        return { value: healthContainerList[healthContainerList.length - 1].value - healthContainerList[0].value };
    }
  }

  filterHealthKpiData(
    healthKpiList: KpiDistributionInterface[],
    kpiFilter: Record<string, FilterBarValues[]>
  ): FilteredHealthKpiData {
    const kpiName: string = Object.keys(kpiFilter)[0];
    const filters = kpiFilter[kpiName];
    const selected: KpiDistributionInterface[] = [];
    const disabled: KpiDistributionInterface[] = [];

    if (!filters) return { selected: [...healthKpiList], disabled: [] };

    healthKpiList.forEach((val) => {
      let isFiltered = false;
      for (const filter of filters) {
        if (
          filter &&
          ((filter.min === 0 && filter.max === 0 && val[kpiName] == null) ||
            (val[kpiName] >= filter.min && val[kpiName] < filter.max))
        ) {
          selected.push(val);
          isFiltered = true;
          break; // Stop checking other filters once a match is found
        }
      }
      if (!isFiltered) {
        disabled.push(val);
      }
    });

    return { selected, disabled };
  }

  formatHealthKpisList(healthKpiList: KpiDistributionInterface[]): Record<string, number[]> {
    const formattedData: Record<string, number[]> = {};

    healthKpiList.forEach((obj) => {
      Object.keys(obj).forEach((key) => {
        if (typeof obj[key] === 'number' || obj[key] == null) {
          formattedData[key] = (formattedData[key] || []).concat([obj[key]]);
        }
      });
    });

    Object.keys(formattedData).forEach(
      (key) => (formattedData[key] = formattedData[key].filter((val) => val != null).sort((a, b) => a - b))
    );

    return formattedData;
  }

  filterHealthKpiChartData(
    filteredHealthKpiList: FilteredHealthKpiData,
    kpisChartData: Record<DistributionKpisEnum, KPIChartData>
  ): Record<DistributionKpisEnum, KPIChartData> {
    const selectedData = this.formatHealthKpisList(filteredHealthKpiList.selected);
    const disabledData = this.formatHealthKpisList(filteredHealthKpiList.disabled);

    const kpisChartDataClone: Record<DistributionKpisEnum, KPIChartData> = { ...kpisChartData };

    for (const kpi in kpisChartDataClone) {
      if (selectedData[kpi]) {
        kpisChartDataClone[kpi as DistributionKpisEnum] = {
          data: {
            x: disabledData[kpi] ? [selectedData[kpi], disabledData[kpi]] : [selectedData[kpi]],
            y: [],
          },
        };
      }
    }

    return kpisChartDataClone;
  }
}
