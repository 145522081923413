@use '@angular/material' as mat;
@use 'twaice-theme';

.bulk-selection {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: twaice-theme.$font-size-base;
}

.section {
  &-header {
    &_button {
      background: none !important;
      font-size: twaice-theme.$font-size-base;
      line-height: twaice-theme.$font-size-base;

      twaice-fe-svg-icon {
        display: inline-block;
        padding-right: twaice-theme.$spacer-xs;
      }

      &[disabled] {
        color: mat.get-color-from-palette(twaice-theme.$twaice-primary, 500);
      }

      &.active {
        color: twaice-theme.$navy-blue;
      }
    }
  }
}
