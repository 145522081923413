import { DatabricksConfig, Environment } from './environment.model';

export const DATABRICKS_CONFIG_DEV: DatabricksConfig = {
  tokenArn: '/twaice/databricks/workspaces/dev_fe_token',
  pathArn: '/twaice/databricks/workspaces/dev/warehouses/dev-backend-sql-warehouse/http_path',
  hostArn: '/twaice/databricks/workspaces/dev/warehouses/dev-backend-sql-warehouse/server_hostname',
  databaseNames: {
    measurements: 'dev_dl_measurements',
    battery_kpis: 'dev_dl_battery_kpis',
    customer_config: 'dev_dl_customer_config',
    performance_manager: 'dev_dl_performance_manager',
  },
};

export const ENVIRONMENT_DEV: Environment = {
  production: false,
  name: process.env['ENV_NAME'] || 'dev',
  staticFilesHost: 'https://static.dev.twaice.ai/',
  baseUrlBe4Fe: 'https://be4fe.dev.twaice.ai/',
  baseUrlApi: 'https://api.dev.twaice.ai/',
  /* eslint-enable @typescript-eslint/naming-convention */
  // public identifier for the Transifex project
  transifexToken: '1/e60edf51d3cbc5c2a847caef792f9da32ba18ef6',
  // public Intercom App ID
  intercomAppId: 'thagvi83',
  // public Mixpanel ID
  mixpanelId: '4f213a56d72f86ef826d5cc8b1ed19e9',
};
