import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { keysToCamel } from '@twaice-fe/shared/utilities';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ChatMessage, ChatMessageResponse, ChatMessagesResponse } from './models';

@Injectable({
  providedIn: 'root',
})
export class ChatService {
  constructor(private http: HttpClient) {}

  fetchChatMessages(customerBk: string, systemBk: string): Observable<ChatMessage[]> {
    const url = `analytics/chat/customers/${customerBk}/systems/${systemBk}/messages`;

    return this.http
      .get<{ data: ChatMessagesResponse }>(url)
      .pipe(
        map((res: { data: ChatMessagesResponse }) =>
          res.data.items.map((item: ChatMessageResponse) => keysToCamel(item) as ChatMessage)
        )
      );
  }

  postChatMessage(
    customerBk: string,
    systemBk: string,
    message: string,
    tags: string[],
    includeLink: boolean
  ): Observable<ChatMessage> {
    const url = `analytics/chat/customers/${customerBk}/systems/${systemBk}/messages`;
    return this.http
      .post<{ data: ChatMessageResponse }>(url, {
        message: message,
        tags: tags,
        source_url: includeLink ? `${location.pathname}${location.search ?? ''}` : undefined,
      })
      .pipe(map((res: { data: ChatMessageResponse }) => keysToCamel(res.data)));
  }

  patchChatMessage(
    customerBk: string,
    systemBk: string,
    timestamp: string,
    message?: string,
    tags?: string[]
  ): Observable<ChatMessage> {
    const url = `analytics/chat/customers/${customerBk}/systems/${systemBk}/messages/${timestamp}`;
    const body = {
      ...(message ? { message: message } : {}),
      ...(tags ? { tags: tags } : {}),
    };

    return this.http
      .patch<{ data: ChatMessageResponse }>(url, body)
      .pipe(map((res: { data: ChatMessageResponse }) => keysToCamel(res.data)));
  }

  deleteChatMessage(customerBk: string, systemBk: string, timestamp: string): Observable<object> {
    const url = `analytics/chat/customers/${customerBk}/systems/${systemBk}/messages/${timestamp}`;
    return this.http.delete(url);
  }
}
