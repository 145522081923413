import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {
  AnnotationData,
  AnnotationViewOptions,
  AnnotationViewService,
  AreaViewService,
  ChartAxisService,
  ChartData,
  ChartLayout,
  ChartLayoutService,
  ConfidenceIntervalData,
  GlobalHoverEvent,
  hexToRgb,
  InitializeChartUtility,
  LineViewService,
  missingDataAlert,
  navyBlue,
} from '@twaice-fe/frontend/shared/utilities';
import { Selection } from 'd3';
import { fromEvent, Subscription } from 'rxjs';
import { lineChartDimensions, lineChartXAxisInfo, lineChartYAxisInfo } from './health-line-chart.config';
import { KpiAlertModels } from '@twaice-fe/shared/models';
import { addLineChartTooltip, initTooltipContent, positionTooltip } from './health-line-chart-tooltip';
import { DatePipe } from '@angular/common';
import { symbolPlus } from 'd3-shape';
import { TooltipContentInterface } from './models/health-line-chart.models';
import { sortConfidenceIntervalData } from './health-line-chart.utils';

@Component({
  selector: 'twaice-fe-health-line-chart',
  templateUrl: './health-line-chart.component.html',
  styleUrls: ['./health-line-chart.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [ChartLayoutService, InitializeChartUtility, ChartAxisService],
  standalone: false,
})
export class HealthLineChartComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  @Input() layout: ChartLayout;
  @Input() data: ChartData;
  @Input() confidenceIntervalData?: ConfidenceIntervalData[];
  @Input() annotationData?: AnnotationData[];
  @Input() annotationViewOptions?: AnnotationViewOptions[];
  @Input() alert?: KpiAlertModels;
  @Input() shouldRedraw?: boolean;
  @Input() indexesSelectedData?: number[];
  @Input() indexSelectedCIData?: number;
  @Input() isLoading = true;
  @Output() chartHover = new EventEmitter<number>();
  @ViewChild('lineChart', { static: true }) lineChart: ElementRef<HTMLDivElement>;
  @ViewChild('tooltip', { static: false }) tooltipRef: ElementRef<HTMLDivElement>;

  tooltipContent: TooltipContentInterface;
  chartAlert: KpiAlertModels;

  private chartContainer: HTMLDivElement;
  private chartSVGContainer: Selection<SVGGElement, unknown, null, undefined>;
  private windowResizeSubscription: Subscription;

  constructor(
    private chartLayoutService: ChartLayoutService,
    private lineViewService: LineViewService,
    private areaViewService: AreaViewService,
    private annotationViewService: AnnotationViewService,
    private datePipe: DatePipe
  ) {}

  ngOnInit(): void {
    this.chartContainer = this.lineChart.nativeElement;
  }

  ngAfterViewInit(): void {
    // setup resize event to keep the charts responsive
    this.windowResizeSubscription = fromEvent(window, 'resize').subscribe(() => {
      if (this.chartContainer && this.chartContainer.clientWidth && this.chartContainer.clientHeight) {
        this.initChart();
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['layout'] || changes['data'] || changes['confidenceIntervalData']) {
      this.chartAlert = null;
      this.isLoading = true;
    }

    if (this.alert) {
      this.chartAlert = this.alert;
      this.isLoading = false;
      return;
    }

    if (this.layout && !changes['shouldRedraw'] && !changes['data'] && !changes['confidenceIntervalData']) {
      if (changes['indexesSelectedData'] && !changes['indexesSelectedData'].firstChange && this.indexesSelectedData !== null) {
        this.selectData();
      }

      if (changes['indexSelectedCIData'] && !changes['indexSelectedCIData'].firstChange && this.indexSelectedCIData !== null) {
        this.selectCIData();
      }

      return;
    }

    this.chartContainer = this.lineChart.nativeElement;

    if (this.indexesSelectedData == null && this.data) {
      this.indexesSelectedData = this.data.x?.map((x, i) => i);
    }

    if (this.indexSelectedCIData == null && this.confidenceIntervalData) {
      this.indexSelectedCIData = 0;
    }

    this.initChart();
  }

  ngOnDestroy() {
    this.windowResizeSubscription.unsubscribe();
  }

  private initChart() {
    this.chartLayoutService.clear(this.chartContainer);
    if (this.layout && this.data) {
      if (!this.data?.x.some((list) => list.length) || !this.data?.y.some((list) => list.length)) {
        this.chartAlert = missingDataAlert;
        this.isLoading = false;
        return;
      }

      this.initChartLayout();
      this.initChartContent();
    }
  }

  private initChartLayout() {
    let confidenceIntervalDataFlattened: ConfidenceIntervalData = { x: [], y0: [], y1: [] };

    if (this.confidenceIntervalData) {
      confidenceIntervalDataFlattened = this.confidenceIntervalData.reduce((result, data) => {
        result.x.push(...data.x);
        result.y0.push(...data.y0);
        result.y1.push(...data.y1);
        return result;
      }, confidenceIntervalDataFlattened);
    }

    const xAxisInfo = this.chartLayoutService.setXAxisInfo({
      xAxisInfo: lineChartXAxisInfo,
      layout: this.layout,
      x: this.confidenceIntervalData ? [].concat(...this.data.x, confidenceIntervalDataFlattened.x) : this.data.x,
    });

    const yAxisInfo = this.chartLayoutService.setYAxisInfo({
      yAxisInfo: lineChartYAxisInfo,
      layout: this.layout,
      y: this.confidenceIntervalData
        ? [].concat(...this.data.y, confidenceIntervalDataFlattened.y0, confidenceIntervalDataFlattened.y1)
        : this.data.y,
    });

    this.chartSVGContainer = this.chartLayoutService.draw({
      chartContainer: this.chartContainer,
      chartDimensions: lineChartDimensions,
      xAxisInfo,
      yAxisInfo,
      layout: this.layout,
    });
  }

  private initChartContent() {
    this.chartSVGContainer = this.lineViewService.draw({
      chartSVGContainer: this.chartSVGContainer,
      chartLayoutService: this.chartLayoutService,
      data: this.data,
      options: {
        color: this.layout.chartColors,
        events: {
          onHover: this.layout.hasTooltip ? (event) => this.onHover({ event }) : null,
        },
      },
    });

    this.isLoading = false;

    if (this.indexesSelectedData) {
      this.selectData();
    }

    if (this.confidenceIntervalData) {
      this.chartSVGContainer = this.areaViewService.draw({
        chartSVGContainer: this.chartSVGContainer,
        chartLayoutService: this.chartLayoutService,
        data: this.confidenceIntervalData,
        options: {
          color: this.layout.confidenceIntervalColors,
        },
      });

      this.isLoading = false;

      if (this.indexSelectedCIData !== null) {
        this.selectCIData();
      }
    }

    if (this.annotationData && this.annotationViewOptions && this.annotationData.length === this.annotationViewOptions.length) {
      this.annotationData.forEach((data, i) => {
        this.annotationViewService.draw({
          chartSVGContainer: this.chartSVGContainer,
          chartLayoutService: this.chartLayoutService,
          data: data,
          options: this.annotationViewOptions[i],
        });
      });
    }
  }

  private selectData() {
    this.layout.chartColors.forEach((_, i) => {
      this.chartSVGContainer?.select(`#line-${i}`).classed('selected', this.indexesSelectedData.includes(i));
      this.chartSVGContainer?.select(`#line-${i}`).classed('enable-hover', this.indexesSelectedData.includes(i));
    });

    if (this.layout.legends && this.layout.chartColors?.length) {
      this.indexesSelectedData.forEach((colorIndex, legendIndex) => {
        if (legendIndex < this.layout.legends.length) {
          this.layout.legends[legendIndex].color = this.layout.chartColors[colorIndex];
        }
      });
    }
  }

  private selectCIData() {
    if (this.confidenceIntervalData?.some((list) => list.x.length)) {
      this.confidenceIntervalData.forEach((_, i) => {
        this.chartSVGContainer?.select(`#area-${i}`).classed('selected', this.indexSelectedCIData === i);
      });

      if (this.layout.legends && this.layout.confidenceIntervalColors?.length) {
        const hexColor = this.layout.confidenceIntervalColors[this.indexSelectedCIData];

        if (hexColor) {
          this.layout.legends[this.layout.legends.length - 1].color = hexToRgb(hexColor, 0.1);
        }
      }
    }
  }

  private onHover({
    event,
    showTooltip = true,
    isExternalMouseover = true,
  }: {
    event: GlobalHoverEvent;
    showTooltip?: boolean;
    isExternalMouseover?: boolean;
  }) {
    if (!event) {
      this.tooltipRef.nativeElement.style.display = 'none';

      this.chartSVGContainer?.select('#lines #line-tooltip').remove();

      if (isExternalMouseover) this.chartHover.emit(null);

      return;
    }

    const { x, y, color } = event;

    if (isExternalMouseover) this.chartHover.emit(x.data as number);

    addLineChartTooltip({ x: x.x, chartContainer: this.chartContainer });

    if (showTooltip) {
      this.tooltipContent = initTooltipContent({
        data: { x: this.datePipe.transform(x.data, 'MMM d, yyyy, HH:mm'), y: y.data as number, index: x.index },
        layoutInfo: {
          color: color,
          title: this.layout.tooltipTitle,
          unit: this.layout.yUnit ? this.layout.yUnit : '',
          valuePrecision: this.layout.yTickValuesPrecision ? this.layout.yTickValuesPrecision : 0,
        },
        confidenceIntervalData:
          this.confidenceIntervalData && this.confidenceIntervalData[this.indexSelectedCIData]
            ? sortConfidenceIntervalData(this.confidenceIntervalData[this.indexSelectedCIData])
            : null,
      });

      this.tooltipRef.nativeElement = positionTooltip({
        x: x.x,
        y: y.y,
        tooltip: this.tooltipRef.nativeElement,
        chartContainer: this.chartContainer,
      });
    }
  }

  initiateHover({
    chartTitle,
    targetXValue,
    showTooltip = false,
  }: {
    chartTitle: string;
    targetXValue: number | null;
    showTooltip?: boolean;
  }) {
    if (this.data && this.layout.title !== chartTitle) {
      if (!targetXValue) {
        this.onHover({ event: null, showTooltip, isExternalMouseover: false });

        this.chartSVGContainer?.select('#annotations').remove();

        return;
      }

      const x = this.data.x[this.indexesSelectedData[0]];

      if (!x) return;

      const xIndex = x.findIndex((xValue) => xValue === targetXValue);

      if (xIndex === -1) return;

      const y = this.data.y[this.indexesSelectedData[0]];

      const xPosition = this.chartLayoutService.getXScale()(x[xIndex]);

      const yPosition = this.chartLayoutService.getYScale()(y[xIndex]);

      const event = {
        x: { x: xPosition, data: x[xIndex], index: xIndex },
        y: { y: yPosition, data: y[xIndex], index: xIndex },
        color: this.layout.chartColors[this.indexesSelectedData[0]],
      };

      this.onHover({ event, showTooltip, isExternalMouseover: false });

      this.chartSVGContainer?.select('#annotations').remove();

      this.annotationViewService.draw({
        chartSVGContainer: this.chartSVGContainer,
        chartLayoutService: this.chartLayoutService,
        data: { x: [x[xIndex]], y: [y[xIndex]] },
        options: {
          stroke: navyBlue,
          size: 100,
          shape: symbolPlus,
        },
      });
    }
  }
}
