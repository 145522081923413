/**
 * Energy
 */
export const ENERGY_BASE_ROUTE = '/energy';
export const ENERGY_OVERVIEW_ROUTE = '/energy/overview';
export const ENERGY_HEALTH_ROUTE = '/energy/health';
export const ENERGY_WARRANTY_TRACKER_ROUTE = '/warranty-tracker';
export const ENERGY_PERFORMANCE_MANAGER_ROUTE = '/performance-manager';
export const ENERGY_ASSET_DASHBOARD_ROUTE = '/energy/overview';
export const ENERGY_PREDICTION_ROUTE = '/health-prediction/health-prediction';
export const ENERGY_INCIDENTS_ROUTE = '/incidents';
export const ENERGY_MONITORING_ROUTE = '/system-monitoring';
export const ENERGY_SAFETY_ROUTE = '/safety';
export const ENERGY_DETAIL_INCIDENT_ROUTE = '/incidents-details';
export const ENERGY_REPORTS_V2_ROUTE = '/reports';

/**
 * Mobility
 */
export const MOBILITY_BASE_ROUTE = '/mobility';
export const MOBILITY_FLEETS_ROUTE = '/mobility/groups';
export const MOBILITY_INCIDENTS_ROUTE = '/mobility/alerts';
export const MOBILITY_RULES_ROUTE = '/mobility/rules';
export const MOBILITY_CONFIGURATION_ROUTE = '/mobility/config';
// @deprecated since 09.03.24
export const MOBILITY_DEPRECATED_BASE_ROUTE = '/fleet-analytics';

/**
 * Model Library
 */
export const MODEL_LIBRARY_BASE_ROUTE = '/model-library/library';
export const MODEL_DEMO_BASE_ROUTE = '/model-demo/demo';

/**
 * Shared
 */
export const DATA_EXPLORER_ROUTE = '/data-explorer';
export const DATA_EXPLORER_V2_ROUTE = '/v2-data-explorer';
export const REPORTING_ROUTE = '/reporting/library';
export const PROFESSIONAL_SERVICES_ROUTE = '/professional-services';
export const ANALYTICS_UPSELL_ROUTE = '/analytics';
export const ACCOUNT_SETTINGS_ROUTE = '/settings';
export const PRIVACY_POLICY_ROUTE = '/privacy-policy';
