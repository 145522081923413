/* eslint-disable @typescript-eslint/naming-convention */
export interface SimulationCellInfoInterface {
  id: string;
  name: string;
  capacity: number;
  nominal_voltage: number;
  chemistry: string;
  form_factor: string;
}

export interface SimulationStopConditionInterface {
  time: number;
  sohc: number;
  efc: number;
}

export interface SimulationCoolingInterface {
  id: string;
  name: string;
  setpoint: number;
}

export interface SimulationOperatingWindowInterface {
  min: number;
  max: number;
}

export interface SimulationModelInterface {
  cell_info: SimulationCellInfoInterface;
  initial_state?: {
    soc: number;
    sohc: number;
    sohr: number;
    temperature: number;
  };
}

export interface SimulationLocationInterface {
  id: string;
  name: string;
  ambient_temperature: number;
}

export interface SimulationScenarioInterface {
  market: string;
  service: string;
  cycles: number;
  soc_operating_window: {
    min: number;
    max: number;
  };
}

export interface SimulationStorageInformationInterface {
  supplier_name: string;
  availability: number;
  rated_ac_power: number;
  rated_ac_energy: number;
  nominal_dc_voltage: number;
  configuration: SimulationStorageConfiguration;
}

export interface SimulationStorageConfiguration {
  transformers: number;
  inverters: number;
  containers: number;
  racks: number;
  modules: number;
  serial_cells: number;
  parallel_cells: number;
}

export enum SimulationEfficiencyType {
  EC = 'ec',
  CEC = 'cec',
}

export interface SimulationPowerConversionInterface {
  type: SimulationEfficiencyType;
  efficiency: number;
  max_efficency: number;
  dc_voltage_range: {
    min: number;
    max: number;
  };
  rated_ac_output_power: number;
}

export interface SimulationMvTransformerInterface {
  no_load_losses: number;
  load_losses: number;
  rated_ac_power: number;
}

export interface SimulationParamsInterface {
  name: string; // Name/description for the simulations list
  model: SimulationModelInterface;
  scenario?: SimulationScenarioInterface;
  cooling?: SimulationCoolingInterface;
  location: SimulationLocationInterface;
  storage_information?: SimulationStorageInformationInterface;
  power_conversion?: SimulationPowerConversionInterface;
  mv_transformer?: SimulationMvTransformerInterface;
  soc_operating_window: SimulationOperatingWindowInterface;
  stop_condition: SimulationStopConditionInterface;
}

export enum SimulationChartType {
  LINE = 'line',
  BAR = 'bar',
  SCATTER = 'scatter',
}

export interface SimulationChartDataPoint {
  offset: number;
  values: number[];
}

export enum SimulationScale {
  SECONDS = 's',
  EFC = 'efc',
  YEARS = 'y',
}

export enum SimulationUnit {
  PERCENT = '%',
  EFC = 'cycles',
  VOLTS = 'V',
  AMPERES = 'A',
  TEMPERATURE = '°C',
  POWER = 'W',
  ENERGY = 'kWh',
  NONE = '',
}

export interface SimulationChartDataInterface {
  scale: SimulationScale;
  data: SimulationChartDataPoint[];
  labels: string[];
  name: string;
  unit: SimulationUnit;
}

export interface SimulationResultInterface {
  stop_condition: string; //ENUM which condition was hit first
  series: SimulationChartDataInterface[];
}

export enum SimulationStatus {
  CREATED = 'CREATED',
  IN_PROGRESS = 'IN_PROGRESS',
  DONE = 'DONE',
  FINISHED = 'FINISHED',
}

export interface SimulationDataInterface {
  // tenantID/systemID/userID -> PK
  timestamp: string;
  id: string; //SK -> uuid
  parameters: SimulationParamsInterface; // Input to generator
  result: SimulationResultInterface; // Output metadata from generator (joined from external source)
  status: SimulationStatus;
}

export interface SimulationDataResponseInterface {
  data: { simulation: SimulationDataInterface };
}
