import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'nameFilter',
  standalone: false,
})
export class NameFilterPipe implements PipeTransform {
  displayItem(item: any, searchText: string): boolean {
    return item.name && item.name.toLowerCase().includes(searchText.trim().toLowerCase());
  }

  /*
   * We want to support ngFor with keyvalue pipe(which have the actual
   * item stored in .value) as well as the regular list of items -
   * this function returns the object we want to work with
   * */
  getItem(inputItem: any) {
    if (inputItem.value) {
      return inputItem.value;
    } else {
      return inputItem;
    }
  }

  transform(items: unknown[], searchText: string, hideOnly: boolean = false): unknown[] {
    if (!items) {
      return;
    }

    if (!searchText) {
      return items;
    }

    if (hideOnly) {
      items.forEach((inputItem: unknown) => {
        const item = this.getItem(inputItem);
        // to support both hide and show principles that could be used
        item.show = this.displayItem(item, searchText);
        item.hide = !this.displayItem(item, searchText);
      });
    } else {
      return items.filter((inputItem) => {
        const item = this.getItem(inputItem);
        return this.displayItem(item, searchText);
      });
    }
  }
}
