import { Component, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

export interface SimpleInfoDialogData {
  title: string;
  content: string;
}

@Component({
  selector: 'twaice-fe-simple-info-dialog',
  templateUrl: './simple-info-dialog.component.html',
  styleUrls: ['./simple-info-dialog.component.scss'],
  standalone: false,
})
export class SimpleInfoDialogComponent {
  title: string;
  content: string;

  constructor(
    public dialogRef: MatDialogRef<SimpleInfoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: SimpleInfoDialogData
  ) {
    this.title = data.title;
    this.content = data.content;
  }
}
