interface DownloadOptions {
  fileName: string;
  fileType: string;
}

export class FileDownloadService {
  constructor() {}

  triggerClientDownload(data: string | BufferSource, { fileName, fileType }: DownloadOptions) {
    const a = document.createElement('a');
    a.style.display = 'none';
    document.body.appendChild(a);

    const blob = new Blob([data], { type: fileType });
    const url = window.URL.createObjectURL(blob);

    a.href = url;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
  }
}
