import { AfterContentInit, Component, ContentChildren, EventEmitter, Input, OnDestroy, Output, QueryList } from '@angular/core';
import { ReplaySubject, takeUntil } from 'rxjs';
import { TabContentComponent } from './tab-content/tab-content.component';
import { TabComponent } from './tab/tab.component';

@Component({
  selector: 'twaice-fe-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss'],
  standalone: false,
})
export class TabsComponent implements AfterContentInit, OnDestroy {
  @Input()
  set activeTab(index) {
    this._defaultTabIndex = index;
    this.setActiveTab(index);
  }
  private _defaultTabIndex = 0;

  @Output()
  activeTabChange = new EventEmitter<number>();

  @ContentChildren(TabComponent) tabs: QueryList<TabComponent>;
  @ContentChildren(TabContentComponent) tabContents: QueryList<TabContentComponent>;

  destroy$ = new ReplaySubject<boolean>();

  private openTabIndex: number;

  constructor() {}

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  ngAfterContentInit(): void {
    this.tabs.map((item, index) =>
      item.tabClicked.pipe(takeUntil(this.destroy$)).subscribe(() => {
        this.setActiveTab(index);
        this.activeTabChange.emit(this.openTabIndex);
      })
    );
    this.openTabIndex = this._defaultTabIndex;
    this.setActiveTab(this._defaultTabIndex);
  }

  setActiveTab(id: number): void {
    if (!this.tabs?.length) return;

    const tabs = this.tabs.toArray();
    const tabContents = this.tabContents.toArray();

    tabs[this.openTabIndex].active = false;
    tabs[id].active = true;

    if (tabContents?.length) {
      tabContents[this.openTabIndex].visible = false;
      tabContents[id].visible = true;
    }

    this.openTabIndex = id;
  }
}
