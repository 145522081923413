import { Pipe, PipeTransform } from '@angular/core';
import { CustomerNameTranslationService } from './customer-name-translation.service';

@Pipe({
  name: 'componentNameTranslation',
  pure: false,
  standalone: true,
})
export class ComponentNameTranslationPipe implements PipeTransform {
  constructor(private customerNameTranslationService: CustomerNameTranslationService) {}

  transform<T extends string | undefined>(componentBk: T): T extends undefined ? undefined : string {
    return this.customerNameTranslationService.translateComponentBk(componentBk);
  }
}
