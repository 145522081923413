import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { ChartInfoItemContent, ChartMissingDataContent } from './models/chart-info.models';
import { StorageLevel } from '@twaice-fe/shared/models';

@Component({
  selector: 'twaice-fe-chart-info',
  templateUrl: './chart-info.component.html',
  styleUrls: ['./chart-info.component.scss'],
  standalone: false,
})
export class ChartInfoComponent implements OnChanges {
  @Input() chartTitle: string;
  @Input() chartMissingDataContent: ChartMissingDataContent;
  @Input() chartItemList: ChartInfoItemContent[];
  @Output() itemClick: EventEmitter<ChartInfoItemContent> = new EventEmitter<ChartInfoItemContent>();
  @Output() filterMissingData: EventEmitter<boolean> = new EventEmitter<boolean>();
  percent: number;

  protected readonly storageLevel = StorageLevel;

  constructor() {}

  ngOnChanges() {
    this.percent = null;

    if (this.chartMissingDataContent?.dataCount && this.chartMissingDataContent?.missingDataCount != null) {
      this.percent = (this.chartMissingDataContent.missingDataCount / this.chartMissingDataContent.dataCount) * 100;
    }
  }
}
