import { ActivatedRoute, Params, Router } from '@angular/router';

export function updateQueryParameter(
  router: Router,
  activatedRoute: ActivatedRoute,
  paramNames: string[] | string = [],
  paramValues: unknown[] | unknown = []
): Promise<boolean> {
  // to allow also for single values and handle them the same way we transform single values in array
  if (!Array.isArray(paramNames)) {
    paramNames = [paramNames];
  }
  if (!Array.isArray(paramValues)) {
    paramValues = [paramValues];
  }

  const queryParams: Params = {};
  for (let i = 0; i < paramNames.length; i++) {
    if (paramValues[i] !== null && paramValues[i] !== undefined) {
      queryParams[paramNames[i]] = paramValues[i];
    }
  }

  return router.navigate([], {
    relativeTo: activatedRoute,
    queryParams: queryParams,
    queryParamsHandling: 'merge', // do not remove and replace all the query params,
    replaceUrl: true,
  });
}

export const createUrlTreeWithQueryParameter = (
  router: Router,
  baseUrl: string[],
  paramNames: string[] | string = [],
  paramValues: unknown[] | unknown = []
): string => {
  // to allow also for single values and handle them the same way we transform single values in array
  if (!Array.isArray(paramNames)) {
    paramNames = [paramNames];
  }
  if (!Array.isArray(paramValues)) {
    paramValues = [paramValues];
  }

  const queryParams: Params = {};
  for (let i = 0; i < paramNames.length; i++) {
    // To allow for more automated query parameter setting, we want
    // to filter out any undefined parameters
    if (paramValues[i] !== null && paramValues[i] !== undefined) {
      queryParams[paramNames[i]] = paramValues[i];
    }
  }

  return router.createUrlTree(baseUrl, { queryParams: queryParams }).toString();
};

export function removeQueryParameter(
  router: Router,
  activatedRoute: ActivatedRoute,
  paramNames: string[] | string = []
): Promise<boolean> {
  // to allow also for single values and handle them the same way we transform single values in array
  if (!Array.isArray(paramNames)) {
    paramNames = [paramNames];
  }

  // we copy the current state of the parameters and remove the defined parameters if they exist
  const currentParameters = { ...activatedRoute.snapshot.queryParams };
  paramNames.forEach((paramName) => {
    if (currentParameters[paramName]) {
      delete currentParameters[paramName];
    }
  });

  return router.navigate([], {
    relativeTo: activatedRoute,
    queryParams: currentParameters,
  });
}
